/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import Content from '../../presentational/Content';
import { State } from '../../../../typings/state/state';
import { ContainerType } from '../../../../typings/constants/containerTypes';
/**
 * Connected container for Header component
 */
const mapStateToProps = ({ page }: State) => {
  const { blocks } = page.data;
  return {
    hasGalactic: page.hasGalactic,
    window: page.window,
    hasMeaFirst: page.isMEAFirst || page.isMeaCarouselFirst,
    footerHeight: page.footerHeight,
    elements: blocks[ContainerType.CONTENT],
  };
};

const ContentContainer = connect(mapStateToProps)(Content);

export default ContentContainer;
