import Logger from 'loggerHelper';
import { Block } from '../../typings/components/block';
import { Blocks, PageDataState, IPageDataFormatState } from '../../typings/state/pageState';
import { PageData } from '../../typings/components/page';
import { PageResult } from '../../typings/components/pageResult';
import { PageBlockType } from '../../typings/constants/pageBlockTypes';
import { ContainerType } from '../../typings/constants/containerTypes';
import { ContentArticleList, ContentLang } from 'src/typings/components/content';

type BlockIndex = { [key in ContainerType]: number | undefined } | {};

export const getBlockIndexBy =
  (data: Blocks) =>
  (key: 'type' | 'id') =>
  (val: PageBlockType | number): BlockIndex =>
    Object.entries(data).reduce(
      (acc, [container, blocks]) => ({ ...acc, [container]: blocks.findIndex((block) => block[key] === val) }),
      {},
    );

const verifyBlock =
  (object: BlockIndex) =>
  (predict: (param: number | undefined) => boolean): boolean =>
    Object.values(object).find(predict) !== undefined;

const hasBlock = (index: number | undefined): boolean => index !== undefined && index > -1;
const isFirst = (index: number | undefined): boolean => index === 0;

export function formatPageApi(rawData: PageResult): IPageDataFormatState {
  const formattedBlocks = rawData.data.blocks.reduce(
    (acc: Partial<Blocks>, block) => ({
      ...acc,
      [block.container]: acc[block.container] ? [...(acc[block.container] as Block[]), block] : [block],
    }),
    {},
  );

  const getBlockIndexByType = getBlockIndexBy(formattedBlocks as Blocks)('type');

  const MEAIndex = getBlockIndexByType(PageBlockType.BLOCK_TYPE_MEA);
  const verifyBlockByMEA = verifyBlock(MEAIndex);

  const MEACarouselIndex = getBlockIndexByType(PageBlockType.BLOCK_TYPE_MEA_CAROUSEL);
  const verifyBlockByMeaCarousel = verifyBlock(MEACarouselIndex);

  const GalacticIndex = getBlockIndexByType(PageBlockType.BLOCK_TYPE_GALACTIC_HEADER);

  let langBlock: Block | undefined;
  const langBlockIndex: BlockIndex = getBlockIndexByType(PageBlockType.BLOCK_TYPE_LANG);

  if (verifyBlock(langBlockIndex)(hasBlock)) {
    langBlock = (formattedBlocks as Blocks)[ContainerType.HEADER][langBlockIndex[ContainerType.HEADER]];
  }

  return {
    ...rawData,
    data: {
      ...rawData.data,
      blocks: formattedBlocks as Blocks,
    },
    hasMEA: verifyBlockByMEA(hasBlock),
    isMEAFirst: verifyBlockByMEA(isFirst),
    hasMeaCarousel: verifyBlockByMeaCarousel(hasBlock),
    isMeaCarouselFirst: verifyBlockByMeaCarousel(isFirst),
    articleModal: {
      ariaLabelledBy: 'heading',
    },
    hasGalactic: verifyBlock(GalacticIndex)(hasBlock),
    langsAvailable: langBlock ? (langBlock.content as ContentLang).locales : [],
  };
}
/**
 * Merge or replace given block next page data into the given block current page data
 *
 * @param {Object} data
 * @param {Object} nextPageData
 * @param {number} blockId
 * @returns {Object}
 */
const mergeableBlocks = [PageBlockType.BLOCK_TYPE_ARTICLE_LIST, PageBlockType.BLOCK_TYPE_ARTICLE_LIST_DYNAMIC];
const replaceableBlocks = [PageBlockType.BLOCK_TYPE_OMNITURE_HEADER];

type ActionType = 'merge' | 'replace';

const mergeBlock = (block: Block, nextPageBlock: Block): Block => ({
  ...block,
  content: {
    ...(block.content as ContentArticleList),
    // Add articles on the next page to the existing articles
    articles: [...(block.content as ContentArticleList).articles, ...(nextPageBlock.content as ContentArticleList).articles],
    links: (nextPageBlock.content as ContentArticleList).links, // Replace pagination links with ones from the next page
  },
});

const replaceBlock = (block: Block, nextPageBlock: Block): Block => ({
  ...block,
  content: nextPageBlock.content,
});

function isSearchPage(data: PageDataState | PageData): data is PageData {
  return Array.isArray(data.blocks);
}

const updatePageData =
  (actionType: ActionType) =>
  (data: PageDataState, nextPageBlock: Block, blockId: number): PageDataState => {
    // Retrieving the block in the current page data
    const getBlockIndexById = getBlockIndexBy(data.blocks)('id');
    const existingBlockIndex = getBlockIndexById(blockId);
    const slot = Object.entries(existingBlockIndex).find(
      /* eslint-disable */
      ([_container, index]) => {
        /* eslint-enable */
        return typeof index !== 'undefined' && index > -1;
      },
    );
    if (!slot) {
      Logger.warn(`Block with id "${blockId}" not found in current page`);
      return data;
    }

    const [container, index] = slot;
    const newData = {
      ...data,
      blocks: {
        ...data.blocks,
        [container]: (data.blocks[container as ContainerType] as Block[]).map((block, i): Block => {
          if (i === index && !!(block.content as any) && !!(block.content as any).articles && actionType === 'merge') {
            return mergeBlock(block, nextPageBlock);
          } else if (i === index && actionType === 'replace') {
            return replaceBlock(block, nextPageBlock);
          }
          return block;
        }),
      },
    };
    return newData;
  };

const updateSearchData =
  (actionType: ActionType) =>
  (data: PageData, nextPageBlock: Block, blockId: number): PageData => {
    const newData = {
      ...data,
      blocks: data.blocks.map((block: Block) => {
        if (block.id === blockId && !!block.content && !!(block.content as any).articles && actionType === 'merge') {
          return mergeBlock(block, nextPageBlock);
        }
        return block;
      }),
    };
    return newData;
  };

export function updatePage(actionType: ActionType) {
  return function updateOnNextPageData(
    data: PageDataState | PageData | undefined,
    nextPageData: PageData | undefined,
    blockId: number,
  ): PageDataState | PageData | undefined {
    Logger.debug(`${actionType} next page into current one (blockId: "${blockId}")`);

    // return if no data in nextPage
    if (!nextPageData || !data) {
      return data;
    }

    // return if no specific block can perform action (merge or replace)
    const filterArray = actionType === 'replace' ? replaceableBlocks : mergeableBlocks;

    const nextPageBlock = nextPageData.blocks.find((block) => block.id === blockId && filterArray.includes(block.type));
    if (!nextPageBlock) {
      Logger.warn(`Block with id "${blockId}" not found in next page, or has no designed behavior`);
      return data;
    }

    if (isSearchPage(data)) {
      return updateSearchData(actionType)(data, nextPageBlock, blockId);
    }
    return updatePageData(actionType)(data, nextPageBlock, blockId);
  };
}
