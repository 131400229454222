import React, { useState, useRef } from 'react';
import { canUseDOM } from '../../../../shared/helpers/windowHelper';

export type Props = {
  options: string[];
  onSwitchLang: void;
  selectOption: string;
  setSelectOption: React.Dispatch<React.SetStateAction<string>>;
}

export function SwitchLanguage({ options, onSwitchLang, selectOption, setSelectOption }:Props) {
 const [isActive, setIsActive] = useState(false);
 const dropDownRef = useRef<HTMLDivElement>(null)

  const handleClickOutsideSideMenu = (e) => {
    if (isActive && !dropDownRef.current?.contains(e.target as Node)) {
      setIsActive(false);
    }
  }

  if (canUseDOM()) {
    window.addEventListener("click", handleClickOutsideSideMenu)
  }

  const changeLang = (option) => {
    setSelectOption(option);
    setIsActive(false);
    onSwitchLang;
  };

  return (
    <div ref={dropDownRef}>
      <div>
      <button type="button" aria-label="Choisir votre langue" className="menu" aria-expanded={isActive} aria-controls="language-list" onClick={() => setIsActive(!isActive)}>
        <span id='language-label'>{selectOption}</span>
        
        <span><i className="arrow down"></i></span>
        {isActive && (
          <ul className="menu-content" role="listbox" id="language-list">
            {options.map((option, index) => (
              <li key={index}
                role="option"
              >
                <a
                  lang={option}
                  href=''
                  aria-label={option === "FR" ? "Français" : "Anglais"}
                  className="menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    changeLang(option);
                  }}
                >
                  {option}
                </a>
              </li>
            ))}
          </ul>
        )}
      </button></div>
    </div>
  );
};

