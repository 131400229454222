/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader';
import ConfigHelper from 'configHelper';
import PageContainer from '../container/Page';
import ErrorContainer from '../container/Error';

import '../../assets/icons/icons.font';
import '../../css/main.css';

const htmlPageConfig = ConfigHelper.getClientConfig('html');

interface Props {
  lang?: string;
}

class App extends React.PureComponent<Props> {
  render() {
    const links = htmlPageConfig.links.map((link) => {
      if (link.rel === 'manifest') {
        return { ...link, href: link.href };
      }

      return link;
    });

    return (
      <div className="app">
        {/*
        All of the following will be injected into our page header.
        @see https://github.com/nfl/react-helmet
      */}
        <Helmet
          htmlAttributes={htmlPageConfig.htmlAttributes}
          titleTemplate={htmlPageConfig.titleTemplate}
          defaultTitle={htmlPageConfig.defaultTitle}
          meta={htmlPageConfig.meta}
          link={links}
          script={htmlPageConfig.scripts}
        />
        <PageContainer />
        <ErrorContainer />
      </div>
    );
  }
}

export default hot(module)(App);
