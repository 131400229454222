import React from 'react';
import classNames from 'classnames';
import { FragmentAuthor } from 'src/typings/components/fragment';

type Props = {
  content: FragmentAuthor;
  className?: string;
};

/**
 * Author component
 * @constructor
 */
const Author = ({ content, className }: Props) => (
  <div className={classNames(className, 'article__author')}>
    <span>{content.name}</span>
  </div>
);

export default Author;
