/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// Taken from https://github.com/kriasoft/react-starter-kit/blob/master/docs/recipes/how-to-integrate-disqus.md

import React from 'react';
import get from 'lodash/get';
import ConfigHelper from 'configHelper';
import { ContentComment } from 'src/typings/components/content';
import { WindowApp } from 'src/typings/global';

function renderDisqus(shortname: string) {
  // Can't render disqus server side
  if (typeof window === 'undefined') {
    return;
  }

  if ((window as unknown as WindowApp).DISQUS === undefined) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://${shortname}.disqus.com/embed.js`;
    document.getElementsByTagName('head')[0].appendChild(script);
  } else {
    (window as unknown as WindowApp).DISQUS.reset({ reload: true });
  }
}

type Props = {
  content?: ContentComment;
  id: string;
  title: string;
  path: string;
};

class CommentDisqus extends React.Component<Props> {
  shortName: string;
  hostname: string;

  constructor(props: Props) {
    super(props);
    this.shortName = get(props, 'content.comments.settings.short_name', ConfigHelper.getClientConfig('feature.disqus.shortname'));
    this.hostname = get(props, 'content.comments.settings.hostname', ConfigHelper.getClientConfig('feature.disqus.hostname'));
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props.id !== nextProps.id || this.props.title !== nextProps.title || this.props.path !== nextProps.path;
  }

  componentDidMount() {
    renderDisqus(this.shortName);
  }

  componentDidUpdate() {
    renderDisqus(this.shortName);
  }

  render() {
    if (typeof window !== 'undefined') {
      const urlArticle = this.hostname + window.location.pathname;
      (window as unknown as WindowApp).disqus_url = urlArticle;
    }

    return <div id="disqus_thread" />;
  }
}

export default CommentDisqus;
