import React from 'react';
import Text from '../Text';
import { FragmentIntro } from 'src/typings/components/fragment';

/**
 * Text component
 * @param {string} content Content
 * @constructor
 */
const Intro = ({ content }: { content: FragmentIntro }) => <Text text={content.text} className="intro" />;

export default Intro;
