import React from 'react';
import classNames from 'classnames';

import { Block } from '../../../../typings/components/block';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { WindowDimension } from '../../../../typings/state/pageState';

export type ContentProps = {
  window: WindowDimension;
  footerHeight: number;
  hasGalactic: boolean;
  hasMeaFirst: boolean;
  modeCenter?: boolean;
  elements?: Block[];
  renderBlock: (block: Block) => JSX.Element;
};

type ContentState = {
  headerHeight: number;
};

class Content extends React.PureComponent<ContentProps, ContentState> {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
    };
  }

  componentDidMount() {
    const header = document ? document.getElementsByTagName('header')[0] : null;
    const headerHeight = header ? header.offsetHeight : 0;
    this.setState({
      headerHeight,
    });
  }

  render() {
    const { window, footerHeight, elements, hasGalactic, hasMeaFirst, renderBlock } = this.props;
    const minHeight = window.height - footerHeight - this.state.headerHeight;

    const isHeaderCoverContent = elements?.find((block) => block.type === PageBlockType.BLOCK_TYPE_ARTICLE);

    return (
      <main
        style={{ minHeight: `${minHeight}px` }}
        className={classNames('content', {
          'content--padded': !hasMeaFirst,
          'content--padded--large': !hasMeaFirst && !hasGalactic,
          'content--padded--large--headerCover': isHeaderCoverContent,
        })}
        role="main"
        id="main-content"
      >
        {elements && elements.map(renderBlock)}
      </main>
    );
  }
}

export default Content;
