/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017-2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import UAParser from 'ua-parser-js';

class DeviceService {
  userAgent: any;

  constructor() {
    this.userAgent = new UAParser();
  }

  getDevice(): any {
    return this.userAgent.getDevice().type;
  }
}

export default DeviceService;
