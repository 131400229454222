import React from 'react';
import Picture from '../Picture';
import { getHeaderMedia } from '../../../helpers/imageHelper';
import { ContentGalacticHeaderElement } from 'src/typings/components/content';
import CustomLink from '../../container/CustomLink';

type Props = {
  brand: ContentGalacticHeaderElement;
  partner: ContentGalacticHeaderElement;
};

class GalacticLogo extends React.PureComponent<Props> {
  render() {
    const { brand, partner } = this.props;

    return (
      <div className="GalacticLogo">
        <CustomLink to={brand.links.href}>
          <Picture className="GalacticLogo__site" images={brand.media ? [getHeaderMedia(brand.media).src] : []} alt="Canal Plus" />
        </CustomLink>
        {partner.media && <span className="GalacticSeparator" />}
        {partner.media && (
          <CustomLink to={partner.links.href}>
            <Picture className="GalacticLogo__partner" images={[getHeaderMedia(partner.media).src]} alt="logo partner" />
          </CustomLink>
        )}
      </div>
    );
  }
}

export default GalacticLogo;
