import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import ConfigHelper from 'configHelper';
import Header from '../../presentational/Header';
import HeaderBurger from '../../presentational/HeaderBurger';
import HeaderV5, { Props as HeaderV5Props } from '../../presentational/HeaderV5';
import TemplateHeaderConst from 'src/typings/constants/templateHeaderConst';
import { Block } from 'src/typings/components/block';
import { ContainerType } from '../../../../typings/constants/containerTypes';
import { ContentNavigation, ContentOmnitureHeader } from 'src/typings/components/content';
import { openNavHeader, closeNavHeader } from '../../../actions/page';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { State } from '../../../../typings/state/state';
import { HeaderProps } from 'src/typings/components/header';

type HeaderContainerProps = HeaderV5Props & HeaderProps;

const mapStateToProps = ({ page }: State) => {
  const { blocks } = page.data;
  const isTransparent: boolean = page.isMEAFirst || page.isMeaCarouselFirst;
  const checkForScroll: boolean = page.hasMEA || page.hasMeaCarousel;
  const { lang, path } = page;
  const locale = page.data.site.locale;

  return {
    isFull: !isTransparent,
    isGalactic: page.hasGalactic,
    window: page.window,
    mobileNavIsOpened: page.mobileNavIsOpened,
    checkForScroll,
    elements: blocks[ContainerType.HEADER],
    lang,
    locale,
    path,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  openNavHeader: () => {
    dispatch(openNavHeader());
  },
  closeNavHeader: () => {
    dispatch(closeNavHeader());
  },
});

const HeaderContainer = ({
  openNavHeader,
  closeNavHeader,
  checkForScroll,
  displayLogo,
  galaxy,
  galaxyMenu,
  hideUserInfo,
  homeLink,
  isFull,
  isGalactic,
  locale,
  microEligibilities,
  mobileNavIsOpened,
  navigation,
  onNavigation,
  onRight,
  renderBlock,
  sealed,
  transparent,
  userIsAuthenticated,
  userStatus,
  window,
  elements,
  lang,
  path,
}: HeaderContainerProps) => {
  const templateHeader: TemplateHeaderConst = ConfigHelper.getClientConfig('feature.header.template');
  
  switch (templateHeader) {
    case TemplateHeaderConst.BURGER:
      return (
        <HeaderBurger
          isFull={isFull}
          isGalactic={isGalactic}
          window={window}
          mobileNavIsOpened={mobileNavIsOpened}
          openNavHeader={openNavHeader}
          closeNavHeader={closeNavHeader}
          checkForScroll={checkForScroll}
          elements={elements}
          renderBlock={renderBlock}
        />
      );
    case TemplateHeaderConst.V5:
      let content = {} as ContentNavigation | undefined;
      let trackingBlocks: Block<ContentOmnitureHeader>[] = [];

      if (elements) {
        const [links] = elements.filter((element) => element.type === PageBlockType.BLOCK_TYPE_NAVIGATION) as Block<ContentNavigation>[];
        trackingBlocks = elements.filter(
          (element) => element.type === PageBlockType.BLOCK_TYPE_OMNITURE_HEADER,
        ) as Block<ContentOmnitureHeader>[];
        content = links.content;
      }

      return (
        <HeaderV5
          displayLogo={displayLogo}
          galaxy={galaxy}
          galaxyMenu={galaxyMenu}
          hideUserInfo={hideUserInfo}
          homeLink={homeLink}
          menu={content?.menu}
          locale={locale}
          microEligibilities={microEligibilities}
          navigation={navigation}
          onNavigation={onNavigation}
          onRight={onRight}
          sealed={sealed}
          transparent={transparent}
          userIsAuthenticated={userIsAuthenticated}
          userStatus={userStatus}
          renderBlock={renderBlock}
          trackingBlocks={trackingBlocks}
          lang={lang}
          path={path}
        />
      );
    default:
      return (
        <Header
          isFull={isFull}
          isGalactic={isGalactic}
          window={window}
          mobileNavIsOpened={mobileNavIsOpened}
          checkForScroll={checkForScroll}
          elements={elements}
          renderBlock={renderBlock}
          openNavHeader={openNavHeader}
          closeNavHeader={closeNavHeader}
        />
      );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
