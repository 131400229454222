/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import breakpoints from '../constants/breakpoints';
import { ArrowSlider } from '../components/presentational/ArticleList/ArrowSlider';
import { ArticleListTemplateConst } from 'src/typings/constants/ArticleListTemplateConst';

function getSliderConfigDefault(topArrow, slidesToShow = 3) {
  return {
    dots: false,
    className: 'Slider',
    infinite: false,
    speed: 500,
    touchThreshold: 3,
    slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.phone, 10),
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: parseInt(breakpoints.tablet, 10),
        settings: { slidesToShow: 2 },
      },
    ],
    nextArrow: <ArrowSlider topArrow={topArrow} />,
    prevArrow: <ArrowSlider topArrow={topArrow} />,
  };
}

function getSliderConfigDetour(topArrow, slidesToShow = 3) {
  return {
    dots: false,
    className: 'Slider',
    infinite: false,
    speed: 500,
    touchThreshold: 3,
    slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.desktop, 10),
        settings: { slidesToShow: 1 },
      },
    ],
    nextArrow: <ArrowSlider topArrow={topArrow} ariaLabel="suivant" />,
    prevArrow: <ArrowSlider topArrow={topArrow} ariaLabel="précédent" />,
  };
}

export const getSliderConfig = (template: ArticleListTemplateConst, topArrow: number, slidesToShow = 3) => {
  switch (template) {
    case ArticleListTemplateConst.DETOUR:
      return getSliderConfigDetour(0, slidesToShow);
    default:
      return getSliderConfigDefault(topArrow, slidesToShow);
  }
};

export const getSliderMEAConfig = (speed = 5) => {
  const speedMillisecond = speed * 1000;

  return {
    dots: true,
    arrows: false,
    className: 'Slider',
    infinite: true,
    speed: 500,
    autoplaySpeed: speedMillisecond,
    autoplay: speedMillisecond > 0,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    touchThreshold: 3,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
};

export const getSliderHighlightConfig = () => ({
  dots: false,
  className: 'Slider',
  infinite: false,
  speed: 500,
  touchThreshold: 3,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: parseInt(breakpoints.desktop, 10),
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: parseInt(breakpoints.tablet, 10),
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: parseInt(breakpoints.phone, 10),
      settings: { slidesToShow: 1 },
    },
  ],
  nextArrow: <ArrowSlider />,
  prevArrow: <ArrowSlider />,
});

export const getSliderHighlightConfigCenterMode = (canUseCenter) => ({
  dots: false,
  className: `Slider${canUseCenter ? ' Slider--centered Slider--centered--wide' : ''}`,
  centerMode: canUseCenter,
  centerPadding: '18%',
  focusOnSelect: true,
  slidesToShow: 3,
  speed: 500,
  infinite: true,
  easing: 'linear',
  responsive: [
    {
      breakpoint: parseInt(breakpoints.tablet, 10) + 1,
      settings: {
        centerMode: canUseCenter,
        className: `Slider${canUseCenter ? ' Slider--centered Slider--centered--narrow' : ''}`,
        centerPadding: '30%',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: parseInt(breakpoints.phone, 10) - 1,
      settings: {
        className: 'Slider',
        slidesToShow: 1,
        centerMode: false,
        infinite: false,
      },
    },
  ],
  nextArrow: <ArrowSlider />,
  prevArrow: <ArrowSlider />,
});

export default getSliderConfig;
