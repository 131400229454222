import React, { ReactNode, useEffect, FunctionComponent } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import Button from '../Button';
import { State } from '../../../../typings/state/state';
import WORDINGS from 'src/shared/constants/wordings';

type Props = {
  closeModal: () => void;
  children: ReactNode;
  isModalOpen: boolean;
  lang?: string;
  articleModal: {
    ariaLabelledBy?: string;
  }
};

const mapStateToProps = (state: State) =>  ({
    lang: state?.page.lang,
    articleModal: state.page.articleModal
  });

const Modal: FunctionComponent<Props> = ({ articleModal, lang, isModalOpen, closeModal, children }) => {

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflowY = isModalOpen ? 'hidden' : 'auto';
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="reactModal">
          <ReactModal
            isOpen
            appElement={document.getElementsByClassName('content')}
            onRequestClose={closeModal}
            className="modal"
            overlayClassName="modalOverlay"
            closeTimeoutMS={500}
            aria={{
              labelledby: articleModal.ariaLabelledBy,
            }}
            shouldReturnFocusAfterClose={true}
          >
            <div className="modal__button">
              <Button 
                className="modal__button--close" 
                ariaLabel={lang && WORDINGS.CLOSE_MODAL[lang.toUpperCase()]} 
                icon="cross" 
                action={closeModal} 
              />
            </div>
            {children || <div className="modal__spinner" />}
          </ReactModal>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Modal);