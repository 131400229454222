/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';

export function setHeightImg(height: number): RootAction {
  return { type: 'SET_HEIGHT_IMG', payload: height };
}
