import React, { useState } from 'react';
import { TranscriptionModal } from '../TranscriptionModal'

type Props = {
  autoPlay: boolean;
  muted: boolean;
  loop: boolean;
  controls: boolean;
  playsInline: boolean;
  content: {
    url: string;
    transcriptionTitle: string | undefined;
    transcriptionText: string | undefined;
  };
};

export default function VGIVideoGif({ content, autoPlay, muted, loop, controls, playsInline }: Props) {
  const [showModalContent, setShowModalContent] = useState(false);
  const [focused, setFocused] = useState(false);

  const openTranscriptionModal = () => {
    setShowModalContent(true);
  }

  const closeTranscriptionModal = () => {
    setShowModalContent(false);
  }

  const onVgiGifFocus = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      onTranscriptionFocus();
    }
  }

  const onTranscriptionFocus = () => {
    setFocused(true);
  }

  const onTranscriptionBlur = () => {
    setFocused(false);
  }

  const isVgiVideoGifFocused = focused && content.transcriptionTitle;

  return (
    <>
      <div>
        <video
          className="vgi__gifVideo"
          src={content.url}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          controls={controls}
          playsInline={playsInline}
          tabIndex={0}
          onKeyDown={onVgiGifFocus}
        />

        <button className={`${isVgiVideoGifFocused && 'text__transcription text__transcription--gif'}`}
          style={{
            opacity: isVgiVideoGifFocused ? '1' : '0',
            height: isVgiVideoGifFocused ? 'auto' : '0',
          }}
          onKeyPress={openTranscriptionModal}
          onClick={openTranscriptionModal}
          tabIndex={0}
          onFocus={onTranscriptionFocus}
          onBlur={onTranscriptionBlur}
        >
          <div aria-expanded="false" className="text__transcription--videoButton">
            <i className="icon ai-arrow-right"></i>
            <span>{content.transcriptionTitle}</span>
          </div>
        </button>
      </div>

      {showModalContent &&
        <TranscriptionModal
          label={content.transcriptionTitle}
          transcription={content.transcriptionText}
          closeTranscriptionModal={closeTranscriptionModal}
          isTranscriptionModalOpen={showModalContent}
        />
      }
    </>
  )
}
