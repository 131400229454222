import { BannerVideo, VideoSource } from 'src/typings/constants/players';

const YOUTUBE_PATTERN = 'watch?v=';
const DAILYMOTION_PATTERN = 'video/';

const getVideoId = (videoUrl: string, splitBy) => videoUrl.split(splitBy)?.[1] || '';

export const extractVideoUrlInfo = (videoUrl: string): Omit<BannerVideo, 'transcription' | 'transcriptionTitle' | 'title'> => {
  if (videoUrl.includes(VideoSource.YOUTUBE)) {
    return {
      source: VideoSource.YOUTUBE,
      id: getVideoId(videoUrl, YOUTUBE_PATTERN),
    };
  }
  if (videoUrl.includes(VideoSource.DAILYMOTION)) {
    return {
      source: VideoSource.DAILYMOTION,
      id: getVideoId(videoUrl, DAILYMOTION_PATTERN),
    };
  }

  return {
    source: undefined,
    id: undefined,
  };
};
