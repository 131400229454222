import React, { useEffect, useState } from 'react';
import { SwitchLanguage }  from './SwitchLanguage';
import { setCookie } from 'src/shared/helpers/cookieHelper'
import { canUseDOM } from 'src/shared/helpers/windowHelper';
import { connect } from 'react-redux';
import { fetch, setLang } from 'src/shared/actions/page';
import { State } from '../../../../typings/state/state';

const mapDispatchToProps = (dispatch: Function) => ({
    setFetchAgain:(path: string, lang: string, langList: string[]) => {
        dispatch(fetch(path, undefined, undefined, lang, langList));
    },
    setLanguage:(lang: string) => {
        dispatch(setLang(lang));
    },
});

const mapStateToProps = (state: State) => ({
    urlTranslations: state.page.data.links[0].translations,
    langList: state.page.data.site.languages,
});

const updateCurrentUrl = (translatedSLug: string | undefined, selectOption: string, options: string[]) => {
    if(translatedSLug?.endsWith(`/${options[0].toLowerCase()}`) || translatedSLug?.endsWith(`/${options[1].toLowerCase()}`) ){
        window.history.replaceState( {} , translatedSLug, `/${selectOption.toLowerCase()}` ); 
    } else {
        window.history.replaceState(null, "", (translatedSLug?.indexOf(`/${selectOption.toLowerCase()}/`) == -1
        && translatedSLug?.endsWith(`/${options[0].toLowerCase()}`) == false
        && translatedSLug?.endsWith(`/${options[1].toLowerCase()}`) == false ) ?
        `/${selectOption.toLowerCase()}${translatedSLug == '/' ? '' : translatedSLug}` : translatedSLug );
    }
}

const SwitchLanguageContainer = ({  langList, urlTranslations,setLanguage, setFetchAgain, lang, menu, activeIndex , path, pathLocation }) => {
    let language = lang ? lang: 'FR';
    const [selectOption, setSelectOption] = useState(language);
    const options = langList;

    useEffect(() => {
        if(canUseDOM()){
            let requestUrl: string | undefined; 
            let translatedSLug: string | undefined; 
            
            // update the 'translated slug' for articles pages urls or navigation menu pages urls requests.
            if ( activeIndex == '-1') {
                if(path.startsWith('/articles/')){
                    const requestUrl = urlTranslations[selectOption.toLowerCase()].href;
                    translatedSLug = requestUrl.slice(requestUrl.indexOf('/articles/')) ?? '/';
                } 
                else {
                    langList.forEach(lang => {
                        if(pathLocation.pathname.indexOf(`/${lang.toLowerCase()}/`) != -1 || pathLocation.pathname.endsWith(`/${lang.toLowerCase()}`)) {
                            requestUrl = `/${lang.toLowerCase()}`;
                            setCookieLanguage(lang);
                        }
                        else {
                            requestUrl = pathLocation.pathname;
                        }
                    });
                    
                    translatedSLug = requestUrl?.slice(requestUrl?.indexOf('/')) ?? '/';
                } 
            }  
            else {
                requestUrl = menu[activeIndex]?.links[0].translations[selectOption.toLowerCase()];
                translatedSLug = requestUrl?.slice(requestUrl?.lastIndexOf('/')) ?? '/';
            }
            
            setFetchAgain(translatedSLug ? translatedSLug : `/${selectOption.toLowerCase()}/`, selectOption);
            setLanguage(selectOption);
            updateCurrentUrl(translatedSLug, selectOption, langList);
        }
        
    },[selectOption])

    const setCookieLanguage = (selectOption: string) => {
        setCookie('language', selectOption);
    }

    return (
        <SwitchLanguage options={options} onSwitchLang={setCookieLanguage(selectOption)}  selectOption={selectOption} setSelectOption={setSelectOption}/>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchLanguageContainer);
