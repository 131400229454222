/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
export enum ArticleListTypes {
  TEMPLATE_3_COL = '3columns',
  TEMPLATE_4_COL = '4articles',
  TEMPLATE_BIG_LEFT = 'bigleft',
  TEMPLATE_BIG_RIGHT = 'bigright',
  TEMPLATE_BIG_CENTER = 'bigcenter',
  TEMPLATE_HIGHLIGHT = 'highlight',
  TEMPLATE_CAROUSEL = 'carousel',
  TEMPLATE_2_BIG_LEFT = '2bigleft',
  TEMPLATE_2_BIG_RIGHT = '2bigright',
  TEMPLATE_2_BIG_CENTER = '2bigcenter',
  PORTRAIT = 'portrait',
}

// min article number to enable highlight center mode
export const HIGHLIGHT_CENTER_MODE_WIDE = 6;
export const HIGHLIGHT_CENTER_MODE_NARROW = 4;
