import React from 'react';
import Helmet from 'react-helmet';

import Text from '../Text';
import { ContentText } from 'src/typings/components/content';
import { Transcription } from '../Transcription';

const TextBlock = ({ content, className, widgetSrc }: { content: ContentText; className?: string; widgetSrc?: string }) => {
  const { transcription, text, transcriptionTitle } = content;

  return (
    <>
      {transcription ? (
        <Transcription className={className} transcription={text} label={transcriptionTitle} />
      ) : (
        <Text text={text} className={className} />
      )}
      {widgetSrc && (
        <Helmet>
          <script type="text/javascript" src={widgetSrc} />
        </Helmet>
      )}
    </>
  );
};
export default TextBlock;
