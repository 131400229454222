/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { connect } from 'react-redux';
import breakpoints from '../../../constants/breakpoints';
import { State } from '../../../../typings/state/state';
import MeaCarousel from '../../presentational/MeaCarousel';
import ConfigHelper from 'configHelper';
import MeaCarouselDetour from '../../presentational/MeaCarouselDetour';
import { MeaCarouselTemplateConst } from 'src/typings/constants/meaCarouselTemplateConst';
import { withRouter } from 'react-router-dom';
/**
 * Connected container for MeaVideoList component
 */
const mapStateToProps = (state: State) => ({
  isCompact: state.page.window ? state.page.window.width <= parseInt(breakpoints.tablet, 10) : false,
});

const template: MeaCarouselTemplateConst = ConfigHelper.getClientConfig('feature.meaCarousel.template');

const MeaCarouselContainer = connect(mapStateToProps)(
  template === MeaCarouselTemplateConst.DETOUR ? withRouter(MeaCarouselDetour) : MeaCarousel,
);

export default MeaCarouselContainer;
