import classNames from 'classnames';
import React, { FC } from 'react';

import { ContentCategoryList } from 'src/typings/components/content';
import { getArticleMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';
import CustomLink from '../../container/CustomLink';
import LazyLoader from '../LazyLoader';
import List from '../List';
import Title from '../Title';
import TruncateText from '../TruncateText';
import TruncateTitle from '../TruncateTitle';

type Props = {
  className: string;
  content: ContentCategoryList;
};

const CategoryList: FC<Props> = ({ className, content }) => (
  <div className={classNames(className, `category-list`)}>
    <Title title={content.title} level={2} />
    <div className={classNames(className, `category-list__grid`)}>
      <List>
        {content.categories.map((category) => (
          <div className={classNames('category-preview')} key={category.title}>
            <CustomLink to={getLinkFromData(category.category)}>
              <div className="category-preview__image">
                <LazyLoader offset={100} height={230}>
                  <img src={getArticleMedia(category.media).src} alt={getArticleMedia(category.media).description || ''} />
                </LazyLoader>
              </div>
              <TruncateTitle title={category.title} />
              <TruncateText text={category.description} />
            </CustomLink>
          </div>
        ))}
      </List>
    </div>
  </div>
);

export default CategoryList;
