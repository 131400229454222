import React, { useEffect, useMemo } from 'react';
import { Block } from 'src/typings/components/block';
import CookieConsentDidomi from '../../presentational/CookieConsentDidomi';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

export type FooterProps = {
  elements?: Block[];
  updateFooterHeight: (height: number) => void;
  renderBlock: (block: Block) => JSX.Element;
};

const Footer = ({ elements, renderBlock, updateFooterHeight }: FooterProps) => {
  const hasOmnitureFooter = useMemo(() => elements?.some((block) => block.type === PageBlockType.BLOCK_TYPE_OMNITURE_FOOTER), []);

  useEffect(() => {
    const footer = document ? document.getElementsByTagName('footer')[0] : null;
    const footerHeight = footer ? footer.offsetHeight : 0;
    updateFooterHeight(footerHeight);
  }, []);

  return (
    <footer className="footer" role="contentinfo">
      {hasOmnitureFooter && <CookieConsentDidomi />}
      {elements && elements.map(renderBlock)}
    </footer>
  );
};

export default Footer;
