import React from 'react';
import { NavLink, matchPath } from 'react-router-dom';

export const newTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

type link = {
  title: string;
  links: { rel: string; href: string }[];
};

export const isActive = (pathTarget, pathname, exact = false) => {
  // used to know if a route or a nested route is active or not
  const match = matchPath(pathname, {
    path: pathTarget,
    exact,
    strict: false,
  });

  return match !== null;
};

export const getActiveItemIndex = (links: link[], activePath: string, langList: string [] | undefined) => {
  //Test if the activePath contains the translate language path
  langList && langList.forEach(langPath => {
    const path = `/${langPath.toLowerCase()}/`; 
    
    if( activePath?.indexOf(path) != -1 ) {
      activePath = `/${activePath?.replace(path,'')}`;
    } else if (activePath.endsWith(`/${langPath.toLowerCase()}`) ) {
      activePath = '/';
    }
  });

  const paths = links.map((link) => {
    const pathName = link.links[0].href.split('/creativemedia');
    return pathName[1] ? pathName[1] : null;
  });

  return paths.indexOf(activePath);
};

export const createNavigationItems = ( navigation: link[], locale: string | string[], langList: string [] | undefined) => {

  return navigation.map(({ links, title }) => {
    let target: string[] = [];
    const [pathTarget] = links;

    if (pathTarget?.href && langList && langList.length > 0 && locale) {
      target = pathTarget.href.split('/creativemedia');
      if (target[1]) {
        target[1] = target[1] === '/' ? `/${locale}` : `/${locale}${target[1]}`;
      }
    }
    else if (pathTarget?.href) {
      target = pathTarget.href.split('/creativemedia');
    }
    
    return pathTarget.rel === 'external' ? (
      <a href={target[0] ? target[0] : ''} key={title} {...newTabProps}>
        {title}
      </a>
    ) : (
      <NavLink to={target[1] ? target[1] : ''} key={title}>
        {title}
      </NavLink>
    );
  });
}
