/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import { getLinkHref } from 'src/shared/helpers/uriHelper';
import { ContentLink } from 'src/typings/components/content';
import CustomLink from '../../container/CustomLink';

type Props = {
  content: ContentLink;
};

const LinkBlock = (props: Props) => {
  const { title, link } = props.content;

  return (
    <CustomLink to={getLinkHref(link[0])} className="link">
      {title}
    </CustomLink>
  );
};

export default LinkBlock;
