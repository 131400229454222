import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import breakpoints from '../../../constants/breakpoints';
import { getSliderHighlightConfig, getSliderHighlightConfigCenterMode } from '../../../helpers/sliderHelper';
import ArticlePreview from '../../presentational/ArticlePreview';
import { HIGHLIGHT_CENTER_MODE_WIDE, HIGHLIGHT_CENTER_MODE_NARROW } from '../../../../typings/constants/articleListTypes';
import { ContentArticleList } from 'src/typings/components/content';
import { ArticleBase } from 'src/typings/components/articleBase';
import { VideoSource } from 'src/typings/constants/players';
import SliderItemDetour from '../SliderItemDetour';
import { ArticleListTemplateConst } from 'src/typings/constants/ArticleListTemplateConst';

type State = {
  prev: number;
  current: number;
  next: number;
};

type Props = {
  isCompact: boolean;
  highlightCenterMode: boolean;
  dispatchToMea: boolean;
  focusedArticleId: number | null;
  content: ContentArticleList;
  onItemClick?: (e: any) => void;
  onFocusItemClick: (e: any) => (article: ArticleBase) => void;
  disableLazyLoad: boolean;
  displayTags: boolean;
  windowWidth: number;
  template: ArticleListTemplateConst;
  heightImg: number;
  setHeightImg: (height: number) => void;
};

class ArticleListHighlight extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      prev: props.content.articles.length - 1,
      current: 0,
      next: 1,
    };
  }

  onBeforeChange = (_oldIndex: number, newIndex: number) => {
    const lastIndex = this.props.content.articles.length - 1;
    this.setState({
      prev: newIndex === 0 ? lastIndex : newIndex - 1,
      current: newIndex,
      next: newIndex === lastIndex ? 0 : newIndex + 1,
    });
  };

  render() {
    const {
      isCompact,
      highlightCenterMode,
      content,
      dispatchToMea,
      focusedArticleId,
      onItemClick,
      onFocusItemClick,
      disableLazyLoad,
      displayTags,
      windowWidth,
      template,
      setHeightImg,
      heightImg,
    } = this.props;
    // Due to a bug in react-slick, which cannot take responsive setting correctly at first render
    // and will always take the default setting, cause client side flick once loaded
    // so we render nothing at server side to prevent incorrect layout
    // as it also makes no sense to fetch window based slide configs on server
    if (!windowWidth) {
      return null;
    }
    const { current, prev, next } = this.state;
    const articles = content.articles;
    const threshold = windowWidth >= parseInt(breakpoints.tablet, 10) ? HIGHLIGHT_CENTER_MODE_WIDE : HIGHLIGHT_CENTER_MODE_NARROW;
    const canUseCenter = articles.length >= threshold;
    const sliderConfig = highlightCenterMode ? getSliderHighlightConfigCenterMode(canUseCenter) : getSliderHighlightConfig();
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <Slider {...sliderConfig} beforeChange={this.onBeforeChange}>
          {articles.map((article, i) => {
            const videoArticleId = !!article.video ? article.id : false;
            const isMeaArticle = focusedArticleId ? focusedArticleId === videoArticleId : false;
            const itemClick =
              dispatchToMea && article.video && article.video.source !== VideoSource.PFV ? onFocusItemClick(article) : onItemClick;
            return template === ArticleListTemplateConst.DETOUR ? (
              <SliderItemDetour
                key={article.id}
                className={`highlight${i === current ? ' highlight--current' : ''}${i === prev || i === next ? ' highlight--active' : ''}`}
                isCompact={isCompact}
                onClick={itemClick}
                article={article}
                overlay={isMeaArticle}
                abstractExposed={content.abstractExposed}
                displayTags={displayTags}
                setHeightImg={setHeightImg}
                heightImg={heightImg}
              />
            ) : (
              <ArticlePreview
                isCompact={isCompact}
                articleClassName={`highlight${i === current ? ' highlight--current' : ''}${
                  i === prev || i === next ? ' highlight--active' : ''
                }`}
                onClick={itemClick}
                article={article}
                overlay={isMeaArticle}
                key={article.id}
                abstractExposed={false}
                disableLazyLoad={disableLazyLoad}
                displayTags={displayTags}
              />
            );
          })}
        </Slider>
        <div className="centerModeBlur -left" />
        <div className="centerModeBlur -right" />
      </div>
    );
  }
}

export default ArticleListHighlight;
