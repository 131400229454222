/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';
import { PageData } from 'src/typings/components/page';
import { ArticleBase } from '../../typings/components/articleBase';
import { IMeaPlaylist } from '../../typings/state/meaPlaylistState';
import { VideoSource } from '../../typings/constants/players';
import { PageBlockType } from './../../typings/constants/pageBlockTypes';
import { Block } from 'src/typings/components/block';
import { ContentArticleList, ContentMEAVideo } from 'src/typings/components/content';
import { VideoArticle } from 'src/typings/components/videoArticle';

const defaultState: IMeaPlaylist = {
  articleList: [],
  meaId: null,
  autoplay: false,
  dispatchToMea: false,
  focusedArticleId: null,
  meaPlaylistIndex: 0,
};

export function getArticleListAndMeaId(data: PageData): IMeaPlaylist {
  const allBlocks: Array<Block> = data.blocks;
  if (allBlocks) {
    const meaBlock: Block | undefined = allBlocks.find(
      (block) => block.type === PageBlockType.BLOCK_TYPE_MEA_VIDEO && block.content !== undefined,
    );
    if (meaBlock) {
      // if focusVideo is true, add all videoArticle of the page in playlist MEA
      if ((meaBlock.content as ContentMEAVideo).focusVideo) {
        const articleListBlocks: Block[] = allBlocks.filter((block) => block.type === PageBlockType.BLOCK_TYPE_ARTICLE_LIST);
        let articleList: VideoArticle[] = [(meaBlock.content as ContentMEAVideo).article];
        articleListBlocks.map((block): null => {
          articleList = articleList.concat(
            (block.content as ContentArticleList).articles.filter(
              (article: ArticleBase) => article.video && article.video.source !== VideoSource.PFV,
            ),
          );
          return null;
        });
        return {
          articleList,
          meaId: meaBlock.id,
          autoplay: (meaBlock.content as ContentMEAVideo).autoplay,
          dispatchToMea: true,
          focusedArticleId: null,
          meaPlaylistIndex: 0,
        };
      }
      const contentMEAVideo: ContentMEAVideo = meaBlock.content as ContentMEAVideo;
      return {
        articleList: [contentMEAVideo.article],
        meaId: meaBlock.id,
        autoplay: contentMEAVideo.autoplay,
        dispatchToMea: false,
        focusedArticleId: contentMEAVideo.article.id ? contentMEAVideo.article.id : null,
        meaPlaylistIndex: 0,
      };
    }

    return {
      articleList: [],
      meaId: null,
      autoplay: false,
      dispatchToMea: false,
      focusedArticleId: null,
      meaPlaylistIndex: 0,
    };
  }

  return defaultState;
}

export default function (state: IMeaPlaylist = defaultState, action: RootAction): IMeaPlaylist {
  let index: number;
  switch (action.type) {
    case 'FETCHED_PAGE':
      return {
        ...state,
        ...getArticleListAndMeaId(action.payload.data),
      };
    case 'SET_PLAYLIST_INDEX':
      return {
        ...state,
        meaPlaylistIndex: action.payload,
      };
    case 'SET_PLAYLIST_INDEX_FROM_ARTICLE_ID':
      index = state.articleList.findIndex((item) => item.id === action.payload);

      if (index !== -1) {
        return {
          ...state,
          meaPlaylistIndex: index,
        };
      }
      return state;

    default:
      return state;
  }
}
