import { PageBlockType } from './pageBlockTypes';
import {
  formatContentOmnitureHeader,
  formatContentArticleList,
  formatContentArticleBanner,
  formatContentCategoryList,
  formatContentContact,
  formatContentDownload,
  formatContentFooter,
  formatContentGalacticHeader,
  formatContentComment,
  formatContentCookieConsent,
  formatContentIframe,
  formatContentJSONLD,
  formatContentLink,
  formatContentLogo,
  formatContentMEA,
  formatContentMeaCarousel,
  formatContentMEAVideo,
  formatContentNavigation,
  formatContentRaw,
  formatContentSearch,
  formatContentSocialNetworksMenu,
  formatContentSocialFeed,
  formatContentStaticBlock,
  formatContentText,
  formatContentTitle,
  formatContentLogin,
  formatContentLang,
  formatContentArticleDynamic,
  formatContentArticleDynamicList,
  formatContentBannerHeader,
  formatContentFooterV5,
  formatContentListImage,
  formatContentVideoBanner,
  formatContentVGI,
  formatContentOfficeList,
} from 'src/shared/api/parsing/ContentParsing';
import { Content } from '../components/content';
import { Fragment } from '../components/fragment';

export const blockParsingFunc: {
  [key in PageBlockType]: (data: any, formatFragment?: (data: any) => Fragment) => Content;
} = {
  [PageBlockType.BLOCK_TYPE_ARTICLE]: formatContentArticleDynamic,
  [PageBlockType.BLOCK_TYPE_ARTICLE_BANNER]: formatContentArticleBanner,
  [PageBlockType.BLOCK_TYPE_ARTICLE_LIST]: formatContentArticleList,
  [PageBlockType.BLOCK_TYPE_ARTICLE_LIST_DYNAMIC]: formatContentArticleDynamicList,
  [PageBlockType.BLOCK_TYPE_CATEGORY_LIST]: formatContentCategoryList,
  [PageBlockType.BLOCK_TYPE_COMMENT]: formatContentComment,
  [PageBlockType.BLOCK_TYPE_CONTACT]: formatContentContact,
  [PageBlockType.BLOCK_TYPE_COOKIE]: formatContentCookieConsent,
  [PageBlockType.BLOCK_TYPE_DOWNLOAD]: formatContentDownload,
  [PageBlockType.BLOCK_TYPE_FOOTER]: formatContentFooter,
  [PageBlockType.BLOCK_TYPE_FOOTER_ADVANCED]: formatContentFooterV5,
  [PageBlockType.BLOCK_TYPE_GALACTIC_HEADER]: formatContentGalacticHeader,
  [PageBlockType.BLOCK_TYPE_IFRAME]: formatContentIframe,
  [PageBlockType.BLOCK_TYPE_JSONLD]: formatContentJSONLD,
  [PageBlockType.BLOCK_TYPE_LINK]: formatContentLink,
  [PageBlockType.BLOCK_TYPE_LOGO]: formatContentLogo,
  [PageBlockType.BLOCK_TYPE_IMAGE]: formatContentLogo,
  [PageBlockType.BLOCK_TYPE_IMAGE_LIST]: formatContentListImage,
  [PageBlockType.BLOCK_TYPE_MEA]: formatContentMEA,
  [PageBlockType.BLOCK_TYPE_MEA_CAROUSEL]: formatContentMeaCarousel,
  [PageBlockType.BLOCK_TYPE_MEA_VIDEO]: formatContentMEAVideo,
  [PageBlockType.BLOCK_TYPE_NAVIGATION]: formatContentNavigation,
  [PageBlockType.BLOCK_TYPE_OMNITURE_HEADER]: formatContentOmnitureHeader,
  [PageBlockType.BLOCK_TYPE_OMNITURE_FOOTER]: formatContentRaw,
  [PageBlockType.BLOCK_TYPE_RAW]: formatContentRaw,
  [PageBlockType.BLOCK_TYPE_SEARCH]: formatContentSearch,
  [PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS]: formatContentSocialNetworksMenu,
  [PageBlockType.BLOCK_TYPE_SOCIAL_FEED]: formatContentSocialFeed,
  [PageBlockType.BLOCK_TYPE_STATIC]: formatContentStaticBlock,
  [PageBlockType.BLOCK_TYPE_TEXT]: formatContentText,
  [PageBlockType.BLOCK_TYPE_TITLE]: formatContentTitle,
  [PageBlockType.BLOCK_TYPE_TITLE_DYNAMIC]: formatContentTitle,
  [PageBlockType.BLOCK_TYPE_LOGIN]: formatContentLogin,
  [PageBlockType.BLOCK_TYPE_LANG]: formatContentLang,
  [PageBlockType.BANNER_HEADER]: formatContentBannerHeader,
  [PageBlockType.BLOCK_TYPE_VIDEO_BANNER]: formatContentVideoBanner,
  [PageBlockType.BLOCK_TYPE_VGI]: formatContentVGI,
  [PageBlockType.BLOCK_TYPE_OFFICE_LIST]: formatContentOfficeList,
};
