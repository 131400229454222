import React from 'react';
import classNames from 'classnames';
import { removeScriptHtml } from 'src/shared/helpers/htmlHelper';

type Props = {
  text: string;
  className?: string;
};
/**
 * Text component
 * @param {string} content Content
 * @constructor
 */
class Text extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    const { text, className } = this.props;

    return <div className={classNames(className, 'text')} dangerouslySetInnerHTML={{ __html: removeScriptHtml(text) }} />;
  }
}

export default Text;
