/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import Error from '../../presentational/Error/Error';
import { State } from '../../../../typings/state/state';

const mapStateToProps = (state: State) => ({
  hasError: state.error.hasError,
  message: state.error.message,
});

const ErrorContainer = connect(mapStateToProps)(Error);

export default ErrorContainer;
