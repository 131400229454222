/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import ConfigHelper from 'configHelper';
import { toggleGalacticHeader } from '../../../actions/menu';
import GalacticMenu from '../../presentational/GalacticMenu';
import { State } from '../../../../typings/state/state';
/**
 * Connected container for GalacticMenu component
 */

const mapStateToProps = (state: State) => ({
  galacticMenuOpen: state.menu.galacticMenuOpen,
  template: ConfigHelper.getClientConfig('feature.galacticHeader.template'),
  appTitle: state.page.data.site.title,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  toggleGalacticHeader: () => {
    dispatch(toggleGalacticHeader());
  },
});

const GalacticMenuContainer = connect(mapStateToProps, mapDispatchToProps)(GalacticMenu);

export default GalacticMenuContainer;
