/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';

import { IMeaPlaylist } from '../../../../typings/state/meaPlaylistState';
import { VideoArticle } from '../../../../typings/components/videoArticle';
import VideoSwitch from '../VideoSwitch';
import { VideoSource } from 'src/typings/constants/players';
import { ContentMEAVideo } from 'src/typings/components/content';

export type MeaVideoListProps = {
  content: ContentMEAVideo;
  meaPlaylist: IMeaPlaylist;
  id: number;
  setCurrentIndex: (index: number) => void;
};

type LocalState = {
  hasError: boolean;
  shouldPlayNext: boolean;
};

/**
 * MeaVideo Component (Mise En Avant Video)
 */

const getVideoSource = (article: VideoArticle): VideoSource | undefined => article.video && article.video.source;
class MeaVideoList extends React.Component<MeaVideoListProps, LocalState> {
  sources: Array<VideoSource | undefined>;
  articleList: VideoArticle[];
  totalArticleList: number;
  isMultiple: boolean;
  refMeaVideo: any | undefined;

  constructor(props: MeaVideoListProps) {
    super(props);

    this.state = {
      hasError: false,
      shouldPlayNext: true,
    };
    this.articleList =
      this.props.meaPlaylist && this.props.id === this.props.meaPlaylist.meaId
        ? this.props.meaPlaylist.articleList
        : [this.props.content.article];
    this.sources = this.articleList.map(getVideoSource);
    this.totalArticleList = this.articleList.length;
    this.isMultiple = this.totalArticleList > 1;
  }

  componentDidUpdate(prevProps: MeaVideoListProps) {
    if (prevProps.meaPlaylist.meaPlaylistIndex !== this.props.meaPlaylist.meaPlaylistIndex) {
      if (this.refMeaVideo) {
        const rect = this.refMeaVideo.getBoundingClientRect();
        window.scroll(0, rect.top + window.scrollY);
      }
    }
  }

  onNextArticle = () => {
    if (this.props.meaPlaylist) {
      this.props.setCurrentIndex(this.props.meaPlaylist.meaPlaylistIndex + 1);
    }

    this.setState({
      hasError: false,
    });
  };

  isLastSource = (): boolean => this.props.meaPlaylist.meaPlaylistIndex === this.totalArticleList - 1;

  onEnd = () => {
    if (!this.isLastSource() && this.state.shouldPlayNext) {
      this.onNextArticle();
    }
  };

  onError = () => {
    if (!this.isLastSource() && this.state.shouldPlayNext) {
      this.setState({ hasError: true });
    }
  };

  setShouldPlayNext = () => {
    this.setState({ shouldPlayNext: !this.state.shouldPlayNext });
  };

  render() {
    const { meaPlaylist, content, id } = this.props;
    const isVideoList = meaPlaylist && id === meaPlaylist.meaId && this.articleList.length > 0;
    return (
      <div
        className="MeaVideo"
        ref={(el) => {
          this.refMeaVideo = el;
        }}
      >
        <VideoSwitch
          players={this.sources}
          currentArticle={isVideoList ? this.articleList[meaPlaylist.meaPlaylistIndex] : content.article}
          autoplay={isVideoList ? meaPlaylist.autoplay : content.autoplay}
          onEnd={this.onEnd}
          onError={this.onError}
          isLastSource={this.isLastSource()}
          setShouldPlayNext={this.setShouldPlayNext}
          hasError={this.isMultiple && this.state.hasError}
          isMultiple={this.isMultiple}
          shouldPlayNext={this.isMultiple && this.state.shouldPlayNext}
          onNextArticle={this.onNextArticle}
        />
      </div>
    );
  }
}

export default MeaVideoList;
