/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import Title from '../Title';
import Text from '../Text';
import LazyLoader from '../LazyLoader';
import { ContactElement as ContactElementType } from '../../../../typings/components/contactElement';
import { getContactMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import { removeScriptHtml } from 'src/shared/helpers/htmlHelper';
import { executeScriptFromHtml } from 'src/shared/helpers/scriptHelper';
import CustomLink from '../../container/CustomLink';

type Props = {
  element: ContactElementType;
};

class ContactElement extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { element } = this.props;
    if (!!element.mailchimp) {
      executeScriptFromHtml(element.mailchimp);
    }
  }

  render() {
    const { element } = this.props;
    const bgImage = element.media ? getContactMedia(element.media).src : null;
    const buttonLink = getLinkFromData(element);
    return (
      <aside className="contact__element">
        {bgImage ? (
          <LazyLoader offset={100} height={120}>
            <span className="contact__image" style={{ backgroundImage: `url(${bgImage})` }} />
          </LazyLoader>
        ) : null}

        <Title level={2} title={element.title} />
        <Text text={element.text} />
        {!!element.mailchimp && (
          <div
            className="input"
            aria-label="votre adresse email"
            dangerouslySetInnerHTML={{ __html: removeScriptHtml(element.mailchimp) }}
          />
        )}
        {!!buttonLink && (
          <CustomLink className="contact__button" to={buttonLink}>
            {element.buttonLabel || ''}
          </CustomLink>
        )}
      </aside>
    );
  }
}

export default ContactElement;
