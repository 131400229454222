/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';
import { IErrorState } from '../../typings/state/errorState';

const defaultState: IErrorState = { hasError: false, message: undefined, code: undefined };

export default function (state: IErrorState = defaultState, action: RootAction): IErrorState {
  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      hasError: true,
      message: action.message,
      code: action.statusCode,
    };
  }
  return state;
}
