/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { Component, createRef} from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import Button from '../Button';
import SearchResult from './SearchResult';
import { ContentArticleList, ContentSearch } from 'src/typings/components/content';
import WORDINGS from 'src/shared/constants/wordings';

type Props = {
  pending: boolean;
  content: ContentSearch;
  result: ContentArticleList | null;
  keyword: string;
  blockId: number | null;
  changeKeyword: (value: string) => void;
  search: (value: string) => void;
  clearSearch: () => void;
  toggleDisplay: (isOpen: boolean) => void;
  className?: string;
  isOpen: boolean;
  closeButtonRef: React.RefObject<HTMLButtonElement>;
};

type State = {};


/**
* Search component
*/
class Search extends Component<Props, State> {
  refInput: any;
  private closeButtonRef: React.RefObject<HTMLButtonElement>;

  static defaultProps = {
    className: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.closeButtonRef = createRef<HTMLButtonElement>();

    this.doSearch = debounce(this.doSearch, 300);
  }

  componentDidUpdate = (oldProps: Props) => {
    if (oldProps.pending === true && this.props.pending === false) {
      this.closeButtonRef?.current?.focus();
    }
  };

  doSearch = (keyword: string): void => {
    this.props.search(keyword);
  };

  onSearchValueChange = (evt: React.SyntheticEvent) => {
    const { value } = evt.target as HTMLInputElement;
    this.props.changeKeyword(value);
  };

  onKeyPress = (evt: React.KeyboardEvent) => {
    const { value } = evt.target as HTMLInputElement;

    if (evt.charCode === 13) {
      evt.preventDefault();
      this.doSearch(value);
    }
  };

  /**
   * Toggle the search component
   */
  toggleDisplay = () => {
    this.props.clearSearch();
    this.clearInputSearchField();

    this.props.toggleDisplay(!this.props.isOpen);
  };

  clearInputSearchField = () => {
    this.props.changeKeyword('');
  };

  render() {
    const { className, result, blockId, pending, isOpen, keyword } = this.props;

    return (
      <div
        className={classNames(className, 'search', {
          'search--open': isOpen,
        })}
      >
        <button
          title="Close search"
          onClick={this.toggleDisplay}
          type="button"
          className={classNames('search__overlay', {
            search__overlay_visible: isOpen,
          })}
        />
        <Button className="search__toggle-button" icon="glass" action={this.toggleDisplay} />
        <div className="search__input-wrapper">
          <form name="searchForm" action="#">
            <input
              aria-label="Recherche"
              id="search-input"
              name="search-input"
              type="text"
              className="search__input"
              placeholder={WORDINGS.SEARCH_PLACEHOLDER}
              onChange={this.onSearchValueChange}
              onKeyPress={this.onKeyPress}
              value={keyword}
              disabled={pending}
              ref={(el) => {
                this.refInput = el;
              }}
            />
            <Button 
              className="button--close" 
              icon="cross" 
              ref= {this.closeButtonRef}
              action={this.toggleDisplay} 
              isCloseButton 
            />
          </form>
        </div>
        {isOpen && (
          <SearchResult onClickHandler={this.toggleDisplay} result={result} blockId={blockId} pending={pending} keyword={keyword} />
        )}
      </div>
    );
  }
}

export default Search;
