/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';
import { IAuthState } from './../../typings/state/authenticationState';

const defaultState: IAuthState = {
  error: undefined,
  username: undefined,
  token: undefined,
  isAuthenticated: false,
  isLoginInProgress: false,
};

export default function (state: IAuthState = defaultState, action: RootAction): IAuthState {
  switch (action.type) {
    case 'LOGIN_IN_PROGRESS':
      return {
        ...state,
        isLoginInProgress: true,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        isLoginInProgress: false,
        error: action.error,
      };
    case 'LOGIN_SUCCESSFUL':
    case 'LOGIN_FROM_COOKIE':
      return {
        ...state,
        error: undefined,
        username: action.username,
        token: action.token,
        isAuthenticated: true,
        isLoginInProgress: false,
      };
    default:
      return state;
  }
}
