import '@canalplus/mycanal-sharedcomponent/dist/card.min.css';
import '@canalplus/mycanal-creativemedia/dist/creativemedia.min.css';

import React from 'react';
import Card from '@canalplus/mycanal-sharedcomponent/Card';
import CreativeMediaCard from '@canalplus/mycanal-creativemedia/dist/creativemediacard.min.js';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

type Props = {
  item?: string;
  ratio?: number;
  image?: string;
  title?: string;
  subtitle?: string;
  linkLabel?: string;
  altImage?: string;
  id?: number;
  type?: PageBlockType;
};

const Item = ({ item = 'card', ratio = 169, image, title, subtitle, linkLabel, altImage, id, type }: Props) => {
  const components = {
    card: Card,
    creativeMediaCard: CreativeMediaCard,
  };
  const ComponentTag = components[item];

  const ItemCard = (): JSX.Element =>
    <>
      {ComponentTag && (
        <ComponentTag
          dimensions={{ XLarge: '332x186' }}
          ratio={ratio.toString()}
          image={{ default: image }}
          title={title}
          subtitle={subtitle}
          altImage={altImage}
          id={id}
        />
      )}
      {linkLabel && linkLabel}
    </>;

  if (type === PageBlockType.BLOCK_TYPE_OFFICE_LIST) {
    return (
      <div className='canalplus-card--poster'>
        <ItemCard />
      </div>
    )
  } else {
    return <ItemCard />
  }
};

export default Item;
