const WORDINGS = {
  SHARE_MAIL_BODY: 'Je te recommande cet article :',
  SEARCH_PLACEHOLDER: 'Saisissez votre recherche',
  NEXT_VIDEO: 'Passer à la vidéo suivante',
  AUTO_PLAY: 'Lecture automatique',
  CLOSE_MODAL: {
    FR: 'Fermer',
    EN: 'Close',
  },
};

export default WORDINGS;
