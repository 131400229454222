import React from 'react';
import CustomLink from '../../container/CustomLink';
import { getImageWithSize } from 'src/shared/helpers/imageHelper';
import { Media } from 'src/typings/components/media';

type Props = {
  title: string;
  uri: string;
  disableLink: boolean;
  media: Media;
  onClick?: (e: React.SyntheticEvent) => void;
};

class NavigationItem extends React.PureComponent<Props> {
  static defaultProps = {
    title: '',
    uri: '',
    disableLink: false,
    onClick: () => {},
  };
  render() {
    const { title, uri, disableLink, media, onClick } = this.props;
    if (disableLink) {
      return (
        <button className="navigation__item" onClick={onClick}>
          {media ? (
            <img className="navigation__item__logo" alt={title} src={getImageWithSize({ height: '25' }, media.links[0].href)} />
          ) : (
            <span>{title}</span>
          )}
        </button>
      );
    }
    return (
      <CustomLink className="navigation__item" to={uri}>
        {media ? (
          <img className="navigation__item__logo" alt={title} src={getImageWithSize({ height: '25' }, media.links[0].href)} />
        ) : (
          <span>{title}</span>
        )}
      </CustomLink>
    );
  }
}

export default NavigationItem;
