/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-disable global-require */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { State } from '../typings/state/state';
import * as serviceWorker from './serviceWorker';
import configureStore from '../shared/redux/configureStore';
import App from '../shared/components/presentational/App';
import TaskRoutesExecutor from './components/TaskRoutesExecutor';
import { resize, setDevice, fetchAgain } from '../shared/actions/page';
import { loginFromCookie } from '../shared/actions/authentication';
import { getJwtUserData, UserData } from '../shared/helpers/jwtHelper';
import ResizeService from '../shared/service/ResizeService';
import DeviceService from '../shared/service/DeviceService';
import { WindowDimension } from '../typings/state/pageState';
import { ThunkDispatch } from 'redux-thunk';
import { RootAction } from 'src/typings/rootActions';
import { WindowApp } from 'src/typings/global';

// Get the DOM Element that will host our React application.
const container = document.getElementById('app');

if (container === null) {
  throw new Error('Could not find container DOM element.');
}

// Create our Redux store.
const store = configureStore({
  // Server side rendering would have mounted our state on this global.
  initialState: (window as unknown as WindowApp).APP_STATE,
});

// If we have a JWT token, login from it
const userData: UserData | null = getJwtUserData();
if (userData) {
  store.dispatch(loginFromCookie(userData.username, userData.token));
  const state: State = store.getState();

  // If the server ended with a 401 page, fetch it again since we are now logged
  if (state.page && state.page.data && state.page.data.statusCode === 401) {
    (store.dispatch as ThunkDispatch<State, {}, RootAction>)(fetchAgain());
  }
}

// init ResizeService
const resizeService = new ResizeService((window: WindowDimension, noDispatch: boolean) => {
  if (!noDispatch) {
    store.dispatch(resize(window));
  }
});

// init Device Service
const deviceService = new DeviceService().getDevice();
store.dispatch(setDevice(deviceService));
resizeService.attach();

// handlers attached, render our APP
ReactDOM.hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <TaskRoutesExecutor dispatch={store.dispatch}>
        <App lang={store.getState().page.lang} />
      </TaskRoutesExecutor>
    </BrowserRouter>
  </Provider>,
  container,
);

resizeService.onResize();

serviceWorker.register();
