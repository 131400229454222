import React from 'react';
import { connect } from 'react-redux';

import breakpoints from 'src/shared/constants/breakpoints';
import SliderAdvanced from '../../presentational/content-row/SliderAdvanced/SliderAdvanced';
import { State } from 'src/typings/state/state';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

const mapStateToProps = ({ page }: State) => ({
  isCompact: page?.window?.width <= parseInt(breakpoints.tablet, 10),
});

const OfficeListContainer = ({ content, isCompact }) => {
  const { offices, title } = content;

  const officeList = offices.map((office) => ({ ...office, alt: office.mainMedia.alt }));

  if (officeList) {
    return <SliderAdvanced list={officeList} title={title} isCompact={isCompact} type={PageBlockType.BLOCK_TYPE_OFFICE_LIST} />;
  }
  return null;
};

export default connect(mapStateToProps, {})(OfficeListContainer);
