import React from 'react';

import ArticleListContainer from '../../container/ArticleList';
import Loader from '../Loader';
import Text from '../Text';
import { ContentArticleList } from 'src/typings/components/content';

type TProps = {
  result: ContentArticleList | null;
  blockId: number | null;
  pending: boolean;
  onClickHandler?: () => void;
  keyword: string;
};

const SearchResult = ({ blockId, result, pending, onClickHandler, keyword }: TProps) => {
  const resultCount = result?.articles.length || 0;

  const resultInfo = () => (
    (resultCount > 0) ?
      <p className="search__result__count" aria-live="polite" aria-atomic="true" tabIndex={-1}>
        Résultat{resultCount > 1 ? 's' : ''} pour le terme <strong>{keyword}</strong> : {resultCount}
      </p>
      :
      null
  );

  const closeResultList = () =>
    onClickHandler ? (
      <button className="button button--rounded button--back" onClick={() => onClickHandler()}>
        Fermer la recherche
      </button>
    ) : null;

  return (
    <div aria-live="polite" role="region" className="search__result">
      {resultInfo()}
      {pending && <Loader />}
      {!pending && blockId && result && result.articles.length > 0 && (
        <ArticleListContainer content={result} id={blockId} onItemClick={onClickHandler} isSearch disableLazyLoad />
      )}
      {!pending && (!blockId || (result && result.articles.length === 0)) && <Text className="no-result" text="Aucun résultat." />}
      {closeResultList()}
    </div>
  );
};

export default SearchResult;
