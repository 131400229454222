export const getCookie = (name) => {
  const cookies = document.cookie.split(';').reduce((acc, cookieString) => {
    const [key, value] = cookieString.split('=').map((s) => s.trim());
    if (key && value) {
      acc[key] = decodeURIComponent(value);
    }
    return acc;
  }, {});
  return name ? cookies[name] || '' : cookies;
};

export const setCookie = (prop: string, value: string) => {
  const daysToExpire = `expires= + ${new Date(2147483647 * 1000).toUTCString()}`;
  if (typeof window !== 'undefined') {
    document.cookie = `${prop}=${value};${prop === 'language' && daysToExpire};path=/`;
  }
};
