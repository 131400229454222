/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';

import { RootAction } from '../../../../typings/rootActions';
import { ThunkDispatch } from 'redux-thunk';
import Search from '../../presentational/Search';
import { search, clearSearch, toggleDisplay, changeKeyword } from '../../../actions/search';
import { PageBlockType } from './../../../../typings/constants/pageBlockTypes';
import { State } from '../../../../typings/state/state';
import { Block } from 'src/typings/components/block';
import { ContentArticleList } from 'src/typings/components/content';

/**
 * Search container component
 * @class
 */

const mapStateToProps = (state: State) => {
  if (state.search.result) {
    const block: Block | undefined = state.search.result.blocks.find((item) => item.type === PageBlockType.BLOCK_TYPE_ARTICLE_LIST_DYNAMIC);
    return {
      pending: state.search.pending,
      isOpen: state.search.isOpen,
      keyword: state.search.keyword,
      result: block ? (block.content as ContentArticleList) : null,
      blockId: block ? block.id : null,
    };
  }
  return {
    pending: state.search.pending,
    isOpen: state.search.isOpen,
    keyword: state.search.keyword,
    result: null,
    blockId: null,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, { uniqueId?: string }, RootAction>) => ({
  search: (value: string) => {
    dispatch(search(value));
  },
  clearSearch: () => {
    dispatch(clearSearch());
  },
  toggleDisplay: (isOpen: boolean) => {
    dispatch(toggleDisplay(isOpen));
  },
  changeKeyword: (keyword: string) => {
    dispatch(changeKeyword(keyword));
  },
});

const SearchContainer = connect(mapStateToProps, mapDispatchToProps)(Search);

export default SearchContainer;
