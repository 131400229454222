/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import Title from './../Title';

type Props = {
  hasError: Boolean;
  message?: string;
};

export default class Error extends React.PureComponent<Props> {
  render() {
    const { hasError, message } = this.props;

    return (
      <div>
        {hasError && (
          <div className="error-overlay">
            <div className="error-overlay__message">
              <Title level={1} title="Une erreur est survenue" />
              {message && <div className="error-overlay__details">{message}</div>}
              <a className="contact__button" href="/">
                {"Retour à la page d'accueil"}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
