import { FragmentType } from './fragmentTypes';
import {
  formatFragmentAuthor,
  formatFragmentDate,
  formatFragmentIntro,
  formatFragmentQuote,
  formatFragmentNetworks,
  formatFragmentTag,
  formatFragmentVideo,
  formatFragmentAdvertising,
  formatFragmentArticleList,
} from 'src/shared/api/parsing/FragmentParsing';
import {
  formatContentDownload,
  formatContentPicture,
  formatContentIframe,
  formatContentTitle,
  formatContentRaw,
  formatContentText,
  formatContentEmptyLine,
  formatContentComment,
} from 'src/shared/api/parsing/ContentParsing';
import { FragmentContent } from 'src/typings/components/fragment';
import { formatApiMediaItemContent } from 'src/shared/api/parsing/ElementParsing';

export const fragmentParsingFunc: { [key in FragmentType]: (data: any) => FragmentContent } = {
  [FragmentType.FRAGMENT_TYPE_AUTHOR]: formatFragmentAuthor,
  [FragmentType.FRAGMENT_TYPE_DATE]: formatFragmentDate,
  [FragmentType.FRAGMENT_TYPE_DOWNLOAD]: formatContentDownload,
  [FragmentType.FRAGMENT_TYPE_IMAGE]: formatContentPicture,
  [FragmentType.FRAGMENT_TYPE_MAIN_IMAGE]: formatContentPicture,
  [FragmentType.FRAGMENT_TYPE_IFRAME]: formatContentIframe,
  [FragmentType.FRAGMENT_TYPE_INTRO]: formatFragmentIntro,
  [FragmentType.FRAGMENT_TYPE_MAIN_TITLE]: formatContentTitle,
  [FragmentType.FRAGMENT_TYPE_QUOTE]: formatFragmentQuote,
  [FragmentType.FRAGMENT_TYPE_RAW]: formatContentRaw,
  [FragmentType.FRAGMENT_TYPE_RELATED_ARTICLES]: formatFragmentArticleList,
  [FragmentType.FRAGMENT_TYPE_SOCIAL_NETWORKS]: formatFragmentNetworks,
  [FragmentType.FRAGMENT_TYPE_TAG]: formatFragmentTag,
  [FragmentType.FRAGMENT_TYPE_TEXT]: formatContentText,
  [FragmentType.FRAGMENT_TYPE_EMPTY_LINE]: formatContentEmptyLine,
  [FragmentType.FRAGMENT_TYPE_TITLE]: formatContentTitle,
  [FragmentType.FRAGMENT_TYPE_VIDEO]: formatFragmentVideo,
  [FragmentType.FRAGMENT_TYPE_COMMENT]: formatContentComment,
  [FragmentType.FRAGMENT_TYPE_MEDIA_LIST]: formatApiMediaItemContent,
  [FragmentType.FRAGMENT_TYPE_ADVERTISING]: formatFragmentAdvertising,
};
