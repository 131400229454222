/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import { ContainerType } from '../../typings/constants/containerTypes';
import { blockComp } from '../../typings/constants/pageBlockMappings';
import { fragmentComp } from '../../typings/constants/pageFragmentMappings';
import { containerComp } from '../../typings/constants/pageContainerMappings';
import { Block } from '../../typings/components/block';
import ErrorBoundary from './presentational/ErrorBoundries';
import { Fragment } from '../../typings/components/fragment';

/**
 * Create a component from the given parameters
 *
 * @param {number} technicalId    Id of the block or the fragment
 * @param {string} type           Type of the block
 * @param {string} id             CSS id of the component
 * @param {object} content        Content of the component (the settings)
 * @param {object} children       Child components
 * @param {string|null} className Optional class name to add to the component
 * @returns {React.Component}
 */

export function renderFragment({ id, type, content }: Fragment) {
  const Comp: any | undefined = fragmentComp[type] || undefined;

  return (
    <ErrorBoundary
      key={`fragement-${id}`}
      title="Error fragment"
      errorText={`Votre fragment de type ${type} manque d'informations et il ne peut être affiché.`}
      error={!Comp || content === undefined}
    >
      {Comp && content ? (
        <div key={`articleContent-${id}`} className="article__content">
          <Comp id={id} type={type} className="article__fragment" content={content} />
        </div>
      ) : null}
    </ErrorBoundary>
  );
}

export function renderBlock({ id, type, content }: Block) {
  const Comp: any | undefined = blockComp[type] || undefined;
  const errorText = `Votre block de type ${type} manque d'informations et il ne peut être affiché.`;

  const classType: string = type.replace(/\./g, '-');
  return (
    <div className={`block ${classType}`} key={`block-${id}`}>
      <ErrorBoundary title="Error block" errorText={errorText} error={!Comp || content === undefined}>
        {Comp && content ? <Comp content={content} id={id} type={type} renderBlock={renderBlock} renderFragment={renderFragment} /> : null}
      </ErrorBoundary>
    </div>
  );
}

export function renderPageContainer(type: ContainerType) {
  const Comp: any | undefined = containerComp[type] || undefined;
  return (
    <ErrorBoundary
      key={`container-${type}`}
      title="Error Container"
      errorText={`Votre container de type ${type} a des erreurs et il ne peut être affiché.`}
      error={!Comp}
    >
      {Comp ? <Comp key={`container-${type}`} renderBlock={renderBlock} renderFragment={renderFragment} /> : null}
    </ErrorBoundary>
  );
}
