/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Component } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { getSliderMEAConfig } from '../../../helpers/sliderHelper';
import MEA from '../MEA/MEA';
import { ContentMeaCarousel } from 'src/typings/components/content';
import { RouteComponentProps } from 'react-router';
import { getLinkFromData } from '../../../helpers/uriHelper';

type Props = RouteComponentProps & {
  content: ContentMeaCarousel;
  isCompact: boolean;
};
type MeaCarouselState = {
  controlItemPos: Array<string>;
  currentIndexSelected: number;
  ready: boolean;
  focusedItem: boolean;
};

class MeaCarouselDetour extends Component<Props, MeaCarouselState> {
  slider: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      controlItemPos: [],
      currentIndexSelected: 0,
      ready: true,
      focusedItem: false,
    };
  }

  componentDidMount() {
    this.setPosControlItems(this.state.currentIndexSelected);
    this.setState({ ready: false }, () => {
      this.setState({ ready: true });
    });
  }

  setPosControlItems = (currentIndexSelected: number) => {
    let controlItemPos: Array<string> = [];
    if (this.props.content) {
      let count: number = 1;
      const max: number = this.props.content.columns.length;

      controlItemPos = this.props.content.columns.map((col, i) => {
        let val: string;
        if (i === currentIndexSelected) {
          val = '50%';
          count = 0;
        } else if (i < currentIndexSelected) {
          val = (46 / (currentIndexSelected + 1)) * count + '%';
        } else {
          val = 54 + (46 / (max + 1 - (currentIndexSelected + 1))) * count + '%';
        }
        count++;
        return val;
      });
    }

    this.setState({ controlItemPos, currentIndexSelected });
  };

  onSliderChange = (oldIndex: number, newIndex: number) => {
    this.setPosControlItems(newIndex);
  };

  onClickControlItem = (index: number) => {
    if (this.slider) {
      this.slider.slickGoTo(index);
    }
  };

  slickPause = () => {
    if (this.slider) {
      this.slider.slickPause();
    }
  };

  slickPlay = () => {
    this.setState({ focusedItem: false });
    if (this.slider) {
      this.slider.slickPlay();
    }
  };

  handleFocus = (itemIndex: number) => {
    this.slickPause();
    this.onClickControlItem(itemIndex);
    this.setState({ focusedItem: true });
  };

  handleKeyPressOnFocus = (event: React.KeyboardEvent, link: string) => {
    if(event.key === 'Enter'){
      this.props.history.push(link);
    }
  }

  render() {
    const { isCompact, content } = this.props;
    const { slideTime, columns } = content;
    const { controlItemPos, currentIndexSelected, ready } = this.state;

    const settingsSlider: any = getSliderMEAConfig(slideTime);
    settingsSlider.beforeChange = this.onSliderChange;
    return (
      <div className={classNames('mea-carouselDetour')}>
        {ready && (
          <Slider {...settingsSlider} ref={(slider) => (this.slider = slider)}>
            {columns.map((col, index) => {
              if (col.article) {
                return (
                  <div className="Slider__ItemWrapper" key={col.article.id}>
                    <MEA onBlur={this.slickPlay} onFocus={() => this.handleFocus(index)} isCompact={isCompact} content={col} />
                  </div>
                );
              }
              return null;
            })}
          </Slider>
        )}

        <div className="mea-carouselDetour__control">
          <ul role="tablist" className="mea-carouselDetour__controlList">
            {columns.map((col, i) => {
              if (col.article) {
                return (
                  <li
                    role="tab"
                    key={`carouselItem-${col.article.title}`}
                    className={classNames('mea-carouselDetour__controlItem', {
                      'mea-carouselDetour__controlItem_selected': currentIndexSelected === i,
                    })}
                    style={{ left: controlItemPos[i] }}
                  >
                    <button
                      onMouseEnter={this.slickPause}
                      onMouseLeave={this.slickPlay}
                      onBlur={this.slickPlay} 
                      onFocus={() => this.handleFocus(i)}
                      onClick={((i) => () => {
                        this.onClickControlItem(i);
                      })(i)}
                      onKeyPress={(e) => col.article && this.handleKeyPressOnFocus(e, getLinkFromData(col.article))}
                    >
                      <span 
                        className={classNames('text', 'text__truncate', {
                          'text__focus-visible': currentIndexSelected === i && this.state.focusedItem,
                        })}
                      >
                        {col.article.title}
                      </span>
                    </button>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default MeaCarouselDetour;
