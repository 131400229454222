import React from 'react';
import CustomLink from '../../container/CustomLink';
import Text from '../Text';
import Title from '../Title';
import Button from '../Button';
import { ContentCookieConsent } from 'src/typings/components/content';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';
import { setCookie } from 'src/shared/helpers/cookieHelper';

type Props = {
  site: string;
  content: ContentCookieConsent;
};

type State = {
  visible: boolean;
};

class CookieConsent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { visible: false };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      let cookies = '';
      let visible = true;
      cookies = document.cookie;
      visible = cookies.indexOf(`${this.props.site}.law`) === -1;

      this.setState({ visible }); // eslint-disable-line
    }
  }

  close = () => {
    this.setState({ visible: false });
    // create cookie
    setCookie(`${this.props.site}.law`, 'true');
  };

  render() {
    const { content } = this.props;
    const { howto, disclaimer, title } = content;
    const link = getLinkFromData(content);
    return (
      <div className="cookie-consent" style={{ display: this.state.visible ? 'block' : 'none' }}>
        <Title level={4} title={title} className="cookie-consent__title" />
        <CustomLink to={link} className="cookie-consent__link">
          <span>{howto}</span>
        </CustomLink>
        <Text text={disclaimer} className="cookie-consent__text" />
        <Button className="cookie-consent__button" action={this.close} icon="cross" />
      </div>
    );
  }
}

export default CookieConsent;
