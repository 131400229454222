import React from 'react';

import VideoSwitch from '../../presentational/VideoSwitch';
import { ContentVideoBanner } from 'src/typings/components/content';
import { extractVideoUrlInfo } from 'src/shared/helpers/videoHelper';

const VideoBannerContainer = ({ content }: { content: ContentVideoBanner }) => {
  const { transcriptionText, transcriptionTitle, videoUrl } = content;
  const { id, source } = extractVideoUrlInfo(videoUrl);

  const videoInfo = {
    transcription: transcriptionText,
    transcriptionTitle: transcriptionTitle,
    id,
    source,
  };

  return (
    <div className="videoBanner">
      <VideoSwitch players={[source]} currentArticle={{ video: videoInfo }} autoplay={false} />
    </div>
  );
};

export default VideoBannerContainer;
