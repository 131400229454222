import ConfigHelper from 'configHelper';
import { SocialShare } from '../../typings/constants/socialNetworks';
import WORDINGS from '../constants/wordings';

export const getSiteDomain = () => `${ConfigHelper.getClientConfig('scheme')}://${ConfigHelper.getClientConfig('domain')}`;

export const getSocialLink = (networkId, pageURI, pageName) => {
  const domain = getSiteDomain();
  const pageNameEncoded = encodeURIComponent(pageName);

  switch (networkId) {
    case SocialShare.FACEBOOK:
      return `https://www.facebook.com/sharer/sharer.php?u=${domain + pageURI}`;
    case SocialShare.TWITTER:
      return `http://twitter.com/share?text=${pageNameEncoded}&url=${domain + pageURI}`;
    case SocialShare.GOOGLE:
      return `https://plus.google.com/share?url=${domain + pageURI}`;
    case SocialShare.TUMBLR:
      return `https://www.tumblr.com/share/link?name=${pageNameEncoded}&url=${domain + pageURI}`;
    case SocialShare.LINKEDIN:
      return `http://www.linkedin.com/shareArticle?mini=true&url=${domain + pageURI}`;
    default:
      return `mailto:?Subject=${pageNameEncoded}&body=${WORDINGS.SHARE_MAIL_BODY} ${domain + pageURI}`;
  }
};

export const getAbsoluteLink = (pageURI: string | null): string => `${getSiteDomain()}${pageURI || ''}`;
