import classNames from 'classnames';
import React, { FunctionComponent, useMemo, ReactNode, useState, useCallback } from 'react';

import { ArticleBase } from '../../../../typings/components/articleBase';
import { ArticleListTemplateConst } from '../../../../typings/constants/ArticleListTemplateConst';
import { ArticleListTypes } from '../../../../typings/constants/articleListTypes';
import { ContentArticleList } from '../../../../typings/components/content';
import { formatColumns, getReadMoreLink, Column, ColumnsPos } from '../../../helpers/articleListHelper';
import Modal  from '../Modal/Modal';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { VideoSource } from '../../../../typings/constants/players';
import { WindowDimension } from '../../../../typings/state/pageState';
import ArticleListBigFour from '../ArticleListBigFour';
import ArticleList3Columns from '../ArticleList3Columns';
import ArticleListCarousel from '../ArticleListCarousel';
import ArticleListHighlight from '../ArticleListHighlight';
import ArticlePreview from '../ArticlePreview';
import CreativeMediaArticle from '../CreativeMediaArticle/CreativeMediaArticle';
import CustomLink from '../../container/CustomLink';
import List from '../List';
import NextLinkContainer from '../../container/NextLink/NextLinkContainer';
import SliderItemDetour from '../SliderItemDetour';
import Title from '../Title';

export type ArticleListProps = {
  className?: string;
  content: ContentArticleList;
  device: string;
  disableLazyLoad: boolean;
  dispatchToMea: boolean;
  displayTags: boolean;
  focusedArticleId: number | null;
  heightImg: number;
  highlightCenterMode: boolean;
  id: number;
  isCompact: boolean;
  isSearch?: boolean;
  lang?: string;
  listsAsCarousel: boolean;
  onFocusItemClick: (article: ArticleBase) => (e: any) => void;
  onItemClick?: () => void;
  renderArticleContent: (path: string | null, lang?: string | undefined) => Promise<ReactNode[] | null>;
  searchIsOpen: boolean;
  setHeightImg: (height: number) => void;
  template: ArticleListTemplateConst;
  articleListType?: ArticleListTypes;
  token?: string;
  type?: PageBlockType;
  windowSize: WindowDimension;
};

const ArticleList: FunctionComponent<ArticleListProps> = ({
  className,
  content,
  device,
  disableLazyLoad,
  dispatchToMea,
  displayTags,
  focusedArticleId,
  heightImg,
  highlightCenterMode,
  id,
  isCompact,
  isSearch,
  listsAsCarousel,
  onFocusItemClick,
  onItemClick,
  searchIsOpen,
  renderArticleContent,
  setHeightImg,
  template,
  articleListType,
  windowSize,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode[] | null>(null);

  const closeModal = useCallback(() => {
    window.history.replaceState(null, '', '/');

    setModalContent(null);
    setIsModalOpen(false);
  }, []);

  const RenderColumn = useCallback(
    (colPosition: ColumnsPos, articles: ArticleBase[] = [], centered = false) => {
      const colSingleClass: string = centered ? ' article-list__center' : '';

      const ListComponent = () => (
        <List>
          {articles.map((article: ArticleBase) => {
            const videoArticleId = article.video ? article.id : false;
            const isMeaArticle = focusedArticleId ? focusedArticleId === videoArticleId : false;
            const itemClick =
              dispatchToMea && article.video && article.video.source !== VideoSource.PFV ? onFocusItemClick(article) : onItemClick;
            switch (template) {
              case ArticleListTemplateConst.DETOUR:
                return (
                  <SliderItemDetour
                    key={article.id}
                    isCompact={isCompact}
                    onClick={itemClick}
                    article={article}
                    overlay={isMeaArticle}
                    abstractExposed={content.abstractExposed}
                    displayTags={displayTags}
                    setHeightImg={setHeightImg}
                    heightImg={heightImg}
                  />
                );
              case ArticleListTemplateConst.ADVANCED:
                return (
                  <CreativeMediaArticle
                    key={article.id}
                    alt={article.mainMedia.compact?.alt}
                    setModalContent={setModalContent}
                    setIsModalOpen={setIsModalOpen}
                    article={article}
                    overlay={isMeaArticle}
                    renderArticleContent={renderArticleContent}
                    isCompact={isCompact}
                  />
                );

              default:
                return (
                  <ArticlePreview
                    isCompact={isCompact}
                    onClick={itemClick}
                    article={article}
                    overlay={isMeaArticle}
                    key={article.id}
                    abstractExposed={content.abstractExposed}
                    disableLazyLoad={disableLazyLoad}
                    displayTags={displayTags}
                  />
                );
            }
          })}
        </List>
      );

      return (
        <div className={`article-list__${colPosition}-col${colSingleClass}`} key={colPosition}>
          {ListComponent()}
        </div>
      );
    },
    [
      content.abstractExposed,
      disableLazyLoad,
      dispatchToMea,
      displayTags,
      focusedArticleId,
      heightImg,
      isCompact,
      onFocusItemClick,
      onItemClick,
      renderArticleContent,
      setHeightImg,
      template,
    ],
  );

  const RenderTemplate = useMemo(() => {
    // Render TEMPLATE_HIGHLIGHT
    if (
      template !== ArticleListTemplateConst.DETOUR &&
      content.template === ArticleListTypes.TEMPLATE_HIGHLIGHT &&
      content.articles.length > 0
    ) {
      return (
        <ArticleListHighlight
          isCompact={isCompact}
          highlightCenterMode={highlightCenterMode}
          dispatchToMea={dispatchToMea}
          focusedArticleId={focusedArticleId}
          content={content}
          onItemClick={onItemClick}
          onFocusItemClick={onFocusItemClick}
          disableLazyLoad={disableLazyLoad}
          displayTags={displayTags}
          template={template}
          heightImg={heightImg}
          setHeightImg={setHeightImg}
          windowWidth={windowSize.width}
        />
      );
    }

    if (template === ArticleListTemplateConst.ADVANCED && content.template) {
      if (content.template === ArticleListTypes.TEMPLATE_4_COL && content.articles.length > 0) {
        return (
          <ArticleListBigFour
            articles={content.articles}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            renderArticleContent={renderArticleContent}
            isCompact={isCompact}
          />
        );
      }
      if (content.template === ArticleListTypes.TEMPLATE_3_COL && content.articles.length > 0) {
        return (
          <ArticleList3Columns
            articles={content.articles}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            renderArticleContent={renderArticleContent}
            isCompact={isCompact}
          />
        );
      }
    }

    // Render Carousel
    if (
      (device === 'mobile' && listsAsCarousel) ||
      ((content.template === ArticleListTypes.TEMPLATE_CAROUSEL ||
        (template === ArticleListTemplateConst.DETOUR && content.template === ArticleListTypes.PORTRAIT) ||
        (template === ArticleListTemplateConst.DETOUR && content.template === ArticleListTypes.TEMPLATE_HIGHLIGHT)) &&
        content.articles.length > 0)
    ) {
      return (
        <ArticleListCarousel
          device={device}
          isCompact={isCompact}
          id={id}
          content={content}
          highlightCenterMode={highlightCenterMode}
          onItemClick={onItemClick}
          className={className}
          isSearch={isSearch}
          listsAsCarousel={listsAsCarousel}
          disableLazyLoad={disableLazyLoad}
          dispatchToMea={dispatchToMea}
          focusedArticleId={focusedArticleId}
          onFocusItemClick={onFocusItemClick}
          displayTags={displayTags}
          heightImg={heightImg}
          setHeightImg={setHeightImg}
          windowSize={windowSize}
          template={template}
        />
      );
    }

    const columns: Array<Column> = formatColumns(content);

    return columns.map((column: Column) => {
      // Centered column class
      const centered =
        column.pos === ColumnsPos.CENTER && (content.template === ArticleListTypes.TEMPLATE_BIG_CENTER || content.articles.length < 3);

      return RenderColumn(column.pos, column.articles, centered);
    });
  }, [
    RenderColumn,
    className,
    content,
    device,
    disableLazyLoad,
    dispatchToMea,
    displayTags,
    focusedArticleId,
    heightImg,
    highlightCenterMode,
    id,
    isCompact,
    isSearch,
    listsAsCarousel,
    onFocusItemClick,
    onItemClick,
    renderArticleContent,
    setHeightImg,
    template,
    windowSize,
  ]);

  const { readMorePaginated, readMoreNotPaginated, readMoreLink } = getReadMoreLink(content);
  const readMoreLabel = content.readMoreLabel || "Plus d'articles";
  const carousselTemplateList = ['highlight', 'carousel'];
  
  return (
    <div
      ref={(el) => el}
      className={classNames(className, `article-list article-list--${articleListType}${searchIsOpen ? ' searchIsOpen' : ''}`)}
    >
      <Modal closeModal={closeModal} isModalOpen={isModalOpen}>
        {modalContent}
      </Modal>
      {content.title && <Title title={content.title} className="article-list__title" level={2} />}
      <div className={`article-list__grid article-list__grid--${articleListType}`}>{RenderTemplate}</div>
      {readMorePaginated && !carousselTemplateList.includes(content.template ?? '') && (
        <NextLinkContainer className="button button--rounded button--pagination" to={readMoreLink} blockId={id} isSearch={isSearch}>
          {readMoreLabel}
        </NextLinkContainer>
      )}
      {readMoreNotPaginated &&
        articleListType !== ArticleListTypes.TEMPLATE_CAROUSEL &&
        articleListType !== ArticleListTypes.TEMPLATE_HIGHLIGHT && (
          <CustomLink className="button button--rounded" to={readMoreLink}>
            {articleListType}
            {readMoreLabel}
          </CustomLink>
        )}
    </div>
  );
};

export default ArticleList;
