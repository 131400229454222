import { Link } from '../components/link';
import { ClickTrackingSettings } from '../components/tracking';

export type SocialNetWork = 'twitter' | 'facebook' | 'spotify' | 'youtube' | 'dailymotion' | 'instagram' | 'linkedin';

export type SocialNetworksMenu = { label: string; items: { [key in SocialNetWork]: { links: Link[] } } | {} };
export type SocialNetworksBar = { label: SocialNetWork; items: Link[] };
export type SocialNetworks = SocialNetworksMenu | SocialNetworksBar[];

export enum SocialShare {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  TUMBLR = 'tumblr',
  GOOGLE = 'google',
  LINKEDIN = 'linkedin',
  MAIL = 'mail',
}

export type SocialNetWorkSharing = {
  isActive: boolean;
  tracking?: ClickTrackingSettings;
};

export const FB_SCRIPT_SRC = '//connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v3.0';
export const TW_SCRIPT_SRC = '//platform.twitter.com/widgets.js';
