/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import debounce from 'lodash/debounce';

export type ResizeWindow = {
  width: number;
  height: number;
};

class ResizeService {
  debounceValue: number;
  resizeApp: any;
  onResizeDebounce: any;

  constructor(resizeApp: any) {
    this.debounceValue = 250;
    this.resizeApp = resizeApp;
    this.onResize = this.onResize.bind(this);

    this.onResizeDebounce = debounce(this.onResize, this.debounceValue);
  }

  attach = (): void => {
    if ((window as any).attachEvent) {
      // if IE (and Opera depending on user setting)
      (window as any).attachEvent('resize', this.onResizeDebounce);
    } else if (window.addEventListener) {
      // WC3 browsers
      window.addEventListener('resize', this.onResizeDebounce);
    }

    if (this.resizeApp) {
      this.resizeApp(this.getWindow(), true);
    }
  };

  dispose = (): void => {
    if ((window as any).detachEvent) {
      // if IE (and Opera depending on user setting)
      (window as any).detachEvent('resize', this.onResizeDebounce);
    } else if (window.removeEventListener) {
      // WC3 browsers
      window.removeEventListener('resize', this.onResizeDebounce);
    }
  };

  onResize = (): void => {
    if (this.resizeApp) {
      this.resizeApp(this.getWindow());
    }
  };

  getWindow = (): ResizeWindow => {
    const fallbackElement: HTMLElement = document.documentElement || document.body;
    let fallbackWidth = 0;
    let fallbackHeight = 0;

    if (fallbackElement) {
      fallbackWidth = fallbackElement.clientWidth;
      fallbackHeight = fallbackElement.clientHeight;
    }

    return {
      width: window.innerWidth || fallbackWidth,
      height: window.innerHeight || fallbackHeight,
    };
  };
}

export default ResizeService;
