import React from 'react';
import classNames from 'classnames';
import Text from '../Text';
import Title from '../Title';
import Picture from '../Picture';
import { ContentStaticBlock } from 'src/typings/components/content';
import { getArticleMedia } from 'src/shared/helpers/imageHelper';

const StaticBlock = ({ content, className }: { content: ContentStaticBlock; className?: string }) => (
  <div className={classNames(className, 'static-block')}>
    {content.title && content.title !== '' && <Title level={1} title={content.title} />}
    {content.content && content.content !== '' && <Text text={content.content} />}
    {content.media && <Picture images={[getArticleMedia(content.media).src]} />}
  </div>
);

export default StaticBlock;
