import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ReactFlagsSelect from 'react-flags-select';

export type LangSelectorProps = {
  defaultValue?: string;
  countriesAvailable: string[];
  customLabels?: { [key: string]: string };
};

const LangSelector = (props: LangSelectorProps) => {
  const { defaultValue, countriesAvailable, customLabels } = props;
  const [display, setDisplay] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // hack to render ReactFlagsSelect only in front side because it doesn't works in server side
    setDisplay(true);
  }, []);

  const onSelectFlag = (countryCode: string) => {
    window.location.href = location.pathname;
  };

  return (
    <div className="langSelector">
      {display && (
        <ReactFlagsSelect
          defaultCountry={defaultValue}
          customLabels={customLabels}
          countries={countriesAvailable}
          onSelect={onSelectFlag}
        />
      )}
    </div>
  );
};

export default LangSelector;
