import { ArticleListTypes } from './../../typings/constants/articleListTypes';
import { ArticleBase } from 'src/typings/components/articleBase';
import { Link } from '../../typings/components/link';
import { ContentArticleList } from 'src/typings/components/content';
import { getLinkHref, getLinkFromData } from './uriHelper';

export enum ColumnsPos {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'middle',
}

export type Column = {
  pos: ColumnsPos;
  articles: ArticleBase[];
};

const columnSlice =
  (data: ArticleBase[]) =>
  (featuredArticlesCount: number = data.length, mode?: ColumnsPos): Array<Column> => {
    const featuredArticles: ArticleBase[] = data.slice(0, featuredArticlesCount);
    const restArticles: ArticleBase[] = data.slice(featuredArticlesCount);

    if (mode === ColumnsPos.CENTER) {
      return [
        {
          pos: ColumnsPos.LEFT,
          articles: restArticles.filter((_article: ArticleBase, i) => i % 2 === 0),
        },
        {
          pos: ColumnsPos.CENTER,
          articles: featuredArticles,
        },
        {
          pos: ColumnsPos.RIGHT,
          articles: restArticles.filter((_article: ArticleBase, i) => i % 2 !== 0),
        },
      ];
    }
    if (mode === ColumnsPos.LEFT) {
      return [
        {
          pos: ColumnsPos.LEFT,
          articles: featuredArticles,
        },
        {
          pos: ColumnsPos.CENTER,
          articles: restArticles,
        },
      ];
    }
    if (mode === ColumnsPos.RIGHT) {
      return [
        {
          pos: ColumnsPos.CENTER,
          articles: restArticles,
        },
        {
          pos: ColumnsPos.RIGHT,
          articles: featuredArticles,
        },
      ];
    }

    return [
      {
        pos: ColumnsPos.CENTER,
        articles: data,
      },
    ];
  };

export function formatColumns(content: ContentArticleList): Array<Column> {
  const columnSliceWithData = columnSlice(content.articles);
  switch (content.template) {
    case ArticleListTypes.TEMPLATE_BIG_CENTER:
      return columnSliceWithData(1, ColumnsPos.CENTER);
    case ArticleListTypes.TEMPLATE_2_BIG_CENTER:
      return columnSliceWithData(2, ColumnsPos.CENTER);
    case ArticleListTypes.TEMPLATE_BIG_LEFT:
      return columnSliceWithData(1, ColumnsPos.LEFT);
    case ArticleListTypes.TEMPLATE_2_BIG_LEFT:
      return columnSliceWithData(2, ColumnsPos.LEFT);
    case ArticleListTypes.TEMPLATE_BIG_RIGHT:
      return columnSliceWithData(1, ColumnsPos.RIGHT);
    case ArticleListTypes.TEMPLATE_2_BIG_RIGHT:
      return columnSliceWithData(2, ColumnsPos.RIGHT);
    default:
      return columnSliceWithData();
  }
}

export function getReadMoreLink(data: ContentArticleList) {
  // Is there a pagination link ?
  const nextLink = data.links ? data.links.find((link: Link) => link.rel === 'next') : null;
  const readMoreLink = nextLink ? getLinkHref(nextLink) : getLinkFromData(data);
  return {
    readMorePaginated: data.isPaginable && !!nextLink,
    readMoreNotPaginated: !data.isPaginable && !!readMoreLink,
    readMoreLink,
  };
}
