import { JwtData } from 'src/typings/components/authentication';
import { setCookie } from './cookieHelper';
/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

export type UserData = {
  username: string;
  token: string;
};

/**
 * Decrypt the given JWT token
 *
 * @param {*} token
 */
const decryptToken = (token: string): JwtData | null => {
  const encodedData = token.split('.')[1];
  let data;

  try {
    // atob() is client side only function
    if (typeof atob !== 'undefined') {
      data = atob(encodedData);
    } else {
      // Server-side
      data = Buffer.from(encodedData, 'base64').toString('binary');
    }
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

// In case of update, you must also update the regex below;
export const cookieName = 'cm_token';
const cookieNameRegex = /(?:(?:^|.*;\s*)cm_token\s*=\s*([^;]*).*$)|^.*$/;

/**
 * Return user data extracted from the JWT token
 * If no token is passed as parameter, the one that may exists in cookie will be used.
 *
 * @param {*} token
 */
export const getJwtUserData = (token?: string): UserData | null => {
  let jwtToken: string;

  if (token) {
    jwtToken = token;
  } else {
    if (typeof document === 'undefined') {
      return null;
    }

    jwtToken = document.cookie.replace(cookieNameRegex, '$1');
  }

  const jwtData = decryptToken(jwtToken);

  if (!jwtData || !jwtData.username) {
    return null;
  }

  return {
    username: jwtData.username,
    token: jwtToken,
  };
};

export const deleteToken = () => {
  if (typeof document === 'undefined') {
    return;
  }

  setCookie(cookieName, '');
};
