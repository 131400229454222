import React from 'react';
import ContactElement from './ContactElement';
import { ContentContact } from 'src/typings/components/content';

const Contact = ({ content }: { content: ContentContact }) => (
  <div className="contact">
    <div className="contact__list">
      {content.map((element) => (
        <ContactElement element={element} key={`contact-element-${element.type}`} />
      ))}
    </div>
  </div>
);

export default Contact;
