import { PageMeta } from '../../typings/components/page';
/**
 * Return the meta getter function with the given parameter in metasList
 * @param {string} param -name of the parameter to get inside meta object
 * @returns {func}
 */
const getMetaWith = (param: string) => (metasList: PageMeta[], property: string) =>
  metasList && metasList.length ? metasList.find((meta) => meta[param] === property) : null;

/**
 * Return the meta with the property parameter in metasList
 * @param {array} metasList - meta list
 * @param {string} property - value to match
 * @returns {object | null | undefined} - the matched meta item or nothing
 */
export const getMetaWithProperty = (metasList: PageMeta[], property: string) => getMetaWith('property')(metasList, property);

/**
 * Return the meta with the name parameter in metasList
 * @param {array} metasList - meta list
 * @param {string} property - value to match
 * @returns {object | null | undefined} - the matched meta item or nothing
 */
export const getMetaWithName = (metasList: PageMeta[], property: string) => getMetaWith('name')(metasList, property);
