import '@canalplus/mycanal-headerfooter/dist/header.min.css';
import '@canalplus/mycanal-sharedcomponent/dist/headerlayout.min.css';
import '@canalplus/mycanal-sharedcomponent/dist/headernavigation.min.css';
import '@canalplus/mycanal-sharedcomponent/dist/headerprofile.min.css';
import '@canalplus/mycanal-sharedcomponent/dist/stickycontainer.min.css';
import '@canalplus/mycanal-strate/dist/main.min.css';
import { useState, useEffect } from 'react';
import { useHeaderTheme } from '@canalplus/mycanal-sharedcomponent/hooks';
import { useLocation } from 'react-router';
import HeaderLayout from '@canalplus/mycanal-sharedcomponent/HeaderLayout';
import HeaderNavigation from '@canalplus/mycanal-sharedcomponent/HeaderNavigation';
import React, { FunctionComponent } from 'react';
import StickyContainer from '@canalplus/mycanal-sharedcomponent/StickyContainer';
import { Block } from 'src/typings/components/block';
import { ContentOmnitureHeader } from 'src/typings/components/content';
import { isActive, createNavigationItems, getActiveItemIndex } from './HeaderV5Services';
import { MenuItem } from 'src/typings/components/navigation';
import HomeLink from './HomeLink';
import { canUseDOM } from 'src/shared/helpers/windowHelper';
import { State } from '../../../../typings/state/state';
import SwitchLanguageContainer  from '../SwitchLanguage/SwitchLanguageContainer';
import { connect } from 'react-redux';
import { getLangFromUrl } from 'src/shared/helpers/uriHelper';

export type Props = {
  homeLink: string;
  userIsAuthenticated: boolean;
  transparent: boolean;
  navigation: true;
  galaxy: boolean;
  onNavigation: null;
  onRight: null;
  sealed: boolean;
  displayLogo: '';
  userStatus: '';
  microEligibilities: '';
  hideUserInfo: boolean;
  galaxyMenu: [];
  locale: string | string[];
  menu?: MenuItem[];
  lang?: string;
  langList: string[] | undefined;
  switchLanguageIsActive: boolean;
  path: string;
  trackingBlocks: Block<ContentOmnitureHeader>[];
  renderBlock: (block: Block) => JSX.Element;
};

const mapStateToProps = (state: State) => ({
  urlTranslations: state.page.data.links[0].translations,
  langList: state.page.data.site.languages,
  switchLanguageIsActive: state.page.data.site.translatable,

});

const HeaderV5: FunctionComponent<Props> = ({ switchLanguageIsActive, langList, path, locale, menu, sealed = false, trackingBlocks, renderBlock, lang }) => {
  const getLanguageFromUrl = (path: string) => {
    let updatedLang: string = getLangFromUrl(path, langList);
    return updatedLang;
  }

  const location = useLocation();
  const language = getLanguageFromUrl(location.pathname);
  
  if (menu) {
    const activeMenuIndex = getActiveItemIndex(menu, path, langList);
    const theme = useHeaderTheme(true, true, false);
    let hiddenDiv: HTMLElement | null = null;
    const [logo, setLogo] = useState('canalGroup');
    
    if (canUseDOM()) {
      const menuItem = document.querySelectorAll<HTMLElement>('a[id^="expandableMenuItem_"]');
      hiddenDiv = document.getElementById('hidden-div');
      menuItem.forEach(element => element.onclick = () => hiddenDiv?.focus());
    }

    useEffect(() => {
      lang === "EN" ? setLogo('canalgroupEn') : setLogo('canalGroup');
    });

    return (
      <div className="headerAdvanced">
        <StickyContainer>
          <HeaderLayout
            theme={theme}
            logo={
              <HomeLink hiddenDiv={hiddenDiv} brand={sealed ? '' : logo} locale={locale} link={sealed ? '' : `/${lang?.toLowerCase()}`} isActive={isActive('/', location.pathname, true)} />
            }
            navigation={
              <HeaderNavigation theme={theme} activeIndex={activeMenuIndex} isDropdownEnabled={false}>
                {createNavigationItems(menu, locale, langList)}
              </HeaderNavigation>
            }
            userOptions={[]}
          />
          { switchLanguageIsActive && (
              <SwitchLanguageContainer path={path} pathLocation={location} lang={language} activeIndex={activeMenuIndex} menu={menu}/>
          )}   
        </StickyContainer>
        {trackingBlocks.map(renderBlock)}
      </div>
    );
  }
  return null;
};

export default connect(mapStateToProps)(HeaderV5);
