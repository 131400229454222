import React from 'react';
import classNames from 'classnames';

/**
 * Return correct media query syntax for required image index
 * @param {array} medias TMedia list
 * @param {number} index Requested media index
 * @returns {string}
 */

type Props = {
  images: string[];
  breakpoints?: string[];
  alt?: string;
  className?: string;
};

const getMediaForIndex = (index: number, breakpoints?: string[]) => {
  if (!breakpoints) {
    return '';
  }
  switch (index) {
    case 0:
      return `(max-width: ${breakpoints[index]})`;
    case breakpoints.length:
      return `(min-width: ${breakpoints[index - 1]})`;
    default:
      return `(min-width: ${breakpoints[index - 1]}) and (max-width: ${breakpoints[index]})`;
  }
};

class Picture extends React.PureComponent<Props> {
  static defaultProps = {
    images: [],
    alt: '',
  };

  render() {
    const { images, breakpoints, alt, className } = this.props;

    return breakpoints && images.length > 1 ? (
      <picture className={classNames(className, 'picture')}>
        {images.map((img, index) => (
          <source srcSet={img} media={getMediaForIndex(index, breakpoints)} key={`${img}-${index}`} />
        ))}
        <img src={images[0]} alt={alt} />
      </picture>
    ) : (
      <img src={images[0]} alt={alt} className={classNames(className, 'picture')} />
    );
  }
}

export default Picture;
