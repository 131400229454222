import classnames from 'classnames';
import React, { useState, useCallback, ReactNode } from 'react';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import CreativeMediaArticle from '../../CreativeMediaArticle/CreativeMediaArticle';
import HorizontalScrollWithArrows from '../../HorizontalScrollWithArrows/HorizontalScrollWithArrows';
import { ArticleBase } from 'src/typings/components/articleBase';
import  Modal  from '../../Modal/Modal';
import { Office } from 'src/typings/components/office';

function isArticleBase(arg: any): arg is ArticleBase {
  return 'abstract' in arg;
}
type Item = ArticleBase | Office;

type Props = {
  list: Item[];
  ratio?: number;
  title?: string;
  button?: object;
  renderArticleContent?: (path: string | null) => Promise<ReactNode[] | null>;
  isCompact: boolean;
  type?: PageBlockType;
};

const SliderAdvanced = ({ title, list = [], ratio = 169, button, renderArticleContent, isCompact, type }: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode[] | null>(null);
  const closeModal = useCallback(() => {
    window.history.replaceState(null, '', '/');

    setModalContent(null);
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <Modal closeModal={closeModal} isModalOpen={isModalOpen}>
        {modalContent}
      </Modal>
      <div className="sliderAdvanced">
        {title && (
          <div className="Advanced__header">
            <h4 className="slider__title">{title}</h4>
          </div>
        )}
        <div className={classnames('slider__horizontal-scroll', { 'with-header': title || button })}>
          <HorizontalScrollWithArrows
            items={
              list &&
              list.map((content, index) => (
                <CreativeMediaArticle
                  alt={isArticleBase(content) ? content.mainMedia.compact?.alt : content.mainMedia.alt}
                  key={index}
                  article={content}
                  ratio={ratio}
                  setModalContent={setModalContent}
                  setIsModalOpen={setIsModalOpen}
                  renderArticleContent={renderArticleContent}
                  isCompact={isCompact}
                  index={index}
                  itemsCount={list.length}
                  type={type}
                />
              ))
            }
            scrollFullRow
            arrowPosition="30%"
          />
        </div>
      </div>
    </>
  );
};

export default SliderAdvanced;
