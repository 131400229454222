import React from 'react';
import List from '../List';
import CustomLink from '../../container/CustomLink';
import Icon from '../Icon';
import { SocialNetworksMenu } from 'src/typings/constants/socialNetworks';
import ConfigHelper from 'configHelper';

type Props = {
  content: SocialNetworksMenu[];
};

const siteName = ConfigHelper.getPublicConfig().identity.split(".")[0]

class SocialHeaderMenu extends React.PureComponent<Props> {
  render() {
    const { content } = this.props;
    const socialList = content ? content.map((item) => ({ label: item.label, link: item?.items ? item?.items[0].href : '' })) : null;

    return (
      <List className="socialbar socialbar--header">
        {socialList &&
          socialList.map((item) => (
            <CustomLink ariaLabel={`${siteName} sur ${item.label}`} className="button-share" key={`social-header-item-${item.label}`} to={item.link} target="_blank" >
              <Icon id={item.label} />
              <p className="button-share--label" role="presentation">
                {item.label}
              </p>
            </CustomLink>
          ))}
      </List>
    );
  }
}

export default SocialHeaderMenu;
