import HeaderContainer from '../../shared/components/container/Header';
import ContentContainer from '../../shared/components/container/Content';
import FooterContainer from '../../shared/components/container/Footer';
import { ContainerType } from './containerTypes';

export const containerComp: { [key in ContainerType]: React.ReactType } = {
  [ContainerType.HEADER]: HeaderContainer,
  [ContainerType.CONTENT]: ContentContainer,
  [ContainerType.FOOTER]: FooterContainer,
};
