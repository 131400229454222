/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import { ArticleBase } from '../../../../typings/components/articleBase';
import { getClientHeight } from '../../../helpers/htmlHelper';
import { getMainMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import CustomLink from '../../container/CustomLink';
import LazyLoader from '../LazyLoader';
import Tags from '../Tags';
import TruncateText from '../TruncateText';
import TruncateTitle from '../TruncateTitle';

/**
 * SliderItem component
 * @param onClick
 * @param overlay
 * @param abstractExposed
 * @param displayTags
 * @param heightImg
 * @param setHeightImg
 * @constructor
 */

type Props = {
  setHeightImg: (height: number) => void;
  heightImg: number;
  displayTags: boolean;
  abstractExposed: boolean;
  overlay: boolean;
  onClick?: (e: any) => void;
  article: ArticleBase;
  isCompact: boolean;
};

class SliderItem extends React.Component<Props> {
  imgElement: any;

  componentDidMount() {
    window.addEventListener('resize', debounce(this.onResize, 50), false);
    window.addEventListener('orientationchange', this.orientationChange, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.orientationChange);
  }

  // onResize get height images slider to position arrows
  onResize = () => {
    const heightImg = getClientHeight(this.imgElement);
    if (heightImg > 0 && heightImg !== this.props.heightImg) {
      this.props.setHeightImg(heightImg);
    }
  };

  orientationChange = () => {
    setTimeout(() => {
      const heightImg = getClientHeight(this.imgElement);
      if (heightImg > 0 && heightImg !== this.props.heightImg) {
        this.props.setHeightImg(heightImg);
      }
    }, 250);
  };
  bindImg = (element: React.ReactNode) => {
    this.imgElement = element;
    // if images are uploaded get height images slider to position arrows
    setTimeout(() => {
      const heightImg = getClientHeight(this.imgElement);
      if (heightImg > 0 && heightImg !== this.props.heightImg) {
        this.props.setHeightImg(heightImg);
      }
    }, 2000);
  };

  render() {
    const { abstractExposed, article, displayTags, onClick, overlay, isCompact } = this.props;
    const imgInfo = getMainMedia(article.mainMedia, isCompact);
    const title = get(article, 'title', '');
    const abstract = get(article, 'abstract', '');
    const tags = get(article, 'tags', []);
    const articleLink = getLinkFromData(article);

    return (
      <CustomLink className={classNames('Slider__Item', { overlay })} to={articleLink} onClick={onClick}>
        <>
          <div className="Slider__Item__image">
            <LazyLoader offset={100} height={230}>
              <img src={imgInfo.src} alt={imgInfo.description} ref={this.bindImg} />
            </LazyLoader>
          </div>
          <TruncateTitle className="Slider__Item__Title" level={3} title={title} />
          {abstractExposed && <TruncateText className="Slider__Item__Abstract" text={abstract} />}
          {displayTags && tags && tags.length && <Tags content={{ tags }} />}
        </>
      </CustomLink>
    );
  }
}

export default SliderItem;
