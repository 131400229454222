import { connect } from 'react-redux';
import Article from '../../presentational/Article';
import { getMetaWithProperty } from '../../../helpers/pageHelper';
import { State } from '../../../../typings/state/state';

/**
 * Article container component
 * @class
 */

const mapStateToProps = (state: State) => ({
  metaOgTitle: !!getMetaWithProperty(state.page.data.meta, 'og:title'),
  metaOgDescription: !!getMetaWithProperty(state.page.data.meta, 'og:description'),
  metaOgType: !!getMetaWithProperty(state.page.data.meta, 'og:type'),
});

const ArticleContainer = connect(mapStateToProps)(Article);

export default ArticleContainer;
