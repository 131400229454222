import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import { Block } from 'src/typings/components/block';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import SearchItemContainer from '../../container/Header/SearchItemContainer';
import { ContentSocialNetworksMenu } from 'src/typings/components/content';
import ScrollService from 'src/shared/service/ScrollService';
import { HEADER_SCROLL_VALUE } from 'src/shared/constants/scroll';
import { HeaderProps, HeaderState } from 'src/typings/components/header';
import { SocialNetworksMenu } from 'src/typings/constants/socialNetworks';

class HeaderBurger extends React.Component<HeaderProps, HeaderState> {
  logoRef: HTMLElement | null;
  menuRef: HTMLElement | null;
  scrollService: ScrollService;

  constructor(props: HeaderProps) {
    super(props);

    this.logoRef = null;
    this.menuRef = null;

    this.state = {
      mobileNavIsOpened: false,
      scrollY: 0,
    };

    this.scrollService = new ScrollService();
  }
  componentDidMount() {
    document.body.addEventListener('mousedown', this.onClickPage);
    this.scrollService.attach();
    this.scrollService.addEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.onClickPage);
    this.scrollService.removeEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
    this.scrollService.dispose();
  }

  onScrollChange = (scrollTop: number) => {
    this.setState({ scrollY: scrollTop });
  };

  onClickPage = (evt) => {
    const elem = document.getElementsByClassName('headerBurger__menu')[0];
    const rect: ClientRect = elem.getBoundingClientRect();
    if (evt.clientX > rect.left + rect.width || evt.clientY > rect.top + rect.height || evt.clientY < rect.top) {
      this.props.closeNavHeader && this.props.closeNavHeader();
    }
  };

  componentDidUpdate(prevProps: HeaderProps) {
    if (prevProps.mobileNavIsOpened !== this.props.mobileNavIsOpened) {
      this.setState({ mobileNavIsOpened: this.props.mobileNavIsOpened }, () => {
        this.translateLogo(this.props);
      });
    }
  }

  translateLogo = (nextProps: HeaderProps) => {
    if (this.logoRef && this.menuRef) {
      const boundsMenu: ClientRect = this.menuRef.getBoundingClientRect();
      const boundsLogo: ClientRect = this.logoRef.getBoundingClientRect();
      const valueTranslate: number = boundsMenu.width > boundsLogo.left ? boundsMenu.width - boundsLogo.left + 20 : 0;

      if (nextProps.mobileNavIsOpened) {
        this.logoRef.style.transform = 'translate3d(' + valueTranslate + 'px, 0, 0)';
      } else {
        this.logoRef.style.transform = 'translate3d(0, 0, 0)';
      }
    }
  };

  onToggleMobileNav = () => {
    if (this.state.mobileNavIsOpened) {
      this.props.closeNavHeader && this.props.closeNavHeader();
    } else if (this.props.openNavHeader) {
      this.props.openNavHeader();
    }
  };

  render() {
    if (!this.props.elements) {
      return null;
    }

    const { renderBlock } = this.props;

    const isFixed = this.state.scrollY > HEADER_SCROLL_VALUE;

    const inMenuBlocksTypes = [PageBlockType.BLOCK_TYPE_NAVIGATION, PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS];
    const elementsGroup: { [key: string]: Array<Block> } = this.props.elements.reduce(
      (group: { [key: string]: Array<Block> }, el: Block) => {
        const groupName = inMenuBlocksTypes.includes(el.type) ? 'inMenuElements' : 'normalElements';
        return {
          ...group,
          [groupName]: [...group[groupName], el],
        };
      },
      {
        normalElements: [],
        inMenuElements: [],
      },
    );

    const normalElements: Array<Block> = elementsGroup.normalElements.filter(
      (block) =>
        block.type !== PageBlockType.BLOCK_TYPE_LOGO &&
        block.type !== PageBlockType.BLOCK_TYPE_SEARCH &&
        block.type !== PageBlockType.BLOCK_TYPE_LANG, // block lang is displayed in GalacticHeader
    );
    const logoElement: Block | undefined = elementsGroup.normalElements.find((block) => block.type === PageBlockType.BLOCK_TYPE_LOGO);
    const searchElement: Block | undefined = elementsGroup.normalElements.find((block) => block.type === PageBlockType.BLOCK_TYPE_SEARCH);
    const inMenuElements: Array<Block> = elementsGroup.inMenuElements.filter(
      (block) => block.type !== PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS,
    );
    const socialElement: Block | undefined = elementsGroup.inMenuElements.find(
      (block) => block.type === PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS,
    );

    const titleSocial: string | undefined = socialElement ? (socialElement.content as SocialNetworksMenu)?.label : undefined;
    const burgerMenu = "le burger menu";

    return (
      <header
        className={classNames('headerBurger', {
          'headerBurger--opened': this.state.mobileNavIsOpened,
          'headerBurger--galactic': this.props.isGalactic,
        })}
      >
        {normalElements.length && normalElements.map(renderBlock)}

        <div
          className={classNames('headerBurger__ctBurger', {
            headerBurger__ctBurger_fixed: isFixed,
          })}
        >
          <Button
            className="headerBurger__burger-button"
            icon={this.state.mobileNavIsOpened ? 'cross' : 'burger'}
            action={this.onToggleMobileNav}
            ariaExpanded={this.state.mobileNavIsOpened}
            ariaControls="headerBurger_menu"
            ariaLabel= {`${this.state.mobileNavIsOpened ? "Quitter" : "Ouvrir"} ${burgerMenu}`} 
          />
          <nav
            id="headerBurger_menu"
            className="headerBurger__menu"
            role="navigation"
            ref={(el) => {
              this.menuRef = el;
            }}
          >
            <div className="headerBurger__ctPadding">
              {searchElement && <SearchItemContainer />}
              {inMenuElements.length && inMenuElements.map(renderBlock)}
            </div>
            {socialElement && Object.entries(socialElement.content as ContentSocialNetworksMenu).length > 0 ? (
              <div className="headerBurger__socialCt">
                {titleSocial ? <p className="headerBurger__titleSocial">{titleSocial}</p> : null}
                <div>{renderBlock(socialElement)}</div>
              </div>
            ) : null}
          </nav>
          {logoElement ? (
            <div className="headerBurger__page">
              <div className="headerBurger__content">
                <div
                  className="headerBurger__logoCt"
                  id="logoAnim"
                  ref={(el) => {
                    this.logoRef = el;
                  }}
                >
                  {renderBlock(logoElement)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {searchElement ? renderBlock(searchElement) : null}
      </header>
    );
  }
}

export default HeaderBurger;
