import breakpoints from '../constants/breakpoints';
import { Size, ImageSizes, ImageSizeMap } from '../../typings/constants/ImageSizes';
import { getLinkFromData } from './uriHelper';
import { MainMedia } from '../../typings/components/mainMedia';
import { Media, MediaDetail } from '../../typings/components/media';

export const getImageWithSize = (size: Size, pattern: string) =>
  pattern && pattern.replace('{width}', size.width || '').replace('{height}', size.height || '');

export const getImageWith = (name: ImageSizes) => (pattern: string) => getImageWithSize(ImageSizeMap[name], pattern);

export const getImageWithJSONSize = getImageWith(ImageSizes.JSON_LOGO_IMG);

export const getResponsiveImages = (pattern: string) =>
  Object.entries(breakpoints)
    .map(([_key, value]) => parseInt(value, 10)) /* eslint-disable-line */
    .sort((a, b) => a - b)
    .map((val) => getImageWithSize({ width: val.toString() }, pattern));

export const getMedia =
  (imageSize: ImageSizes = ImageSizes.ARTICLE_IMG) =>
  (media: Media): MediaDetail => {
    const uri = getLinkFromData(media);
    const description = media.description;
    return {
      src: getImageWith(imageSize)(uri),
      description,
    };
  };

export const getArticleMedia = getMedia(ImageSizes.ARTICLE_IMG);
export const getHeaderMedia = getMedia(ImageSizes.HEADER_LOGO_IMG);
export const getHeaderMediaBurger = getMedia(ImageSizes.HEADER_LOGO_IMG_BURGER);

export const getContactMedia = getMedia(ImageSizes.CONTACT_ELEMENT_IMG);

export const getMEALogoMedia = getMedia(ImageSizes.MEA_LOGO_IMG);
export const getBrandLogoMedia = getMedia(ImageSizes.BRAND_IMG);
export const getMEABgMedia = getMedia(ImageSizes.MEA_BACKGROUND_IMG);

export const getFooterLogoMedia = getMedia(ImageSizes.FOOTER_LOGO_IMG);

export const getMainMedia = (mainMedia: MainMedia | Media, isCompact: boolean): MediaDetail => {
  let media: Media;
  if ((mainMedia as MainMedia)?.default) {
    const defaultMedia = (mainMedia as MainMedia).default;
    const compactMedia = (mainMedia as MainMedia).compact;
    media = isCompact && compactMedia ? compactMedia : defaultMedia;
  } else {
    media = mainMedia as Media;
  }

  return getArticleMedia(media);
};
