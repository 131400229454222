import React, { Component } from 'react';
import classNames from 'classnames';

import List from '../List';
import Icon from '../Icon';
import { trackClick } from '../../../helpers/trackingHelper';
import { getSocialLink } from '../../../helpers/socialHelper';
import { ClickTrackingSettings } from '../../../../typings/components/tracking';
import ScrollService from '../../../service/ScrollService';
import { WindowDimension } from '../../../../typings/state/pageState';
import { FragmentSocialNetwork } from 'src/typings/components/fragment';

type Props = {
  pageURI: string;
  pageName: string;
  tracker?: string;
  content: FragmentSocialNetwork;
  className?: string;
  openPopup: (url: string, id: string, window: WindowDimension, w: number, h: number) => void;
  window: WindowDimension;
  isTemplateSocialBarVertical: boolean;
};

type State = {
  isVisible: boolean;
  isDisplay: boolean;
};

/**
 * SocialBar component
 */
class SocialBar extends Component<Props, State> {
  reference?: any;
  scrollService: ScrollService;
  rectangle: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isVisible: true,
      isDisplay: false,
    };

    this.scrollService = new ScrollService();
  }

  componentDidMount() {
    const visibleSocialList = Object.entries(this.props.content.shareList).filter(
      ([_network, sharingSetting]) => sharingSetting && sharingSetting.isActive === true,
    );

    this.scrollService.attach();
    this.scrollService.addEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);

    visibleSocialList.map(([network]) => {
      const buttonShareAll = document.getElementById('button-share-all');
      const buttonShareNetwork = document.getElementById(`button-share-${network}`);
      const { isDisplay } = this.state;

      if (buttonShareAll != null && buttonShareNetwork != null) {
        buttonShareAll.addEventListener('focus', () => {
          this.setState({ isDisplay: !isDisplay });
        });
        buttonShareNetwork.addEventListener('focusout', () => {
          this.setState({ isDisplay: !isDisplay });
        });
        buttonShareNetwork.addEventListener('focus', () => {
          this.setState({ isDisplay: !isDisplay });
        });
        buttonShareNetwork.addEventListener('focusout', () => {
          this.setState({ isDisplay: !isDisplay });
        });
      }
    });
  }

  componentWillUnmount() {
    this.scrollService.removeEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
    this.scrollService.dispose();
  }

  onScrollChange = () => {
    this.updateVisibility();
  };

  onClickShare = (event: any, urlShare: string, clickTracking?: ClickTrackingSettings) => {
    if (urlShare.indexOf('mailto') !== -1) {
      return window.location.assign(urlShare);
    }

    const { tracker } = this.props;

    event.preventDefault();

    // Executing network onClick instructions provided by the model
    if (clickTracking) {
      trackClick(event, clickTracking, tracker);
    }

    return this.props.openPopup(urlShare, 'fbShareWindow', this.props.window, 530, 450);
  };

  /**
   * Update this component visibility status according to the given props
   *
   * @param nextProps
   */
  updateVisibility() {
    const { isVisible } = this.state;
    if (!this.reference) {
      return;
    }
    if (isVisible) {
      this.rectangle = this.reference.getBoundingClientRect();
    }

    let shouldBeVisible = true;

    const footer = document.getElementsByTagName('footer')[0];

    let boudingRect;
    if (footer) {
      boudingRect = footer.getBoundingClientRect();
      if (this.rectangle.top + this.rectangle.height + 50 > boudingRect.top) {
        shouldBeVisible = false;
      }
    }
    if (isVisible !== shouldBeVisible) {
      this.setState({ isVisible: shouldBeVisible });
    }
  }

  getSocialIconList(visibleSocialList) {
    const { pageURI, pageName } = this.props;

    return visibleSocialList.map(([network, sharingSetting]) => {
      const urlShare = getSocialLink(network, pageURI, pageName);
      const onClick = (e: React.SyntheticEvent) => (sharingSetting ? this.onClickShare(e, urlShare, sharingSetting.tracking) : null);

      return (
        <button
          id={`button-share-${network}`}
          onClick={onClick}
          key={`social-link-${urlShare}`}
          className={`button-share button-share--${network}`}
        >
          <Icon id={network} />
        </button>
      );
    });
  }

  getSocialBarVertical(visibleSocialList) {
    const { isVisible, isDisplay } = this.state;
    const { className } = this.props;

    const buttonClassName = classNames(className, 'socialbar socialbar--content', { 'socialbar--is-hidden': !isVisible });

    return (
      <button id="button-share-all" aria-expanded={isDisplay} className={buttonClassName}>
        <List
          refList={(el: HTMLElement | null) => {
            this.reference = el;
          }}
          className="socialbar socialbar--list"
        >
          <div className="button-share button-share--share">
            <Icon id="share" />
          </div>
          {this.getSocialIconList(visibleSocialList)}
        </List>
      </button>
    );
  }

  getSocialBarHorizontal = (visibleSocialList) => (
    <div className="socialbar__wrapper">
      <div className="socialbar__horizontal-link">{this.getSocialIconList(visibleSocialList)}</div>
    </div>
  );

  render() {
    const { content, isTemplateSocialBarVertical } = this.props;
    const visibleSocialList = Object.entries(content.shareList).filter(
      ([_network, sharingSetting]) => sharingSetting && sharingSetting.isActive === true,
    );

    if (!visibleSocialList.length) {
      return null;
    }

    return isTemplateSocialBarVertical ? this.getSocialBarVertical(visibleSocialList) : this.getSocialBarHorizontal(visibleSocialList);
  }
}

export default SocialBar;
