enum TemplateFooterConst {
  DEFAULT = 'default',
  V5 = 'V5',
}

export enum AdvancedFooterRightColumnDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

export default TemplateFooterConst;
