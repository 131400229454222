/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { getAbsoluteLink } from '../../../helpers/socialHelper';
import { getImageWithJSONSize } from '../../../helpers/imageHelper';
import { ContentJSONLD } from 'src/typings/components/content';
import { removeScriptHtml } from 'src/shared/helpers/htmlHelper';

type Props = {
  pageURI: string;
  content: ContentJSONLD;
};

/**
 * JsonLd component for displaying json-ld data
 * @constructor
 */
class JsonLd extends React.Component<Props> {
  getJsonLdData() {
    const { content, pageURI } = this.props;
    const { data } = content;

    if (!data) {
      return data;
    }

    const jsonLdData = cloneDeep(data);

    // First level url is replaced by current page url
    if (jsonLdData && (jsonLdData.url || jsonLdData.url === null)) {
      jsonLdData.url = getAbsoluteLink(pageURI);
    }

    // mainEntity path is prefixed by site domain
    if (jsonLdData && jsonLdData.mainEntity && jsonLdData.mainEntity.url) {
      jsonLdData.mainEntity.url = getAbsoluteLink(jsonLdData.mainEntity.url);
    }

    // searchPath path is prefixed by site domain
    if (jsonLdData && jsonLdData.potentialAction && jsonLdData.potentialAction.target && jsonLdData.potentialAction.target.urlTemplate) {
      jsonLdData.potentialAction.target.urlTemplate = getAbsoluteLink(jsonLdData.potentialAction.target.urlTemplate);
    }

    if (jsonLdData && jsonLdData.mainEntity && jsonLdData.mainEntity.logo) {
      jsonLdData.mainEntity.logo = getImageWithJSONSize(jsonLdData.mainEntity.logo);
    }

    return jsonLdData;
  }

  render() {
    const jsonLdData = this.getJsonLdData();

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: removeScriptHtml(JSON.stringify(jsonLdData)) }} />; // eslint-disable-line react/no-danger
  }
}

export default JsonLd;
