/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';

const FacebookEmbed = ({ apiId }: { apiId: string }) => (
  <div className="facebook">
    <div
      className="fb-page"
      data-href={`www.facebook.com/${apiId}/`}
      data-tabs="timeline"
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote className="fb-xfbml-parse-ignore" cite={`www.facebook.com/${apiId}/`}>
        <a href={`www.facebook.com/${apiId}/`}>{`${apiId}`}</a>
      </blockquote>
    </div>
  </div>
);

export default FacebookEmbed;
