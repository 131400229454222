/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import ConfigHelper from 'configHelper';

const minLogLevel = ConfigHelper.getClientConfig(`log.client.level`);
// const logFormat = ConfigHelper.getClientConfig(`log.client.format`);
const useLogs = ConfigHelper.getClientConfig(`log.client.active`);

/**
 * Return if the given log level should be logged
 *
 * @param {string} level
 * @returns {boolean}
 */
const relevant = (level) => {
  if (minLogLevel === 'error' && level === 'error') {
    return true;
  }
  if (minLogLevel === 'warn' && (level === 'error' || level === 'warn')) {
    return true;
  }
  if (minLogLevel === 'info' && (level === 'error' || level === 'warn' || level === 'info')) {
    return true;
  }
  if (minLogLevel === 'debug') {
    return true;
  }

  return false;
};

const getTimestamp = () => new Date().toISOString();

/**
 * Temporary front logger until front log solution is chosen
 */
const frontLogger = {
  /**
   * Log the given message as an error
   *
   * @param {string} message
   * @param {object} metadata
   */
  error(message, metadata = {}) {
    if (!relevant('error')) {
      return;
    }

    /* eslint-disable */
    // Console.log is exceptionnaly allowed here
    console.error(`${getTimestamp()} ERROR ${message} ${JSON.stringify(metadata)}`);
    /* eslint-disable */
  },

  /**
   * Log the given message as a warning
   *
   * @param {string} message
   * @param {object} metadata
   */
  warn(message, metadata = {}) {
    if (!relevant('warn')) {
      return;
    }

    /* eslint-disable */
    // Console.log is exceptionnaly allowed here
    console.warn(`${getTimestamp()} WARN ${message} ${JSON.stringify(metadata)}`);
    /* eslint-disable */
  },

  /**
   * Log the given message as an info
   *
   * @param {string} message
   * @param {object} metadata
   */
  info(message, metadata = {}) {
    if (!relevant('info')) {
      return;
    }

    /* eslint-disable */
    // Console.log is exceptionnaly allowed here
    console.info(`${getTimestamp()} INFO ${message} ${JSON.stringify(metadata)}`);
    /* eslint-enable */
  },

  /**
   * Log the given message as a debug message
   *
   * @param {string} message
   * @param {object} metadata
   */
  debug(message, metadata = {}) {
    if (!relevant('debug')) {
      return;
    }

    /* eslint-disable */
    // Console.log is exceptionally allowed here
    console.log(`${getTimestamp()} DEBUG ${message} ${JSON.stringify(metadata)}`);
    /* eslint-enable */
  },
};

/**
 * Logger printing nothing, used when logging is deactivated
 */
const emptyLogger = {
  debug: () => null,
  info: () => null,
  warn: () => null,
  error: () => null,
};

/**
 * Return a logger instance depending on the application context
 *
 * @returns {*}
 */
const getLogger = () => {
  let logger;
  if (useLogs === true) {
    // This is the SPA, using a simple logger
    logger = frontLogger;
  } else {
    logger = emptyLogger;
  }

  /**
   * Generate a loggable metadata object based on a provided data object
   *
   * This method is building a meta data object by placing some relevant log data at
   * places expected by the explotation team and reported on log dashboards
   *
   *
   * @param {object} data An object whose properties will be added as log metadata
   * @return {object}
   */
  logger.generateMetadata = (data) => {
    const metadata: any = {};

    // Request id is moved to req.uniqueId
    if (data.uniqueId) {
      Object.assign(metadata, {
        req: {
          uniqueId: data.uniqueId,
        },
      });

      delete data.uniqueId; // eslint-disable-line no-param-reassign
    }

    // Status code is moved to res.statusCode
    if (data.statusCode) {
      Object.assign(metadata, {
        res: {
          statusCode: data.statusCode,
        },
      });

      delete data.statusCode; // eslint-disable-line no-param-reassign
    }

    // responseTime is calculated using startAt
    if (data.startAt) {
      metadata.responseTime = new Date().getTime() - data.startAt;

      delete data.startAt; // eslint-disable-line no-param-reassign
    }

    // Other data properties are merged as if
    return Object.assign(metadata, data);
  };

  /**
   * Generate a loggable metadata object in an API call context
   *
   * @param {string} apiName     The API name (tms, pass, ...)
   * @param {string} serviceName The service used the API (createToken, wsFromPath, ...)
   * @param {object} data
   * @returns {Object}
   */
  logger.generateApiMetadata = (apiName, serviceName, data) =>
    logger.generateMetadata(
      Object.assign(data, {
        service: apiName,
        webservice: serviceName,
      }),
    );

  return logger;
};

export default getLogger();
