import React, { FunctionComponent } from 'react';

type Props = {
  id: string;
  scale?: number;
  rotate?: number;
};

const Icon: FunctionComponent<Props> = ({ id, scale = 1, rotate = 0 }) => (
  <i className={`icon ai-${id}`} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} />
);

export default Icon;
