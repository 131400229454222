import { PageBlockType } from './pageBlockTypes';
import ArticleBanner from '../../shared/components/container/ArticleBanner';
import ArticleContainer from '../../shared/components/container/Article';
import ArticleList from '../../shared/components/container/ArticleList';
import BannerHeader from 'src/shared/components/presentational/BannerHeader';
import CategoryListContainer from 'src/shared/components/container/CategoryList';
import CommentDisqus from '../../shared/components/presentational/CommentDisqus/CommentDisqus';
import Contact from '../../shared/components/presentational/Contact';
import CookieConsentContainer from '../../shared/components/container/CookieConsent';
import Download from '../../shared/components/presentational/Download';
import FooterBlock from '../../shared/components/presentational/FooterBlock';
import FooterV5 from 'src/shared/components/presentational/FooterV5';
import GalacticHeader from '../../shared/components/container/GalacticHeader';
import IFrame from '../../shared/components/presentational/IFrame';
import ImageListContainer from 'src/shared/components/container/ImageList';
import JsonLd from '../../shared/components/container/JsonLd';
import LangSelectorContainer from 'src/shared/components/container/LangSelector';
import LinkBlock from '../../shared/components/presentational/LinkBlock';
import LoginContainer from '../../shared/components/container/Login';
import Logo from '../../shared/components/container/Logo';
import MeaCarouselContainer from '../../shared/components/container/MeaCarousel';
import MeaContainer from '../../shared/components/container/Mea';
import MeaVideoContainer from '../../shared/components/container/MeaVideo';
import Navigation from '../../shared/components/container/Navigation';
import OfficeListContainer from 'src/shared/components/container/OfficeList';
import OmnitureHeader from '../../shared/components/presentational/OmnitureHeader';
import RawBlock from '../../shared/components/presentational/Raw';
import SearchContainer from '../../shared/components/container/Search';
import SocialFeed from '../../shared/components/presentational/SocialFeed';
import SocialMediaContainer from 'src/shared/components/container/SocialMedia';
import StaticBlock from '../../shared/components/presentational/StaticBlock';
import TextBlock from '../../shared/components/presentational/TextBlock';
import TitleBlock from '../../shared/components/presentational/TitleBlock';
import VGIContainer from 'src/shared/components/container/VGI/VGIContainer';
import VideoBannerContainer from 'src/shared/components/container/VideoBanner/VideoBannerContainer';

export const blockComp: { [key in PageBlockType]: React.ReactType } = {
  [PageBlockType.BLOCK_TYPE_ARTICLE]: ArticleContainer,
  [PageBlockType.BLOCK_TYPE_ARTICLE_BANNER]: ArticleBanner,
  [PageBlockType.BLOCK_TYPE_ARTICLE_LIST]: ArticleList,
  [PageBlockType.BLOCK_TYPE_ARTICLE_LIST_DYNAMIC]: ArticleList,
  [PageBlockType.BLOCK_TYPE_CATEGORY_LIST]: CategoryListContainer,
  [PageBlockType.BLOCK_TYPE_COMMENT]: CommentDisqus,
  [PageBlockType.BLOCK_TYPE_CONTACT]: Contact,
  [PageBlockType.BLOCK_TYPE_COOKIE]: CookieConsentContainer,
  [PageBlockType.BLOCK_TYPE_DOWNLOAD]: Download,
  [PageBlockType.BLOCK_TYPE_FOOTER]: FooterBlock,
  [PageBlockType.BLOCK_TYPE_FOOTER_ADVANCED]: FooterV5,
  [PageBlockType.BLOCK_TYPE_GALACTIC_HEADER]: GalacticHeader,
  [PageBlockType.BLOCK_TYPE_IFRAME]: IFrame,
  [PageBlockType.BLOCK_TYPE_JSONLD]: JsonLd,
  [PageBlockType.BLOCK_TYPE_LINK]: LinkBlock,
  [PageBlockType.BLOCK_TYPE_LOGO]: Logo,
  [PageBlockType.BLOCK_TYPE_IMAGE]: ImageListContainer,
  [PageBlockType.BLOCK_TYPE_IMAGE_LIST]: ImageListContainer,
  [PageBlockType.BLOCK_TYPE_MEA]: MeaContainer,
  [PageBlockType.BLOCK_TYPE_MEA_CAROUSEL]: MeaCarouselContainer,
  [PageBlockType.BLOCK_TYPE_MEA_VIDEO]: MeaVideoContainer,
  [PageBlockType.BLOCK_TYPE_NAVIGATION]: Navigation,
  [PageBlockType.BLOCK_TYPE_OMNITURE_HEADER]: OmnitureHeader,
  [PageBlockType.BLOCK_TYPE_OMNITURE_FOOTER]: RawBlock,
  [PageBlockType.BLOCK_TYPE_RAW]: RawBlock,
  [PageBlockType.BLOCK_TYPE_SEARCH]: SearchContainer,
  [PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS]: SocialMediaContainer,
  [PageBlockType.BLOCK_TYPE_SOCIAL_FEED]: SocialFeed,
  [PageBlockType.BLOCK_TYPE_STATIC]: StaticBlock,
  [PageBlockType.BLOCK_TYPE_TEXT]: TextBlock,
  [PageBlockType.BLOCK_TYPE_TITLE]: TitleBlock,
  [PageBlockType.BLOCK_TYPE_TITLE_DYNAMIC]: TitleBlock,
  [PageBlockType.BLOCK_TYPE_LOGIN]: LoginContainer,
  [PageBlockType.BLOCK_TYPE_LANG]: LangSelectorContainer,
  [PageBlockType.BANNER_HEADER]: BannerHeader,
  [PageBlockType.BLOCK_TYPE_VIDEO_BANNER]: VideoBannerContainer,
  [PageBlockType.BLOCK_TYPE_VGI]: VGIContainer,
  [PageBlockType.BLOCK_TYPE_OFFICE_LIST]: OfficeListContainer,
};
