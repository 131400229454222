/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { getMainMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import LazyLoader from '../LazyLoader';
import TruncateTitle from '../TruncateTitle';
import TruncateText from '../TruncateText';
import Tags from '../Tags';
import { ArticleBase } from '../../../../typings/components/articleBase';
import CustomLink from '../../container/CustomLink';

type Props = {
  article: ArticleBase;
  onClick?: (e: any) => void;
  overlay: boolean;
  abstractExposed: boolean;
  disableLazyLoad: boolean;
  displayTags: boolean;
  isCompact: boolean;
  articleClassName?: string;
};

class ArticlePreview extends React.PureComponent<Props> {
  render() {
    const { article, onClick, overlay, abstractExposed, disableLazyLoad, displayTags, isCompact, articleClassName } = this.props;
    const imgInfo = getMainMedia(article.mainMedia, isCompact);
    const category = get(article, 'category.name', '');
    const title = get(article, 'title', '');
    const abstract = get(article, 'abstract', '');
    const tags = get(article, 'tags', []);
    const articleLink = getLinkFromData(article);
    const categoryLink = article.category ? getLinkFromData(article.category) : '';

    return (
      <article className={articleClassName}>
        <CustomLink
          className={classNames('article-item', 'article-preview', {
            'article-preview--displayTags': displayTags,
            overlay,
          })}
          to={articleLink}
          onClick={onClick}
        >
          <div className="article-preview__image">
            <LazyLoader offset={100} height={230} disableLazyLoad={disableLazyLoad}>
              <img src={imgInfo.src} alt={imgInfo.description} />
            </LazyLoader>
          </div>
          {!!category && <p className="category">{!!categoryLink ? <span className="category">{category}</span> : category}</p>}
          {!!title && <TruncateTitle title={title} />}
          {abstractExposed && abstract && <TruncateText text={abstract} />}
          {displayTags && tags && tags.length && <Tags content={{ tags }} />}
        </CustomLink>
      </article>
    );
  }
}

export default ArticlePreview;
