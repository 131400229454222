import React from 'react';

type Props = {
  className?: string;
  ariaLabel?: string;
  topArrow?: number;
  onClick?: (e: React.SyntheticEvent) => void;
};

export class ArrowSlider extends React.PureComponent<Props> {
  render() {
    const { className, onClick, topArrow, ariaLabel } = this.props;
    return <button aria-label={ariaLabel} className={className} style={topArrow ? { top: topArrow / 2 } : {}} onClick={onClick} />;
  }
}
