import ConfigHelper from 'configHelper';
import React from 'react';

import { ArticleListTemplateConst } from 'src/typings/constants/ArticleListTemplateConst';
import { CategoryListAdvanced } from '../../presentational/CategoryListAdvanced/CategoryListAdvanced';
import CategoryList from '../../presentational/CategoryList';

const isTemplateArticleListAdvanced: boolean =
  ConfigHelper.getClientConfig('feature.articlesList.article.template') === ArticleListTemplateConst.ADVANCED;

const CategoryListContainer = ({ content, className }) =>
  isTemplateArticleListAdvanced ? <CategoryListAdvanced content={content} /> : <CategoryList content={content} className={className} />;
export default CategoryListContainer;
