/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import classNames from 'classnames';
import { getLinkHref } from '../../../helpers/uriHelper';
import { Tag } from '../../../../typings/components/tag';
import { FragmentTag } from 'src/typings/components/fragment';
import CustomLink from '../../container/CustomLink';

type Props = {
  content: FragmentTag;
  className?: string;
};

/**
 * Tags component for displaying Tags data
 * @constructor
 */
class Tags extends React.Component<Props> {
  getListTag(tags: Tag[]) {
    return tags.map(
      (item) =>
        item.links && (
          <li key={`tags__tagItem${item.name}`} className="tags__tagItem">
            <CustomLink to={getLinkHref(item.links && item.links[0])} className="tags__tagItemLink">
              {item.name}
            </CustomLink>
          </li>
        ),
    );
  }

  render() {
    const { className, content } = this.props;
    const listTag = this.getListTag(content.tags);

    return (
      <div className={classNames(className, 'tags')}>
        <ul className="tags__tagList">{listTag}</ul>
      </div>
    );
  }
}

export default Tags;
