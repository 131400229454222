import React from 'react';
import LazyLoader from '../LazyLoader';
import TruncateTitle from '../TruncateTitle';
import { getMainMedia, getArticleMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import { ContentArticleBanner } from 'src/typings/components/content';
import CustomLink from '../../container/CustomLink';

type Props = {
  isCompact: boolean;
  content: ContentArticleBanner;
  id: number;
};

class ArticleBanner extends React.PureComponent<Props> {
  render() {
    const { isCompact, content, id } = this.props;

    return (
      <div className="article-banner">
        {content.columns.map(({ article, media }) => {
          const { title } = article;
          const linkTo = getLinkFromData(article);
          const articleImg = getMainMedia(article.mainMedia, isCompact).src;
          const bgImageURL = media ? getArticleMedia(media).src : null;
          const imgUrl = bgImageURL || articleImg;
          const eleModifier = content.columns.length > 1 ? ' article-banner-element--multiple' : '';
          return (
            <CustomLink className={`article-banner-element${eleModifier}`} to={linkTo} key={`article-banner-${title}-${id}`}>
              <LazyLoader offset={100} height={200}>
                <div className="article-banner-element__wrapper" style={imgUrl ? { backgroundImage: `url(${imgUrl})` } : {}} />
                <TruncateTitle title={title} level={3} className="article-banner-element__title" />
              </LazyLoader>
            </CustomLink>
          );
        })}
      </div>
    );
  }
}

export default ArticleBanner;
