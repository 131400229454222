/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';

import { Player } from '../../../../typings/constants/players';

const EMBED_PATH = 'https://www.youtube.com/embed/';

class YoutubePlayer extends React.Component<Player, {}> {
  player: any;

  componentDidUpdate(prevProps: Player) {
    if (this.props.id && this.props.id !== prevProps.id && this.player) {
      this.player.loadVideoById(this.props.id);
    }
  }

  onYoutubeReady = (event: any) => {
    this.player = event.target;
    if (this.props.id) {
      if (this.props.autoplay) {
        this.player.loadVideoById(this.props.id);
      } else {
        this.player.cueVideoById(this.props.id);
      }
    }
  };

  onStateChange = (event: any) => {
    // see the doc of youtube https://developers.google.com/youtube/iframe_api_reference
    // 0 means video ended
    if (event.data === 0 && this.props.onEnd) {
      this.props.onEnd();
    }
  };

  render() {
    const { isVisible, id, title } = this.props;

    return (
      <div className={`video__youtube ${isVisible ? '' : 'video__youtube--isHidden'}`}>
        <iframe src={`${EMBED_PATH}/${id}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title={title} />
      </div>
    );
  }
}

export default YoutubePlayer;
