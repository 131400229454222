/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { Component } from 'react';
import Logger from 'loggerHelper';
import * as ScriptsHelper from '../../../helpers/scriptHelper';
import Title from '../Title';
import FacebookEmbed from './Embeds/Facebook';
import TwitterEmbed from './Embeds/Twitter';
import { FB_SCRIPT_SRC, TW_SCRIPT_SRC, SocialShare } from '../../../../typings/constants/socialNetworks';
import { ContentSocialFeed, FluxFeed, Flux } from 'src/typings/components/content';
import { WindowApp } from 'src/typings/global';

type Props = {
  content: ContentSocialFeed;
};

type SocialList = {
  [key: string]: SocialListItem;
};

type SocialListItem = {
  script: string;
  embedComp: any;
};

const execution = (type: SocialShare) => {
  const defaultRet = {
    checker: (): boolean => {
      return false;
    },
    loader: (): void => {},
  };

  const windowApp: WindowApp = window as unknown as WindowApp;
  switch (type) {
    case SocialShare.FACEBOOK:
      return {
        checker: (): boolean => !!windowApp.FB,
        loader: (): void => {
          if (windowApp.FB) {
            windowApp.FB.XFBML.parse();
          }
        },
      };
      break;
    case SocialShare.TWITTER:
      return {
        checker: (): boolean => !!windowApp.twttr,
        loader: (): void => {
          if (windowApp.twttr) {
            windowApp.twttr.widgets.load();
          }
        },
      };
      break;
    default:
  }

  return defaultRet;
};
class SocialFeed extends Component<Props> {
  socialList: SocialList;

  constructor(props: Props) {
    super(props);
    this.socialList = {
      facebook: {
        script: FB_SCRIPT_SRC,
        embedComp: FacebookEmbed,
      },
      twitter: {
        script: TW_SCRIPT_SRC,
        embedComp: TwitterEmbed,
      },
    };
  }

  getEmbed(feed: FluxFeed) {
    if (this.socialList[feed.type]) {
      const EmbedComponent: any = this.socialList[feed.type].embedComp;
      return <EmbedComponent apiId={feed.apiId} key={feed.type} />;
    }
    return '';
  }

  renderFeeds(flux: Flux) {
    return flux.map((feed) => this.getEmbed(feed));
  }

  componentDidMount() {
    // Load/Reload Embed Scripts
    this.props.content.flux.forEach(async (feed) => {
      if (this.socialList[feed.type]) {
        const socialScript: string = this.socialList[feed.type].script;
        try {
          const { checker, loader } = execution(feed.type);
          await ScriptsHelper.loadScript(socialScript, checker);
          loader();
        } catch (e) {
          Logger.error(`Load social script error: ${e}`);
        }
      }
    });
  }

  render() {
    const { flux, title } = this.props.content;
    return (
      <div className="SocialFeed">
        <Title title={title} level={2} />
        <div className="SocialFeed__wrap">{this.renderFeeds(flux)}</div>
      </div>
    );
  }
}

export default SocialFeed;
