/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Logger from 'loggerHelper';
import { HttpClient, getCancelToken, isCancel } from './HttpClient';
import { getPageURI, getSearchURI } from '../helpers/uriHelper';

type FetchPageOptions = {
  uniqueId?: string;
  monitoring?: boolean;
  token?: string;
  timeout?: number;
  lang?: string;
};

const StructureApi = {
  /**
   * Request the given page on the API
   *
   * @param {string} path    The page path
   * @param {object} options
   * @returns {Promise.<*>}
   */
  async fetchPage(path: string, options: FetchPageOptions = {}) {
    const { token, uniqueId, monitoring } = options;
    const { lang } = options;

    Logger.debug('StructureApi::fetchPage()', Logger.generateMetadata({ uniqueId }));

    let parameters: { [key: string]: string } = {};
    if (monitoring && monitoring === true) {
      parameters = {
        monitoring: 'true',
        timestamp: Date.now().toString(),
      };
    }

    if (lang) {
      parameters.tl = lang;
    }

    const url = getPageURI(path, parameters);

    Logger.info(
      `Fetch GET ${url}`,
      Logger.generateApiMetadata('structure', 'fetchPage', {
        uniqueId,
        token,
        endpoint: url,
        baseURL: HttpClient.defaults.baseURL,
      }),
    );

    const headers: any = {
      'X-UNIQUE-ID': uniqueId,
      ...(lang && { 'X-LANG': lang.toLowerCase() }),
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // $FlowFixMe
    return HttpClient.get(url, {
      headers,
    });
  },

  /**
   * Perform a search on the API
   *
   * @param {string} words
   * @param {object} options
   * @returns {Promise.<*>}
   */
  async search(words: string, options: any = {}) {
    const { uniqueId, monitoring, lang } = options;

    Logger.debug('StructureApi::search()', Logger.generateMetadata({ uniqueId }));

    let parameters: { [key: string]: string } = {};
    if (monitoring && monitoring === true) {
      parameters = {
        monitoring: 'true',
        timestamp: Date.now().toString(),
      };
    }

    if (lang) {
      parameters.tl = lang;
    }

    const cancelToken = getCancelToken();
    const url = getSearchURI(words, parameters);

    Logger.info(
      `Fetch GET ${url}`,
      Logger.generateApiMetadata('structure', 'search', {
        uniqueId,
        endpoint: url,
      }),
    );

    // $FlowFixMe
    return HttpClient.get(url, {
      cancelToken,
      headers: {
        'X-UNIQUE-ID': uniqueId,
      },
    });
  },

  /**
   * Login on the API
   *
   * @param {string} url
   * @param {string} username
   * @param {string} password
   * @param {object} options
   * @returns {Promise.<*>}
   */
  async login(url: string, username: string, password: string, options: any = {}) {
    const { uniqueId } = options;

    Logger.debug('StructureApi::login()', Logger.generateMetadata({ uniqueId }));

    const cancelToken = getCancelToken();

    Logger.info(
      `Fetch POST ${url}`,
      Logger.generateApiMetadata('structure', 'login', {
        uniqueId,
        endpoint: url,
      }),
    );

    const data = {
      username,
      password,
    };

    // $FlowFixMe
    return HttpClient.post(url, data, {
      cancelToken,
      headers: {
        'X-UNIQUE-ID': uniqueId,
      },
    });
  },

  /**
   * Check if given error is a canceled request error
   *
   * @param {object} error
   * @returns {boolean}
   */
  isCancelError(error: Record<string, any>): boolean {
    return isCancel(error);
  },
};

export default StructureApi;
