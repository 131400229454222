import React from 'react';

import TextBlock from '../../presentational/TextBlock';
import { ContentText } from 'src/typings/components/content';

const TWITTER_WIDGET_URL = 'https://platform.twitter.com/widgets.js';

const TextBlockContainer = ({ content, className }: { content: ContentText; className?: string }) => {
  const widgetSrc = content.text.includes('pic.twitter.com') ? TWITTER_WIDGET_URL : undefined;

  return <TextBlock content={content} className={className} widgetSrc={widgetSrc} />;
};

export default TextBlockContainer;
