import { connect } from 'react-redux';
import CookieConsent from '../../presentational/CookieConsent';
import { State } from '../../../../typings/state/state';

/**
 * CookieConsent container component
 * @class
 */

const mapStateToProps = ({ page }: State) => ({
  site: page.data.site && page.data.site.title ? page.data.site.title : '',
});

const CookieConsentContainer = connect(mapStateToProps)(CookieConsent);

export default CookieConsentContainer;
