/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { Component } from 'react';
import * as ScriptsHelper from '../../../helpers/scriptHelper';
import { Player } from '../../../../typings/constants/players';
import { WindowApp } from 'src/typings/global';
import ConfigHelper from 'configHelper';

/**
 * CanalPlayer
 * Component rendering Canal's OnePlayer
 */
export class CanalPlayer extends Component<Player> {
  player: any;
  playerContainer: React.ReactNode;

  state = {
    loaded: false,
  };

  componentDidMount() {
    this.loadPlayer(ConfigHelper.getClientConfig('feature.canalPlayer.script_url'));
  }

  async loadPlayer(src: string) {
    try {
      await ScriptsHelper.loadScript(src, () => !!(window as unknown as WindowApp).OnePlayer);
      this.createPlayer();
    } catch (e) {
      console.error(e);
    }
  }

  createPlayer = () => {
    const { id, autoplay } = this.props;
    const settings = {
      content: id,
      platform: 'live',
      context: 'cplus',
      base: ConfigHelper.getClientConfig('feature.canalPlayer.base_url'),
      params: {
        autoplay,
      },
    };
    // Class OnePlayer is directly available in window
    this.player = new (window as unknown as WindowApp).OnePlayer(this.playerContainer, settings);
    this.setState({
      loaded: true,
    });
  };

  render() {
    return (
      <div
        id="onePlayer"
        ref={(comp) => {
          this.playerContainer = comp;
        }}
      />
    );
  }
}

export default CanalPlayer;
