/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';
import LangSelector from '../../presentational/LangSelector';
import {
  countriesLangMapping,
  getCountryCodeFromLang,
  getCustomLabelsFromAvailableLangs,
} from '../../presentational/LangSelector/helpers/LangSelectorHelper';

/* eslint-disable */
const mapStateToProps = (state: State) => ({
  /* eslint-enable */
  defaultValue: state.page.lang ? countriesLangMapping[state.page.lang] : undefined,
  countriesAvailable: state.page.langsAvailable.map((lang: string) => getCountryCodeFromLang(lang)),
  customLabels: getCustomLabelsFromAvailableLangs(state.page.langsAvailable),
});

const LangSelectorContainer = connect(mapStateToProps)(LangSelector);

export default LangSelectorContainer;
