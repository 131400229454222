/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import classNames from 'classnames';
import ConfigHelper from 'configHelper';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { ContainerType } from 'src/typings/constants/containerTypes';
import { getAbsoluteLink } from 'src/shared/helpers/socialHelper';
import { getImageWithSize } from '../../../helpers/imageHelper';
import { getMetaWithProperty } from '../../../helpers/pageHelper';
import { IPageState } from 'src/typings/state/pageState';
import { PageMeta, PageScript, AttributesMeta, ExternalScript, NameMeta, PropertyMeta, RawScript } from 'src/typings/components/page';
import { renderPageContainer } from '../../componentFactory';

type PageProps = {
  pageData: IPageState;
  pageURI: string;
  scrollTo: (x: number, y?: number) => void;
  closeNavHeader: () => void;
};

/**
 * Page component; Dynamically build page from a page pageData
 *
 * @param {object} pageData TPageResultPayload pageData
 *
 * @constructor
 */
class Page extends Component<PageProps> {
  componentDidUpdate(prevProps: PageProps) {
    // If the url changed, close menu and go to top
    if (prevProps.pageURI !== this.props.pageURI) {
      this.props.scrollTo(0, 0);
      this.props.closeNavHeader();
    }
  }

  /**
   * @returns {Array.<PageMeta>}
   */
  getPageMeta = () => {
    const { pageData, pageURI } = this.props;

    if (!pageData.data.meta) {
      return [];
    }
    // pull some default meta values from config
    const defaultMetasContent: { [key: string]: string } = {
      'og:url': getAbsoluteLink(pageURI),
      'og:title': ConfigHelper.getClientConfig('html.defaultTitle'),
      'twitter:title': ConfigHelper.getClientConfig('html.defaultTitle'),
      'og:description': ConfigHelper.getClientConfig('html.meta').find((meta: NameMeta) => meta.name === 'description'),
      'twitter:description': ConfigHelper.getClientConfig('html.meta').find((meta: NameMeta) => meta.name === 'description'),
      'twitter:card': 'summary_large_image',
    };
    // og:image dimension formatter
    const ogImageContent = (content: string) => {
      const metaOgWidth = getMetaWithProperty(pageData.data.meta, 'og:image:width');
      const metaOgHeight = getMetaWithProperty(pageData.data.meta, 'og:image:height');
      const size = {
        width: metaOgWidth ? metaOgWidth.content : '',
        height: metaOgHeight ? metaOgHeight.content : '',
      };
      return getImageWithSize(size, content);
    };

    const twitterMetas: PageMeta[] = [];
    let formattedMetas = pageData.data.meta.map((meta: PageMeta) => {
      // there might be some attributes contains multiple attribute, not only property & content
      const transformMeta = () => {
        if ((meta as AttributesMeta).attributes) {
          return Object.values((meta as AttributesMeta).attributes).reduce((acc, attr) => ({ ...acc, [attr.attribute]: attr.value }), {});
        }
        return meta;
      };

      const transformedMeta = transformMeta();
      const { property, content } = transformedMeta as PropertyMeta;

      switch (property) {
        case 'og:image':
          // format og:image with height/width info
          if (content) {
            const imageContent = ogImageContent(content);
            twitterMetas.push({ property: 'twitter:image', content: imageContent });
            return { property, content: imageContent };
          }
          break;
        case 'og:title':
          twitterMetas.push({ property: 'twitter:title', content });
          break;
        case 'og:description':
          twitterMetas.push({ property: 'twitter:description', content });
          break;
        case 'og:type':
          twitterMetas.push({ property: 'twitter:card', content: 'summary_large_image' });
          break;
        default:
          break;
      }

      // the content attr must exist even for non-definitive attributes number
      // populate other metas' content with default values if it's null
      return content ? transformedMeta : { ...transformedMeta, content: defaultMetasContent[property] };
    });

    formattedMetas = formattedMetas.concat(twitterMetas);
    // we might completely don't have 'og:type' object (why?), add it if that's the case
    return getMetaWithProperty(formattedMetas as PageMeta[], 'og:type')
      ? formattedMetas
      : [...formattedMetas, { property: 'og:type', content: 'website' }];
  };

  /**
   * Return an array of script component to include
   *
   * @returns {Array}
   */
  getScripts = () => {
    const { pageData } = this.props;

    if (!pageData.data.scripts) {
      return [];
    }

    return pageData.data.scripts.map((script: PageScript) => {
      if ((script as RawScript).raw) {
        return (
          <script key={`script-${(script as RawScript).raw}`} type={(script as RawScript).type}>
            {(script as RawScript).raw}
          </script>
        );
      }

      if ((script as ExternalScript).url) {
        return <script key={`script-${(script as ExternalScript).url}`} type="text/javascript" src={(script as ExternalScript).url} />;
      }

      return null;
    });
  };

  render() {
    const { pageData, pageURI } = this.props;

    return (
      <div className={classNames(pageData.data.template, 'page')}>
        <a id="hidden-div" className='hidden-content' tabIndex={0}></a>
        <a className="skip-link" href="#main-content">Accès au contenu principal</a>
        <Helmet
          htmlAttributes={{ lang: pageData.lang, prefix: 'og: http://ogp.me/ns#' }}
          link={[{ rel: 'canonical', href: getAbsoluteLink(pageURI) }]}
          meta={this.getPageMeta()}
          title={pageData.data.title}
        >
          {this.getScripts()}
        </Helmet>
        {Object.keys(pageData.data.blocks).map((containerType) => renderPageContainer(containerType as ContainerType))}
      </div>
    );
  }
}

export default Page;
