import classNames from 'classnames';
import React from 'react';
import Helmet from 'react-helmet';
import { throttle } from 'lodash';

import ScrollService from '../../../service/ScrollService';
import { ContentArticleDynamic } from 'src/typings/components/content';
import { Fragment } from 'src/typings/components/fragment';
import { SOCIALBAR_FIXED_SCROLL_VALUE } from '../../../constants/scroll';

export type ArticleProps = {
  content: ContentArticleDynamic;
  metaOgTitle?: boolean;
  metaOgDescription?: boolean;
  metaOgType?: boolean;
  renderFragment: (fragment: Fragment) => JSX.Element;
};

type State = {
  fixSocialBar: boolean;
};

type MetaInfo = {
  property: string;
  content: string | undefined;
};
class Article extends React.Component<ArticleProps, State> {
  scrollService: ScrollService;
  constructor(props: ArticleProps) {
    super(props);
    this.scrollService = new ScrollService();
    this.state = { fixSocialBar: false };
  }

  componentDidMount() {
    this.scrollService.attach();
    this.scrollService.addEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
  }

  componentWillUnmount() {
    this.scrollService.removeEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
    this.scrollService.dispose();
  }

  onScrollChange = throttle((scrollTop: number) => {
    if (scrollTop > SOCIALBAR_FIXED_SCROLL_VALUE && !this.state.fixSocialBar) {
      this.setState({ fixSocialBar: true });
    } else if (scrollTop <= SOCIALBAR_FIXED_SCROLL_VALUE && this.state.fixSocialBar) {
      this.setState({ fixSocialBar: false });
    }
  }, 100);

  render() {
    const { content, metaOgTitle = false, metaOgDescription = false, metaOgType = false, renderFragment } = this.props;

    if (Array.isArray(content)) {
      return null;
    }

    const { article } = content;

    const metaToAdd: Array<MetaInfo> = [];

    if (!metaOgTitle) {
      metaToAdd.push({ property: 'og:title', content: article.title });
      metaToAdd.push({ property: 'twitter:title', content: article.title });
    }

    if (!metaOgDescription) {
      metaToAdd.push({ property: 'og:description', content: article.subtitle });
      metaToAdd.push({ property: 'twitter:description', content: article.subtitle });
    }

    if (!metaOgType) {
      metaToAdd.push({ property: 'og:type', content: 'article' });
    }

    return (
      <div
        className={classNames('article', {
          article__socialBarFixed: this.state.fixSocialBar,
        })}
      >
        <Helmet meta={metaToAdd} />
        {article.fragments && article.fragments.map(renderFragment)}
      </div>
    );
  }
}

export default Article;
