export enum PageBlockType {
  BLOCK_TYPE_ARTICLE = 'canalplus.block.dynamic.article',
  BLOCK_TYPE_ARTICLE_BANNER = 'canalplus.block.banner',
  BLOCK_TYPE_ARTICLE_LIST = 'canalplus.block.articlelist',
  BLOCK_TYPE_ARTICLE_LIST_DYNAMIC = 'canalplus.block.dynamic.articlelist',
  BLOCK_TYPE_CATEGORY_LIST = 'canalplus.block.categorylist',
  BLOCK_TYPE_COMMENT = 'canalplus.block.comment',
  BLOCK_TYPE_CONTACT = 'canalplus.block.contact',
  BLOCK_TYPE_COOKIE = 'canalplus.block.cookie',
  BLOCK_TYPE_DOWNLOAD = 'canalplus.block.download',
  BLOCK_TYPE_FOOTER = 'canalplus.block.footer',
  BLOCK_TYPE_FOOTER_ADVANCED = 'canalplus.block.footer.advanced',
  BLOCK_TYPE_GALACTIC_HEADER = 'canalplus.block.galacticheader',
  BLOCK_TYPE_IFRAME = 'canalplus.block.iframe',
  BLOCK_TYPE_JSONLD = 'canalplus.block.jsonld',
  BLOCK_TYPE_LINK = 'canalplus.block.link',
  BLOCK_TYPE_LOGO = 'canalplus.block.logo',
  BLOCK_TYPE_IMAGE = 'canalplus.block.image',
  BLOCK_TYPE_IMAGE_LIST = 'canalplus.block.images.list',
  BLOCK_TYPE_MEA = 'canalplus.block.mea',
  BLOCK_TYPE_MEA_CAROUSEL = 'canalplus.block.mea.carousel',
  BLOCK_TYPE_MEA_VIDEO = 'canalplus.block.meavideo',
  BLOCK_TYPE_NAVIGATION = 'canalplus.block.menu',
  BLOCK_TYPE_OMNITURE_HEADER = 'canalplus.block.tracking.omniture.header',
  BLOCK_TYPE_OMNITURE_FOOTER = 'canalplus.block.tracking.omniture.footer',
  BLOCK_TYPE_RAW = 'canalplus.block.raw',
  BLOCK_TYPE_SEARCH = 'canalplus.block.search',
  BLOCK_TYPE_SOCIAL_NETWORKS = 'canalplus.block.socialnetwork',
  BLOCK_TYPE_SOCIAL_FEED = 'canalplus.block.socialnetwork.flux',
  BLOCK_TYPE_STATIC = 'canalplus.block.static',
  BLOCK_TYPE_TEXT = 'canalplus.block.text',
  BLOCK_TYPE_TITLE = 'canalplus.block.title',
  BLOCK_TYPE_TITLE_DYNAMIC = 'canalplus.block.dynamic.title',
  BLOCK_TYPE_LOGIN = 'canalplus.block.login',
  BLOCK_TYPE_LANG = 'canalplus.block.locale.list',
  BANNER_HEADER = 'canalplus.block.header.banner',
  BLOCK_TYPE_VIDEO_BANNER = 'canalplus.block.banner.video',
  BLOCK_TYPE_VGI = 'canalplus.block.vgi',
  BLOCK_TYPE_OFFICE_LIST = 'canalplus.block.office.list',
}
