import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import CreativeMediaArticle from '../CreativeMediaArticle/CreativeMediaArticle';
import VideoSwitch from '../VideoSwitch';
import VGIVideoGif from '../VGIVideoGif'
import { ArticleBase } from 'src/typings/components/articleBase';
import { getArticleMedia } from 'src/shared/helpers/imageHelper';
import { Link as LinkType } from 'src/typings/components/link';
import { MediaType } from 'src/typings/components/media';
import Modal  from '../Modal/Modal';
import { VideoVGI } from 'src/typings/components/video';

type Props = {
  gifContent: {
    url: string;
    transcriptionTitle: string | undefined;
    transcriptionText: string | undefined;
  };
  alt: string;
  video: VideoVGI;
  articles: ArticleBase[];
  image: MediaType & { redirection: LinkType };
  renderArticleContent: (path: string | null) => Promise<ReactNode[] | null>;
  isCompact: boolean;
};

const URL_SEPARATOR = '/creativemedia';

const VGI: FunctionComponent<Props> = ({ alt, gifContent, video, articles, image, renderArticleContent, isCompact }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode[] | null>(null);

  const ClickableImg = ({ children }) => {
    const { rel, href } = image.redirection;

    if (rel !== 'external') {
      return (
        <Link to={href.split(URL_SEPARATOR)[1]} rel="noreferrer">
          {children}
        </Link>
      );
    }

    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  };

  const closeModal = useCallback(() => {
    window.history.replaceState(null, '', '/');

    setModalContent(null);
    setIsModalOpen(false);
  }, []);
  return (
    <>
      <Modal closeModal={closeModal} isModalOpen={isModalOpen}>
        {modalContent}
      </Modal>
      <div className="vgi">
        <VideoSwitch className="vgi__player"
          players={[video.source]} 
          currentArticle={{ video }} 
          autoplay={false} 
        />
        <VGIVideoGif content={gifContent} autoPlay muted loop controls playsInline />
        <ClickableImg>
          <img className="vgi__image" alt={image.alt || ''} src={getArticleMedia(image).src} />
        </ClickableImg>
        {articles &&
          articles.map((content, index) => (
            <div key={index} className={`vgi__article-${index + 1}`}>
              <CreativeMediaArticle
                alt={content.mainMedia.default?.alt}
                article={content}
                ratio={169}
                setModalContent={setModalContent}
                setIsModalOpen={setIsModalOpen}
                renderArticleContent={renderArticleContent}
                isCompact={isCompact}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default VGI;
