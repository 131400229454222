import React, { ReactNode } from 'react';
import ConfigHelper from 'configHelper';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ArticleBase } from '../../../../typings/components/articleBase';
import { ArticleListProps } from '../../presentational/ArticleList/ArticleList';
import { ArticleListTemplateConst } from 'src/typings/constants/ArticleListTemplateConst';
import { ArticleListTypes } from 'src/typings/constants/articleListTypes';
import { renderContent } from 'src/shared/helpers/modalHelper';
import { setHeightImg } from '../../../actions/articleList';
import { setPlaylistIndexFromArticleId } from '../../../actions/meaPlaylist';
import { State } from '../../../../typings/state/state';
import ArticleList from '../../presentational/ArticleList';
import breakpoints from '../../../constants/breakpoints';
import SliderAdvanced from '../../presentational/content-row/SliderAdvanced/SliderAdvanced';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

const mapStateToProps = ({ page, meaPlaylist, articleList, search, authentication }: State) => ({
  device: page.device || '',
  listsAsCarousel: ConfigHelper.getClientConfig('feature.mobileListCarousel'),
  displayTags: ConfigHelper.getClientConfig('feature.articleListTags'),
  highlightCenterMode: ConfigHelper.getClientConfig('feature.highlight.centerMode'),
  template: ConfigHelper.getClientConfig('feature.articlesList.article.template'),
  dispatchToMea: meaPlaylist.dispatchToMea,
  focusedArticleId: meaPlaylist.focusedArticleId,
  heightImg: articleList.heightImg,
  isCompact: page.window ? page.window.width <= parseInt(breakpoints.tablet, 10) : false,
  windowSize: page.window || {},
  searchIsOpen: search.isOpen,
  token: authentication.token,
  lang: page.lang,
});

const templateArticleList: ArticleListTemplateConst = ConfigHelper.getClientConfig('feature.articlesList.article.template');
const mapDispatchToProps = (
  dispatch: ThunkDispatch<State, void, Action>,
  ownProps: Partial<ArticleListProps> & { renderBlock?: () => Promise<ReactNode[] | null> },
  ) => {
    const { token, renderBlock } = ownProps;

  return {
    onFocusItemClick: (article: ArticleBase) => (e: any) => {
      if (article.video) {
        e.preventDefault();
        dispatch(setPlaylistIndexFromArticleId(article.id));
      }
    },
    setHeightImg: (height: number) => {
      dispatch(setHeightImg(height));
    },
    renderArticleContent: async (path: string | null, lang: string | undefined) => renderContent(path, token, lang, renderBlock),
  };
};

const ArticleListContainer = ({ ...props }: ArticleListProps): JSX.Element => {
  const {
    renderArticleContent, 
    lang,
    isCompact,
    content: { title, articles, template },
  } = props;

  if (templateArticleList === ArticleListTemplateConst.ADVANCED && template === 'carousel' && props.content) {
    return <SliderAdvanced isCompact={isCompact} list={articles} title={title} renderArticleContent={ (path) => renderArticleContent(path, lang) } />;
  }

  const getArticleListTypes = (): ArticleListTypes => {
    const isCarousel = props.template !== ArticleListTemplateConst.DETOUR && props.content.template === ArticleListTypes.TEMPLATE_HIGHLIGHT;
    const is4Columns =
      props.content.template === ArticleListTypes.TEMPLATE_4_COL ||
      (props.template === ArticleListTemplateConst.ADVANCED && props.type === PageBlockType.BLOCK_TYPE_ARTICLE_LIST_DYNAMIC);

    if (isCarousel) {
      return ArticleListTypes.TEMPLATE_CAROUSEL;
    }
    if (is4Columns) {
      return ArticleListTypes.TEMPLATE_4_COL;
    }
    if (props.content.template) {
      return props.content.template;
    }
    return ArticleListTypes.TEMPLATE_3_COL;
  };

  return <ArticleList {...props} articleListType={getArticleListTypes()} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListContainer);
