/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';
import Logo from '../../presentational/Logo';
import ConfigHelper from 'configHelper';
import TemplateHeaderConst from 'src/typings/constants/templateHeaderConst';
import { clearSearch, toggleDisplay } from 'src/shared/actions/search';
import { ThunkDispatch } from 'redux-thunk';
import { RootAction } from 'src/typings/rootActions';

const templateHeader: TemplateHeaderConst = ConfigHelper.getClientConfig('feature.header.template');
/* eslint-disable */
const mapStateToProps = (state: State) => ({
  /* eslint-enable */
  templateHeader,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, { uniqueId?: string }, RootAction>) => ({
  clearSearch: () => {
    dispatch(clearSearch());
  },
  hideSearchResult: () => {
    dispatch(toggleDisplay(false));
  },
});
const LogoContainer = connect(mapStateToProps, mapDispatchToProps)(Logo);

export default LogoContainer;
