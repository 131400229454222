import { connect } from 'react-redux';
import Navigation from '../../presentational/Navigation';
import { State } from '../../../../typings/state/state';
import breakpoints from '../../../constants/breakpoints';

/**
 * Navigation component
 * @class
 */

const mapStateToProps = ({ page }: State) => ({
  isCompact: page.window ? page.window.width <= parseInt(breakpoints.tablet, 10) : false,
});

const NavigationContainer = connect(mapStateToProps)(Navigation);

export default NavigationContainer;
