/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import NextLink from '../../presentational/NextLink';
import { fetch } from '../../../actions/page';
import { State } from '../../../../typings/state/state';
import { RootAction } from '../../../../typings/rootActions';

/**
 * NextLink container component
 * @class
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<State, {}, RootAction>) => ({
  onClickHandler: (to: string, blockId: number, isSearch?: boolean) => {
    dispatch(fetch(to, blockId, isSearch));
  },
});

const NextLinkContainer = connect(null, mapDispatchToProps)(NextLink);

export default NextLinkContainer;
