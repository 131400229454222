/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { langCodes } from 'src/typings/constants/langs';
import { State } from '../../../../typings/state/state';
import CustomLink from '../../presentational/CustomLink';

/* eslint-disable */
const mapStateToProps = (state: State) => ({
  /* eslint-enable */
  lang: state.page.lang !== langCodes.FR ? state.page.lang : undefined,
});

const CustomLinkContainer = connect(mapStateToProps)(CustomLink);

export default CustomLinkContainer;
