import React from 'react';
import classNames from 'classnames';

/**
 * SubTitle component
 * @param {string} text text
 * @constructor
 */
const Subtitle = ({ content, className }: { content: { text?: string }; className?: string }) => (
  <h3 className={classNames('subtitle', className)}>{content.text}</h3>
);

export default Subtitle;
