const disableScroll = false;

/**
 * ScrollService
 */
class ScrollService {
  listCallBackChange: Array<any>;
  timeoutScrollComplete: boolean;
  timeoutScrollToId: NodeJS.Timeout | null;
  static SCROLL_CHANGE = 'SCROLL_CHANGE';

  /**
   * constructor
   */
  constructor() {
    this.listCallBackChange = [];
    this.timeoutScrollComplete = true;
    this.timeoutScrollToId = null;
  }

  /**
   * [attach description]
   * @return {[type]} [description]
   */
  attach = (): void => {
    if (typeof document === 'undefined') return;

    const mousewheelevt = /Gecko/i.test(navigator.userAgent) || /Firefox/i.test(navigator.userAgent) ? 'DOMMouseScroll' : 'mousewheel'; // FF doesn't recognize mousewheel as of FF3.x

    if ((document as any).attachEvent) {
      // if IE (and Opera depending on user setting)
      (document as any).attachEvent('onscroll', this.onScroll);
      (document as any).attachEvent(`on${mousewheelevt}`, this.onScroll);
      (document as any).attachEvent('onwheel', this.onScroll);
    } else if (document.addEventListener) {
      // WC3 browsers
      document.addEventListener('scroll', this.onScroll);
      document.addEventListener(mousewheelevt, this.onScroll);
      document.addEventListener('wheel', this.onScroll);
    }

    this.listCallBackChange.map((value: any) => value(this.getScrollTop()));
  };

  /**
   * [dispose description]
   * @return {[type]} [description]
   */
  dispose = (): void => {
    if (!document) return;

    const mousewheelevt = /Gecko/i.test(navigator.userAgent) || /Firefox/i.test(navigator.userAgent) ? 'DOMMouseScroll' : 'mousewheel'; // FF doesn't recognize mousewheel as of FF3.x

    if ((document as any).detachEvent) {
      // if IE (and Opera depending on user setting)
      (document as any).detachEvent('onscroll', this.onScroll);
      (document as any).detachEvent(`on${mousewheelevt}`, this.onScroll);
      (document as any).detachEvent('onwheel', this.onScroll);
    } else if (document.removeEventListener) {
      // WC3 browsers
      document.removeEventListener('scroll', this.onScroll);
      document.removeEventListener(mousewheelevt, this.onScroll);
      document.removeEventListener('wheel', this.onScroll);
    }
  };

  /**
   * [onScroll description]
   * @return {[type]} [description]
   */
  onScroll = (e: any): boolean => {
    if (disableScroll) {
      if (e) {
        e.preventDefault();
      }
      return false;
    }

    const scrollTop = this.getScrollTop();

    if (this.timeoutScrollToId) {
      clearTimeout(this.timeoutScrollToId);
    }

    this.listCallBackChange.map((value: any) => value(scrollTop));

    return true;
  };

  /**
   * [scrollTo description]
   * @param  {[type]} top  [description]
   * @param  {[type]} left [description]
   * @return {[type]}      [description]
   */
  scrollTo = (top: number, left: number, withAnim: boolean): void => {
    if (withAnim) {
      // smooth scroll
      let currentValue = this.getScrollTop();
      const funcScroll = () => {
        currentValue += (top - currentValue) / 8;
        window.scroll(left || 0, currentValue);

        if (Math.abs(top - currentValue) > 1) {
          this.timeoutScrollToId = setTimeout(funcScroll, 20);
        }
      };

      if (this.timeoutScrollToId) {
        clearTimeout(this.timeoutScrollToId);
      }
      funcScroll();
    } else {
      window.scroll(left || 0, top);
    }
  };

  /**
   * [getScrollTop description]
   * @return {[type]} [description]
   */
  getScrollTop = (): number => {
    if (!document) return 0;
    return document.body.scrollTop || document.documentElement.scrollTop;
  };

  /**
   * [enablePageScroll description]
   * @return {[value]} [description]
   */
  enablePageScroll = (value = true): void => {
    // disableScroll = !value;

    if (value) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  blockMouseWheel = (): boolean => false;

  addEventListener = (type: string, func: any): void => {
    if (type === ScrollService.SCROLL_CHANGE) {
      this.listCallBackChange.push(func);
    }
  };

  removeEventListener = (type: string, func: any): void => {
    if (type === ScrollService.SCROLL_CHANGE) {
      for (let i = 0; i < this.listCallBackChange.length; i += 1) {
        if (this.listCallBackChange[i] === func) {
          this.listCallBackChange.splice(i, 1);
          break;
        }
      }
    }
  };
}

export default ScrollService;
