/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';
import { updatePage } from '../helpers/pageStateHelper';
import { ISearchState } from '../../typings/state/searchState';
import { SearchResult } from '../../typings/components/searchResult';

const defaultState: ISearchState = {
  keyword: '',
  result: undefined,
  pending: false,
  isOpen: false,
};

export default function (state: ISearchState = defaultState, action: RootAction): ISearchState {
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return {
        ...state,
        pending: true,
        keyword: action.payload,
        result: undefined,
      };
    case 'SEARCH_RESPONSE':
      return {
        ...state,
        pending: false,
        result: action.payload,
      };
    case 'SEARCH_NEXT_RESPONSE':
      return {
        ...state,
        pending: false,
        result: updatePage('merge')(state.result, action.payload.result, action.payload.blockId) as SearchResult,
      };
    case 'SEARCH_CLEAR':
      return {
        ...state,
        pending: false,
        result: undefined,
      };
    case 'SEARCH_TOGGLE_DISPLAY':
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case 'SEARCH_CHANGE_KEYWORD':
      return {
        ...state,
        keyword: action.payload.keyword,
      };
    default:
      return state;
  }
}
