/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import ConfigHelper from 'configHelper';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import SocialBar from '../../presentational/SocialBar';
import { openPopup } from '../../../actions/page';
import { State } from '../../../../typings/state/state';
import { WindowDimension } from '../../../../typings/state/pageState';
import { canUseDOM } from '../../../helpers/windowHelper';

const isTemplateSocialBarVertical = ConfigHelper.getClientConfig('feature.socialBarVertical.template');

const mapStateToProps = ({ page }: State) => {
  const pageData = page.data;
  let uri;

  if (canUseDOM()){
     uri = window.location.pathname
  }

  return {
    pageURI: uri,
    pageName: pageData.title,
    tracker: pageData.site.tracker && pageData.site.tracker.type ? pageData.site.tracker.type : undefined,
    window: page.window,
    isTemplateSocialBarVertical,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  openPopup: (url: string, id: string, windowSize: WindowDimension, w: number, h: number) => {
    dispatch(openPopup(url, id, windowSize, w, h));
  },
});

const SocialBarContainer = connect(mapStateToProps, mapDispatchToProps)(SocialBar);

export default SocialBarContainer;
