import React from 'react';
import { connect } from 'react-redux';
import ConfigHelper from 'configHelper';

import Footer from '../../presentational/Footer';
import FooterV5 from '../../presentational/FooterV5';
import TemplateFooterConst from 'src/typings/constants/templateFooterConst';
import { ContainerType } from '../../../../typings/constants/containerTypes';
import { State } from '../../../../typings/state/state';
import { updateFooterHeight } from 'src/shared/actions/page';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { MenuItem } from 'src/typings/components/navigation';
import { LinkFooter } from 'src/typings/components/link';
import { sliceRightColumnLinks } from '../../../helpers/footerHelper';
import { AdvancedFooterRightColumnDirection } from 'src/typings/constants/templateFooterConst';

const isTemplateFooterV5 = ConfigHelper.getClientConfig('feature.footer.template') === TemplateFooterConst.V5;

const mapStateToProps = (state: State) => ({
  elements: state.page.data.blocks[ContainerType.FOOTER],
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateFooterHeight: (height) => {
    dispatch(updateFooterHeight(height));
  },
});

const FooterContainer = ({ elements, renderBlock, ...props }) => {
  if (isTemplateFooterV5) {
    if (!elements) {
      return <FooterV5 columnList={[]} renderBlock={renderBlock} />;
    }
    const FooterV5Block = elements.find(
      (block) => block.type === PageBlockType.BLOCK_TYPE_FOOTER_ADVANCED || block.type === PageBlockType.BLOCK_TYPE_FOOTER,
    );
    const trackingBlocks = elements.filter((block) => block.type === PageBlockType.BLOCK_TYPE_OMNITURE_FOOTER);

    if (FooterV5Block.type === PageBlockType.BLOCK_TYPE_FOOTER) {
      FooterV5Block?.content?.menu.forEach((menuSection: MenuItem) => {
        // create first a empty array for each menu section in the footer.
        let linksSection: LinkFooter[] = [];
        menuSection.children?.forEach((subMenu: MenuItem) => {
          linksSection.push({ label: subMenu.title, link: subMenu.links });
        });
        FooterV5Block.content.links.push(linksSection);
      });
    }

    const { links } = FooterV5Block?.content ? FooterV5Block?.content : [];
    const [columnLeftLinks, columnRightLinks] = links;

    const columnList = [
      columnLeftLinks,
      sliceRightColumnLinks(columnRightLinks, AdvancedFooterRightColumnDirection.LEFT),
      sliceRightColumnLinks(columnRightLinks, AdvancedFooterRightColumnDirection.RIGHT),
    ];

    return <FooterV5 columnList={columnList} trackingBlocks={trackingBlocks} renderBlock={renderBlock} />;
  }

  return <Footer updateFooterHeight={updateFooterHeight} elements={elements} renderBlock={renderBlock} {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
