/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import Slider from 'react-slick';
import { getSliderConfig } from '../../../helpers/sliderHelper';
import { ArticleBase } from '../../../../typings/components/articleBase';
import { WindowDimension } from '../../../../typings/state/pageState';
import { ContentArticleList } from 'src/typings/components/content';
import SliderItem from '../SliderItem';
import SliderItemDetour from '../SliderItemDetour';
import { ArticleListTemplateConst } from 'src/typings/constants/ArticleListTemplateConst';
import { VideoSource } from 'src/typings/constants/players';
import { ArticleListTypes } from 'src/typings/constants/articleListTypes';

type Props = {
  device: string;
  isCompact: boolean;
  id: number;
  content: ContentArticleList;
  highlightCenterMode: boolean;
  onItemClick?: (e: any) => void;
  className?: string;
  isSearch?: boolean;
  listsAsCarousel: boolean;
  disableLazyLoad: boolean;
  dispatchToMea: boolean;
  focusedArticleId: number | null;
  onFocusItemClick: (article: ArticleBase) => (e: any) => void;
  displayTags: boolean;
  heightImg: number;
  setHeightImg: (height: number) => void;
  windowSize: WindowDimension;
  template: ArticleListTemplateConst;
};

class ArticleListCarousel extends React.PureComponent<Props> {
  render() {
    const {
      isCompact,
      displayTags,
      setHeightImg,
      content,
      dispatchToMea,
      focusedArticleId,
      onItemClick,
      onFocusItemClick,
      template,
      heightImg,
    } = this.props;
    const slideToShow = content.template === ArticleListTypes.PORTRAIT ? 4 : 3;
    const sliderConfig = getSliderConfig(template, heightImg, slideToShow);
    const displayGradient = content.articles.length > 2;

    return (
      <div className="article-list__carousel">
        {displayGradient && <div className="slick-gradient-prev" />}
        {displayGradient && <div className="slick-gradient-next" />}
        <Slider {...sliderConfig}>
          {content.articles.map((article) => {
            const videoArticleId = !!article.video ? article.id : false;
            const isMeaArticle = focusedArticleId ? focusedArticleId === videoArticleId : false;
            const itemClick =
              dispatchToMea && article.video && article.video.source !== VideoSource.PFV ? onFocusItemClick(article) : onItemClick;
            return (
              <div className="Slider__ItemWrapper" key={article.id}>
                {template === ArticleListTemplateConst.DETOUR ? (
                  <SliderItemDetour
                    isCompact={isCompact}
                    onClick={itemClick}
                    article={article}
                    overlay={isMeaArticle}
                    abstractExposed={content.abstractExposed}
                    displayTags={displayTags}
                    setHeightImg={setHeightImg}
                    heightImg={heightImg}
                    template={content.template}
                  />
                ) : (
                  <SliderItem
                    isCompact={isCompact}
                    onClick={itemClick}
                    article={article}
                    overlay={isMeaArticle}
                    abstractExposed={content.abstractExposed}
                    displayTags={displayTags}
                    setHeightImg={setHeightImg}
                    heightImg={heightImg}
                  />
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default ArticleListCarousel;
