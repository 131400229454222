import React from 'react';

type Props = {};

class Loader extends React.PureComponent<Props> {
  render() {
    return (
      <div className="loader">
        <svg width="51px" height="51px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <rect x="17.5" y="27.6089" width="15" height="44.7822" fill="#0a0a0a">
            <animate
              attributeName="y"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="16;30;30"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.23255813953488372s"
            ></animate>
            <animate
              attributeName="height"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="68;40;40"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.23255813953488372s"
            ></animate>
          </rect>
          <rect x="42.5" y="26.4061" width="15" height="47.1878" fill="#39393d">
            <animate
              attributeName="y"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="19.499999999999996;30;30"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.11627906976744186s"
            ></animate>
            <animate
              attributeName="height"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="61.00000000000001;40;40"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.11627906976744186s"
            ></animate>
          </rect>
          <rect x="67.5" y="23.3057" width="15" height="53.3886" fill="#59595c">
            <animate
              attributeName="y"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="19.499999999999996;30;30"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            ></animate>
            <animate
              attributeName="height"
              repeatCount="indefinite"
              dur="1.1627906976744184s"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="61.00000000000001;40;40"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            ></animate>
          </rect>
        </svg>
      </div>
    );
  }
}

export default Loader;
