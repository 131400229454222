import React from 'react';
import Truncate from 'react-text-truncate';
import classNames from 'classnames';
import CustomLink from '../../container/CustomLink';
import Heading from '../Heading';

type Props = {
  title: string;
  className?: string;
  level?: number;
  href?: string;
  line?: number;
  onClick?: () => void;
};

/**
 * Title component
 * @param {string} title Title text
 * @constructor
 */

class Title extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    href: '',
    onClick: () => {},
  };

  render() {
    const { title, className, level, href, onClick, line } = this.props;

    return (
      <Heading level={level} className={classNames('title', className)}>
        <>
        {href ? (
          <CustomLink to={href} onClick={onClick} target="_blank">
            {title}
          </CustomLink>
        ) : line ? (
          <Truncate element="span" line={line} truncateText="…" text={title} />
        ) : (
          <React.Fragment>{title}</React.Fragment>
        )}
        </>
        
      </Heading>
    );
  }
}

export default Title;
