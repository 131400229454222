import React, { FunctionComponent } from 'react';

import { getFooterLogoMedia as getBrandLogoMediaList, getBrandLogoMedia } from '../../../helpers/imageHelper';
import { MediaType } from 'src/typings/components/media';

type Props = {
  images: MediaType[];
};

const ImageList: FunctionComponent<Props> = ({ images = [] }) => {
  if (images.length > 0) {
    const imageList = images.map((image) => {
      const alt = image.alt || '';
      const { src } = images.length > 1 ? getBrandLogoMediaList(image) : getBrandLogoMedia(image);

      return <img key={image.filename} src={src} alt={alt} />;
    });

    return <div className="image-list">{imageList}</div>;
  }

  return null;
};

export default ImageList;
