import Logger from 'loggerHelper';
import { formatApiLink, formatApiPageMeta, formatApiPageScript, formatApiPageDataSite } from './ElementParsing';
import { SearchResult } from '../../../typings/components/searchResult';
import { Block } from '../../../typings/components/block';
import { PageData } from '../../../typings/components/page';
import { Content } from '../../../typings/components/content';

import { blockParsingFunc } from 'src/typings/constants/pageFormatBlockMappings';
import { Fragment, FragmentContent } from 'src/typings/components/fragment';
import { fragmentParsingFunc } from 'src/typings/constants/pageFormatFragmentMappings';

export const formatFragment = (data: any): Fragment => {
  let content: FragmentContent | undefined;
  try {
    if (fragmentParsingFunc[data.type]) {
      content = fragmentParsingFunc[data.type](data.content);
    } else {
      content = undefined;
      Logger.error(`ERROR formatFragment. Parsing function for type "${data.type}" not exist. Check fragment type "${data.type}" exist.`);
    }
  } catch (e) {
    content = undefined;
    Logger.error(e);
  }

  return {
    id: data.id,
    type: data.type,
    content,
  };
};

export const formatBlock = (data: any): Block => {
  let content: Content | undefined;

  try {
    if (blockParsingFunc[data.type]) {
      content = blockParsingFunc[data.type](data.content, formatFragment);
    } else {
      content = undefined;
      Logger.error(`ERROR formatBlock. Parsing function for type "${data.type}" not exist. Check block type "${data.type}" exist.`);
    }
  } catch (e) {
    content = undefined;
    Logger.error(e);
  }

  return {
    id: data.id,
    type: data.type,
    container: data.container,
    content,
  };
};

export const formatApiPageData = (data: any): PageData => ({
  statusCode: data.statusCode,
  cache: data.cache,
  title: data.title,
  links: data.links ? data.links.map(formatApiLink) : [],
  template: data.template,
  meta: data.meta ? data.meta.map(formatApiPageMeta) : [],
  scripts: data.scripts ? data.scripts.map(formatApiPageScript) : [],
  headers: data.headers ? data.headers : {},
  site: data.site
    ? formatApiPageDataSite(data.site)
    : {
        locale: '',
        title: '',
        logo: '',
        translatable: false,
        languages: undefined,
      },
  blocks: data.blocks ? data.blocks.map(formatBlock) : [],
});

export const formatApiSearchResult = (data: any): SearchResult => formatApiPageData(data);
