/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Children, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import executeTasks from './executeTasks';

type Props = RouteComponentProps & {
  location: Location;
  dispatch: Dispatch;
  children: React.ReactNode;
};

/**
 * The TaskRoutesExecutor makes sure any data tasks are
 * executed prior to our route being loaded.
 * @see ./src/shared/routeTasks/
 */
class TaskRoutesExecutor extends Component<Props> {
  componentDidMount() {
    executeTasks(this.props.location, this.props.dispatch);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== null && prevProps.location !== this.props.location) {
      executeTasks(this.props.location, this.props.dispatch);
    }
  }

  render() {
    return Children.only(this.props.children);
  }
}

// Wrapping with withRouter injects "location" prop
export default withRouter(TaskRoutesExecutor);
