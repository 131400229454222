/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { matchPath } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootAction } from '../../typings/rootActions';
import { State } from '../../typings/state/state';
import * as PageActions from '../actions/page';
import { LocationApp } from 'src/typings/global';

type TaskFunc = (path: any) => void;

type TaskRouteDispacth = {
  dispatch: ThunkDispatch<State, { uniqueId?: string }, RootAction>;
};

type TaskRoute = {
  pattern: string;
  prefetchData: TaskFunc;
};

type TaskExecutionResult = {
  routes: TaskRoute[];
  results: any[];
};

function taskRoutes({ dispatch }: TaskRouteDispacth): TaskRoute[] {
  return [
    {
      pattern: '*',
      prefetchData: (path: { [key: number]: string }) => dispatch(PageActions.fetch(path[0])),
    },
  ];
}

const matchRoutesToLocation = (routes: TaskRoute[], location: LocationApp) => {
  const matchedRoutes: TaskRoute[] = [];
  const params = {};

  routes.forEach((route: TaskRoute) => {
    if (!route.pattern) {
      matchedRoutes.push(route);
      return;
    }

    const match: any = matchPath(location.pathname, { path: route.pattern });

    if (match) {
      matchedRoutes.push(route);

      if (match.params) {
        Object.keys(match.params).forEach((key) => {
          params[key] = match.params[key];
        });
      }
    }
  });
  return { matchedRoutes, params };
};

/**
 * When you execute the tasks using "runTasksForLocation" please be aware
 * that multiple tasks could be executed. All the resolved tasks are executed
 * via the Promise.all API.
 *
 * @see README.md local to this file for more information.
 */
function runTasksForLocation(
  location: LocationApp,
  taskNames: string[],
  routeDispatch: TaskRouteDispacth,
): Promise<TaskExecutionResult> | null {
  const routes = taskRoutes(routeDispatch);

  // Now we try to match the location being requested against our taskRoutes
  // and see if there are any matches.
  const { matchedRoutes, params } = matchRoutesToLocation(routes, location);

  if (matchedRoutes.length === 0) {
    return null;
  }

  const resolveTasks = (taskName: string) =>
    Promise.all(
      // The list of action routes which were matched.
      matchedRoutes
        // Filter the action routes down to those the action.
        .filter((tasksRoute: any) => tasksRoute[taskName])
        // Then we call the 'prefetchData' action, passing in any routing params.
        // The action should typically return a Promise so that you are able to
        // indicate when the action has completed.
        .map((tasksRoute: any) => tasksRoute[taskName](params)),
    );

  return Promise.all(taskNames.map(resolveTasks)).then((results) => ({
    routes: matchedRoutes,
    results,
  }));
}

export default runTasksForLocation;
