import _ from 'lodash';
import classNames from 'classnames';
import React, { ReactNode, useCallback, SetStateAction, Dispatch } from 'react';

import CustomLink from '../../container/CustomLink';
import Item from './Item/Item';
import { ArticleBase } from 'src/typings/components/articleBase';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';
import { getMainMedia } from 'src/shared/helpers/imageHelper';
import { Office } from 'src/typings/components/office';
import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

const mapStateToProps = (state: State) => ({
  lang: state.page.lang,
  langList: state.page.data.site.languages,
});

function isArticleBase(arg: any): arg is ArticleBase {
  return 'abstract' in arg;
}

const getUrlWithLang = (link: string, lang: string) =>{
  if (link.indexOf(`/${lang.toLowerCase()}/`) === -1 ){
     return `${link.slice(0, link.indexOf('/articles/') + '/articles/'.length)}${lang.toLowerCase()}/${link.slice(link.indexOf('/articles/') + '/articles/'.length)}`;
  } else {
    return link;
  }
}

type Props = {
  article: ArticleBase | Office;
  ratio?: number;
  isCompact: boolean;
  item?: string;
  setIsModalOpen?: (boolean) => void;
  setModalContent?: Dispatch<SetStateAction<ReactNode[] | null>>;
  renderArticleContent?: (path: string | null) => Promise<ReactNode[] | null>;
  overlay?: any;
  alt?: string;
  itemsCount?: number;
  index?: number;
  lang: string | undefined;
  langList: string[] | undefined;
  type?: PageBlockType;
};

const CreativeMediaArticle = ({
  article,
  isCompact,
  overlay,
  ratio = 169,
  renderArticleContent,
  setIsModalOpen,
  setModalContent,
  alt,
  itemsCount,
  index,
  lang,
  langList,
  type
}: Props): JSX.Element => {
  const imgInfo = getMainMedia(article.mainMedia || article.media, true);
  let link = getLinkFromData(article);
  const ariaAttributes = typeof index === 'number' && {
    role: 'group',
    'aria-roledescription': 'slide',
    'aria-label': `Slide ${index + 1} sur ${itemsCount}`,
  };

  const openModal = useCallback(() => {
    if (setModalContent && setIsModalOpen && renderArticleContent) {
      setIsModalOpen(true);

      // this change link to translated url for translated articles only
      if (langList && lang) link = getUrlWithLang(link,lang);

      renderArticleContent(link).then((content) => setModalContent(content));
      
      return window.history.replaceState(null, article.title, link);
    }
    return null;
  }, [article.title, link, renderArticleContent, setIsModalOpen, setModalContent]);

  return (
    <div className={classNames('creative-media-article')} {...ariaAttributes}>
      {article && (
        <CustomLink onKeyPress={openModal} className={classNames('Slider__Item', { overlay })} {...(isCompact ? { to: link } : { onClick: openModal })}>
          <Item
            image={imgInfo.src}
            linkLabel={isArticleBase(article) ? article.abstract : ''}
            ratio={ratio}
            subtitle={article.subtitle}
            title={article.title}
            altImage={alt}
            id={article.id}
            type={type}
          />
        </CustomLink>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(CreativeMediaArticle);

