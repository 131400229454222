import React, { FunctionComponent } from 'react';
import { getBrandLogoUrl } from '../../../../service/brand-logo.service';

type Props = {
  brand: string;
  link: string;
  locale: string | string[];
  isActive: boolean;
  hiddenDiv: HTMLElement | null,
};

const HomeLink: FunctionComponent<Props> = ({ brand = 'canalGroup', locale, link = '', isActive = false, hiddenDiv }) => {
  const CustomTag = isActive ? 'h1' : 'div';

  return (
    <CustomTag>
      <a href={link} id="home-link" >
        <img src={getBrandLogoUrl(brand)} alt="groupe canal" />
      </a>
    </CustomTag>
  );
};

export default HomeLink;
