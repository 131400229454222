import React from 'react';
import classNames from 'classnames';
import Title from '../Title';
import { ContentTitle } from 'src/typings/components/content';

const getClassNameFrom = (type: string) => {
  // due to a limitation in enum type, we cannot use FragmentType.FRAGMENT_TYPE_MAIN_TITLE
  // as after compile, this value is converted to a number(ordered), not a string
  switch (type) {
    case 'canalplus.creativemedia.fragment.main_title':
      return 'article__title';
    case 'canalplus.block.title':
      return 'blockTitle';
    default:
      return '';
  }
};

const TitleBlock = ({ content, type, className }: { content: ContentTitle; type: string; className?: string }) => {
  const title = content.title || content.text || '';
  return <Title title={title} className={classNames(className, getClassNameFrom(type))} />;
};

export default TitleBlock;
