/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import Logger from 'loggerHelper';
import { setError } from './error';

import { RootAction } from '../../typings/rootActions';
import { SearchResult } from '../../typings/components/searchResult';
import { WindowDimension } from '../../typings/state/pageState';
import { PageResult } from '../../typings/components/pageResult';

import StructureApi from '../api/StructureApi';
import { searchNextReturned, toggleDisplay } from './search';
import { getLangFromUrl, getPath } from '../helpers/uriHelper';
import { PageData } from '../../typings/components/page';
import { State } from '../../typings/state/state';
import { formatApiPageData, formatApiSearchResult } from '../api/parsing/PageParsing';

declare let IS_SERVER: boolean;

function fetching(path: string): RootAction {
  return { type: 'FETCHING_PAGE', payload: path };
}

function fetchingNext(path: string, blockId: number): RootAction {
  return { type: 'FETCHING_NEXT_PAGE', payload: { path, blockId } };
}

function fetched(page: PageResult): ThunkAction<void, State, { uniqueId?: string }, RootAction> {
  return (dispatch) => {
    // close search if opened
    dispatch(toggleDisplay(false));
    dispatch({ type: 'FETCHED_PAGE', payload: page });
  };
}

function fetchedNext(page: PageResult, blockId: number): ThunkAction<void, State, { uniqueId?: string }, RootAction> {
  return (dispatch) => {
    // close search if opened
    dispatch(toggleDisplay(false));
    dispatch({ type: 'FETCHED_NEXT_PAGE', payload: { page, blockId } });
  };
}

function fetchedRedirection(page: PageResult): ThunkAction<void, State, { uniqueId?: string }, RootAction> {
  return (dispatch) => {
    // close search if opened
    dispatch(toggleDisplay(false));
    dispatch({ type: 'FETCHED_REDIRECTION', payload: page });
  };
}

export function fetch(
  path: string,
  blockId?: number,
  isSearch = false,
  forceLang?: string,
  langList?: string[],
): ThunkAction<Promise<void>, State, { uniqueId?: string }, RootAction> {
  let pathUrl = path;
  return async (dispatch, getState, { uniqueId }) => {
    if (!isSearch) {
      dispatch(blockId ? fetchingNext(pathUrl, blockId) : fetching(pathUrl));
    }

    let errorMessage;
    let response;

    const startAt = new Date().getTime();

    const options: { uniqueId?: string; token?: string; lang?: string } = {
      uniqueId,
    };

    // Add authorization token if we have one
    const { token } = getState().authentication;
    let { lang } = getState().page;

    if (token) {
      options.token = token;
    }

    // Find the lang in the url path and update the lang option for the Structure.api request
    if (langList) {
      lang = getLangFromUrl(path, langList);

      if (lang && path.endsWith(`/${lang.toLowerCase()}`)) {
        pathUrl = '/';
      }
    }

    if (forceLang || lang) {
      options.lang = forceLang || lang;
    }

    try {
      response = await StructureApi.fetchPage(pathUrl, options);
    } catch (error) {
      const logMetadata = Logger.generateApiMetadata('structure', 'fetchPage', {
        uniqueId,
        startAt,
      });
      errorMessage = `Failed to get a readable response from api: ${error.message}`;
      Logger.error(errorMessage, logMetadata);

      // Trigger an error
      dispatch(setError(errorMessage, 500));
      return;
    }

    const { status } = response;
    const logMetadata = Logger.generateApiMetadata('structure', 'fetchPage', {
      uniqueId,
      status,
      startAt,
    });

    // Check HTTP status validity
    if (status !== 200) {
      errorMessage = `Api response is unsuccessful: ${response.status} ${response.statusText}`;
      Logger.error(errorMessage, logMetadata);

      // Trigger an error
      dispatch(setError(errorMessage, 500));
      return;
    }

    // statusCode variable holds the page status code and NOT the response status code
    const { statusCode } = response.data;

    // Check response data validity
    if (!statusCode) {
      errorMessage = `Api response could not be understood: ${response.status} ${response.statusText}`;
      Logger.error(errorMessage, logMetadata);

      // Trigger an error
      dispatch(setError(errorMessage, 500));
      return;
    }

    // Handle redirects
    if (statusCode === 301 || statusCode === 302) {
      const page: PageResult = { path: pathUrl, data: formatApiPageData(response.data) };

      Logger.info('Fetched redirection from structure api', logMetadata);

      if (IS_SERVER) {
        dispatch(fetchedRedirection(page));
        return;
      }

      const redirectPath = getPath(response.data.headers.Location);

      dispatch(fetch(redirectPath));
      return;
    }

    // Dispatch corresponding Search or Fetch actions
    if (isSearch) {
      const result: SearchResult = formatApiSearchResult(response.data);
      dispatch(searchNextReturned(blockId as number, result));
    } else {
      const page: PageResult = { path: pathUrl, data: formatApiPageData(response.data) };
      dispatch(blockId ? fetchedNext(page, blockId) : fetched(page));
    }

    Logger.info('Fetched page from structure api', logMetadata);
  };
}

export const fetchAgain =
  (forceLang?: string): ThunkAction<Promise<void>, State, {}, RootAction> =>
  async (dispatch: ThunkDispatch<State, {}, RootAction>, getState: () => State) => {
    const { path } = getState().page;
    dispatch(fetch(path, undefined, false, forceLang));
  };

export function setDevice(device: string): RootAction {
  return { type: 'SET_DEVICE', payload: device };
}

export function scrollTo(valueTop: number, valueLeft = 0): RootAction {
  // Ensure window object is available
  if (window && window.scroll) {
    window.scroll(valueTop, valueLeft);
  }

  return { type: 'SCROLL_TO' };
}

export function resize(window: WindowDimension): RootAction {
  return { type: 'RESIZE_CHANGE', payload: window };
}

export function openPopup(url: string, id: string, windowSize: any, w: number, h: number): RootAction {
  // Ensure window object is available
  if (window && window.open) {
    window.open(
      url,
      id,
      `height=${h}, width=${w}, top=${windowSize.height / 2 - h / 2}, left=${
        windowSize.width / 2 - w / 2
      }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
    );
  }

  return { type: 'OPEN_POPUP' };
}

export function openNavHeader(): RootAction {
  return { type: 'OPEN_NAV_HEADER' };
}

export function closeNavHeader(): RootAction {
  return { type: 'CLOSE_NAV_HEADER' };
}

// used by search actions to eventually update tracking block
export function updateBlock(data: PageData, blockId: number): RootAction {
  return { type: 'UPDATE_BLOCK', payload: { data, blockId } };
}

export function updateFooterHeight(height: number): RootAction {
  return { type: 'FOOTER_HEIGHT_CHANGE', payload: height };
}

export function setLang(lang: string): RootAction {
  return { type: 'SET_LANG', payload: lang };
}
