import React from 'react';
import classNames from 'classnames';
import List from '../List';
import NavigationItem from './NavigationItem';
import { getLinkHref } from '../../../helpers/uriHelper';
import { ContentNavigation } from 'src/typings/components/content';
import { MenuItem } from 'src/typings/components/navigation';
import { PageType } from 'src/typings/constants/pageTypes';

type Props = {
  content: ContentNavigation;
  className: string;
  isCompact: boolean;
};

type State = {
  subnavOpened: number | null;
};

/**
 * Navigation component
 * @param content Navigation data object
 * @param className optional css class
 * @constructor
 */

class Navigation extends React.Component<Props, State> {
  static defaultProps = {
    content: {
      menu: [],
    },
    className: '',
    isCompact: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      subnavOpened: null,
    };
  }

  onMainItemClick = (index: number) => () => {
    this.setState({
      subnavOpened: index,
    });
  };

  getMenuItem = (item, i) => {
    const { className, isCompact } = this.props;
    const { subnavOpened } = this.state;

    return (
      <>
        <NavigationItem
          title={item.title}
          uri={getLinkHref(item.links[0])}
          disableLink={item.children && item.children.length > 0 && isCompact}
          onClick={this.onMainItemClick(i)}
          media={item.media}
        />
        {item.children && item.children.length > 0 && (
          <List
            className={classNames(className, 'navigation', {
              opened: subnavOpened === i,
            })}
          >
            {item.children.map((child) => (
              <NavigationItem
                key={`${child.title}`}
                title={child.title}
                uri={getLinkHref(child.links[0])}
                disableLink={false}
                media={child.media}
              />
            ))}
          </List>
        )}
      </>
    );
  };

  render() {
    const { className, content } = this.props;
    const menuCategory = content.menu.filter((item: MenuItem) => item.pageType === PageType.CATEGORY);
    const menuOthers = content.menu.filter((item: MenuItem) => item.pageType !== PageType.CATEGORY);

    return (
      <>
        {menuCategory.length > 0 && (
          <List className={classNames(className, 'navigation', 'navigation_category')}>
            {menuCategory.map((item, i) => (
              <div key={`${item.title}`}>{this.getMenuItem(item, i)}</div>
            ))}
          </List>
        )}
        {menuOthers.length > 0 && (
          <List className={classNames(className, 'navigation', 'navigation_default')}>
            {menuOthers.map((item, i) => (
              <div key={`${item.title}`}>{this.getMenuItem(item, i)}</div>
            ))}
          </List>
        )}
      </>
    );
  }
}

export default Navigation;
