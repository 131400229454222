/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Component } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { getSliderMEAConfig } from '../../../helpers/sliderHelper';
import MEA from '../MEA/MEA';
import { ContentMeaCarousel } from 'src/typings/components/content';

type Props = {
  content: ContentMeaCarousel;
  isCompact: boolean;
};
type MeaCarouselState = {
  controlItemPos: Array<string>;
  currentIndexSelected: number;
};

class MeaCarousel extends Component<Props, MeaCarouselState> {
  slider: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      controlItemPos: [],
      currentIndexSelected: 0,
    };
  }

  componentDidMount() {
    this.setPosControlItems(this.state.currentIndexSelected);
  }

  setPosControlItems = (currentIndexSelected: number) => {
    let controlItemPos: Array<string> = [];
    if (this.props.content) {
      let count: number = 1;
      const max: number = this.props.content.columns.length;

      controlItemPos = this.props.content.columns.map((col, i) => {
        let val: string;
        if (i === currentIndexSelected) {
          val = '50%';
          count = 0;
        } else if (i < currentIndexSelected) {
          val = (40 / (currentIndexSelected + 1)) * count + '%';
        } else {
          val = 60 + (40 / (max + 1 - (currentIndexSelected + 1))) * count + '%';
        }
        count++;
        return val;
      });
    }

    this.setState({ controlItemPos, currentIndexSelected });
  };

  onSliderChange = (oldIndex: number, newIndex: number) => {
    this.setPosControlItems(newIndex);
  };

  onClickControlItem = (index: number) => {
    if (this.slider) {
      this.slider.slickGoTo(index);
    }
  };

  render() {
    const { isCompact, content } = this.props;
    const { slideTime, columns } = content;

    const settingsSlider: any = getSliderMEAConfig(slideTime);
    settingsSlider.beforeChange = this.onSliderChange;

    return (
      <div className={classNames('mea-carousel')}>
        <Slider {...settingsSlider} ref={(slider) => (this.slider = slider)}>
          {columns.map((col) => {
            if (col.article) {
              return (
                <div className="Slider__ItemWrapper" key={col.article.id}>
                  <MEA isCompact={isCompact} content={col} />
                </div>
              );
            }
            return null;
          })}
        </Slider>
      </div>
    );
  }
}

export default MeaCarousel;
