import DefaultBrand from '../assets/svg-icons/app-logo-canal.svg';
import CloseIcon from '../assets/icons/cross.svg';
import Dailymotion from '../assets/svg-icons/dailymotion.svg';
import Facebook from '../assets/svg-icons/facebook.svg';
import Twitter from '../assets/svg-icons/twitter.svg';
import Linkedin from '../assets/svg-icons/linkedin.svg';

const BRANDS = {
  canalGroup: DefaultBrand,
  canalgroupEn: DefaultBrand,
  CloseIcon,
  Dailymotion,
  Facebook,
  Twitter,
  Linkedin,
};

export const getBrandLogoUrl = (brand: string) => BRANDS[brand] || DefaultBrand;
