import _ from 'lodash';
import classnames from 'classnames';
import React, { useEffect, useState, useCallback, useRef, Fragment, MutableRefObject } from 'react';

import ArrowLogo from '../../../assets/icons/arrow.svg';

type Props = {
  items?: JSX.Element[];
  arrowPosition?: string;
  currentIndexCallback?: (newIndex: number) => void;
  scrollFullRow: boolean;
};

type SideType = 'left' | 'right';
type DirectionType = { direction: SideType };

export default function HorizontalScrollWithArrows({
  items = [],
  arrowPosition = '50%',
  currentIndexCallback = () => null,
  scrollFullRow = false,
}: Props): JSX.Element {
  const [scrollAmount, setScrollAmount] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(false);

  const scroller = useRef() as MutableRefObject<HTMLInputElement>;
  const numberOfItems = items.length;

  const onClickUpdate = useCallback(
    (direction: SideType) => {
      if (numberOfItems > 0) {
        const itemWidth = _.get(scroller, 'current.scrollWidth') / numberOfItems;

        const moveAmount = scrollFullRow ? _.get(scroller, 'current.offsetWidth') : itemWidth;

        scroller.current.scrollLeft += (direction === 'left' ? -1 : 1) * moveAmount;
      }
    },
    [numberOfItems, scrollFullRow],
  );

  const onScrollUpdate = useCallback(() => {
    const itemWidth = _.get(scroller, 'current.scrollWidth') / numberOfItems;
    const scrollLeft = _.get(scroller, 'current.scrollLeft');

    if (scrollLeft !== scrollAmount) {
      setScrollAmount(scrollLeft);

      const getIndex = (scroll) => Math.min(numberOfItems - 1, Math.max(0, Math.round(scroll / itemWidth)));

      const scrollIndex = getIndex(scrollAmount);
      const newIndex = getIndex(scrollLeft);

      if (newIndex !== scrollIndex) {
        currentIndexCallback(newIndex);
      }
    }
  }, [currentIndexCallback, numberOfItems, scrollAmount]);

  useEffect(() => setShouldScroll(_.get(scroller, 'current.offsetWidth') < _.get(scroller, 'current.scrollWidth')), [scroller]);

  const Arrow = ({ direction }: DirectionType): JSX.Element | null => {
    const isLeft = direction === 'left';

    const isOnEdge = isLeft
      ? scrollAmount <= 0
      : scrollAmount >= _.get(scroller, 'current.scrollWidth') - _.get(scroller, 'current.offsetWidth');

    return shouldScroll && !isOnEdge ? (
      <button
        type="button"
        className={classnames('horizontal-scroll-with-arrows__arrow', direction)}
        style={{ top: arrowPosition }}
        onClick={() => onClickUpdate(direction)}
        aria-label={isLeft ? 'previous' : 'next'}
      >
        <img src={ArrowLogo} alt={isLeft ? 'précedent' : 'suivant'} className="arrow" />
      </button>
    ) : null;
  };

  return (
    items && (
      <div className="horizontal-scroll-with-arrows" role="group" aria-roledescription="carousel" aria-atomic="false" aria-live="polite">
        <Arrow direction="left" />
        <div onScroll={_.debounce(onScrollUpdate, 50)} ref={scroller} className="horizontal-scroll-with-arrows__items-wrapper">
          <div className="horizontal-scroll-with-arrows__items-wrapper__side" />
          <div className="horizontal-scroll-with-arrows__items-wrapper__items">
            {items.map((item, index) => (
              <Fragment key={`scrollItem_${index}`}>{item}</Fragment>
            ))}
          </div>
          <div className="horizontal-scroll-with-arrows__items-wrapper__side" />
        </div>
        <Arrow direction="right" />
      </div>
    )
  );
}
