import React from 'react';
import { connect } from 'react-redux';

import SocialHeaderMenu from '../../presentational/SocialHeaderMenu';
import SocialLinksBar from '../../presentational/SocialLinksBar';
import { ContainerType } from '../../../../typings/constants/containerTypes';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { State } from '../../../../typings/state/state';

const mapStateToProps = (state: State) => ({
  elementsContent: state.page.data.blocks[ContainerType.CONTENT],
  elementsHeader: state.page.data.blocks[ContainerType.HEADER],
});

const SocialMediaContainer = ({ elementsContent, elementsHeader }): JSX.Element | null => {
  if (elementsContent?.length) {
    const [links] = [...elementsContent, ...elementsHeader].filter((element) => element.type === PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS);

    if (links?.content) {
      const isSocialMediaHeader = links && links?.container === ContainerType.HEADER;
      const { content } = links;

      if (isSocialMediaHeader) {
        return <SocialHeaderMenu content={content} />;
      }

      return <SocialLinksBar socialsLinks={content} />;
    }
  }

  return null;
};

export default connect(mapStateToProps)(SocialMediaContainer);
