import React from 'react';
import classNames from 'classnames';
import { getLinkFromData } from '../../../helpers/uriHelper';
import { ContentIframe } from 'src/typings/components/content';

/**
 * IFrame component
 * @param {string} url Source of the iframe
 * @constructor
 */
const IFrame = ({ content, className }: { content: ContentIframe; className?: string }) => {
  const url: string = getLinkFromData(content);
  const width: number = content.width ? Math.floor(content.width) : -1;
  const height: number = content.height ? Math.floor(content.height) : -1;

  if (width > -1 && height > -1) {
    return (
      <iframe
        title="iframeWithHeightAndWidth"
        style={{ width: `${width}px`, height: `${height}px` }}
        src={url}
        className={classNames(className, 'frame')}
      />
    );
  }
  return <iframe title="iframe" src={url} className={classNames(className, 'frame')} />;
};

export default IFrame;
