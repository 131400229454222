/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import { ArticleBase } from '../../../../typings/components/articleBase';
import { getClientHeight } from '../../../helpers/htmlHelper';
import { getMainMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import CustomLink from '../../container/CustomLink';
import LazyLoader from '../LazyLoader';
import TruncateTitle from '../TruncateTitle';
import { formatDateDetour } from 'src/shared/helpers/dateHelper';
import { ArticleListTypes } from 'src/typings/constants/articleListTypes';
import ConfigHelper from 'configHelper';

/**
 * SliderItem component
 * @param onClick
 * @param overlay
 * @param abstractExposed
 * @param displayTags
 * @param heightImg
 * @param setHeightImg
 * @constructor
 */

type Props = {
  setHeightImg: (height: number) => void;
  heightImg: number;
  displayTags: boolean;
  abstractExposed: boolean;
  overlay: boolean;
  onClick?: (e: any) => void;
  article: ArticleBase;
  isCompact: boolean;
  className?: string;
  template?: ArticleListTypes;
};

const HIDE_DATE_SITES = ['goodgame'];
const hideDate = !HIDE_DATE_SITES.find((element) => ConfigHelper.getPublicConfig().identity.includes(element));

class SliderItemDetour extends React.Component<Props> {
  imgElement: any;

  componentDidMount() {
    window.addEventListener('resize', debounce(this.onResize, 50), false);
    window.addEventListener('orientationchange', this.orientationChange, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.orientationChange);
  }

  // onResize get height images slider to position arrows
  onResize = () => {
    const heightImg = getClientHeight(this.imgElement);
    if (heightImg > 0 && heightImg !== this.props.heightImg) {
      this.props.setHeightImg(heightImg);
    }
  };

  orientationChange = () => {
    setTimeout(() => {
      const heightImg = getClientHeight(this.imgElement);
      if (heightImg > 0 && heightImg !== this.props.heightImg) {
        this.props.setHeightImg(heightImg);
      }
    }, 250);
  };
  bindImg = (element: React.ReactNode) => {
    this.imgElement = element;
    // if images are uploaded get height images slider to position arrows
    setTimeout(() => {
      const heightImg = getClientHeight(this.imgElement);
      if (heightImg > 0 && heightImg !== this.props.heightImg) {
        this.props.setHeightImg(heightImg);
      }
    }, 2000);
  };

  render() {
    const { article, onClick, overlay, isCompact, className, template } = this.props;
    const imgInfo = getMainMedia(article.mainMedia, isCompact);
    const title = get(article, 'title', '');
    const articleLink = getLinkFromData(article);

    return (
      <CustomLink
        className={classNames(
          'article-item',
          'sliderItemDetour',
          { overlay, 'sliderItemDetour--video': article.video !== undefined && template !== ArticleListTypes.PORTRAIT },
          { 'sliderItemDetour--portrait': template === ArticleListTypes.PORTRAIT },
          className,
        )}
        to={articleLink}
        onClick={onClick}
      >
        <div className="sliderItemDetour__image">
          <LazyLoader offset={100} height={230}>
            <img src={imgInfo.src} alt={imgInfo.description || ''} ref={this.bindImg} />
          </LazyLoader>
        </div>
        <div className="sliderItemDetour__Container">
          <TruncateTitle className="sliderItemDetour__Title" line={3} title={title} />
          <div className="sliderItemDetour__Desc">
            {template === ArticleListTypes.PORTRAIT ? (
              <p className="sliderItemDetour__Portrait">{article.abstract}</p>
            ) : (
              <>
                <p className="sliderItemDetour__Category">{article.category.name}</p>
                {hideDate && <p className="sliderItemDetour__Published">{formatDateDetour(new Date(article.publishedAt))}</p>}
              </>
            )}
          </div>
        </div>
      </CustomLink>
    );
  }
}

export default SliderItemDetour;
