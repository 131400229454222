/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';
import GalacticHeader from '../../presentational/GalacticHeader';
/**
 * Connected container for GalacticMenu component
 */

const mapStateToProps = (state: State) => ({
  langsAvailable: state.page.langsAvailable,
});

const GalacticHeaderContainer = connect(mapStateToProps)(GalacticHeader);

export default GalacticHeaderContainer;
