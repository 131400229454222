/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/**
 *
 * @param element
 * @returns {number}
 */
export const getClientHeight = (element) => {
  if (!element) {
    return null;
  }
  return element.clientHeight;
};

export const removeScriptHtml = (text: string): string =>
  text.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '').replace('javascript:', ''); // eslint-disable-line
