import React from 'react';
import classNames from 'classnames';
import { ContentRaw } from 'src/typings/components/content';
import { executeScriptFromHtml } from 'src/shared/helpers/scriptHelper';

type Props = {
  content: ContentRaw;
  className?: string;
};

/**
 * Raw component for handling responsive images
 * @constructor
 */
class Raw extends React.Component<Props> {
  componentDidMount() {
    const { content } = this.props;
    executeScriptFromHtml(content.text, true);
  }

  render() {
    const { content, className } = this.props;
    return <div className={classNames(className || '', 'raw')} dangerouslySetInnerHTML={{ __html: content.text }} />;
  }
}

export default Raw;
