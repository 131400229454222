/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Logger from 'loggerHelper';
import { ThunkAction } from 'redux-thunk';
import { RootAction } from '../../typings/rootActions';
import { State } from '../../typings/state/state';
import InternalApi from './../api/InternalApi';
import { getJwtUserData, deleteToken } from '../helpers/jwtHelper';
import { fetchAgain } from './page';

function loginInProgress(): RootAction {
  return { type: 'LOGIN_IN_PROGRESS' };
}

function loginSuccessful(username: string, token: string): RootAction {
  return { type: 'LOGIN_SUCCESSFUL', username, token };
}

export function loginFromCookie(username: string, token: string): RootAction {
  return { type: 'LOGIN_FROM_COOKIE', username, token };
}

function loginFailed(error: string): RootAction {
  return { type: 'LOGIN_FAILED', error };
}

/**
 * Login with the given credentials
 *
 * @param {string} url
 * @param {string} username
 * @param {string} password
 * @param {boolean} useRememberMe
 */
export function login(
  url: string,
  username: string,
  password: string,
  useRememberMe?: boolean,
): ThunkAction<Promise<void>, State, { uniqueId?: string }, RootAction> {
  return async (dispatch, getState, { uniqueId }) => {
    Logger.debug(`Login with username ${username}`);

    dispatch(loginInProgress());

    if (username === '' || password === '') {
      dispatch(loginFailed("Merci de saisir un nom d'utilisateur et un mot de passe"));
      return;
    }

    let errorMessage;

    const startAt = new Date().getTime();

    try {
      await InternalApi.login(url, username, password, { uniqueId });
    } catch (error) {
      if (InternalApi.isCancelError(error)) {
        const logMetadata = Logger.generateApiMetadata('internal', 'login', {
          uniqueId,
          startAt,
        });
        errorMessage = `Login was canceled: ${error.message}`;
        Logger.debug(errorMessage, logMetadata);

        return;
      }

      const logMetadata = Logger.generateApiMetadata('internal', 'login', {
        uniqueId,
        startAt,
      });
      errorMessage = `Failed to get a readable response from api: ${error.message}`;
      Logger.error(errorMessage, logMetadata);

      // Trigger an error
      dispatch(loginFailed('La connexion a échoué.'));
      return;
    }

    // The InternalApi.login() call should have set a cookie with the JWT token
    const userData = getJwtUserData();

    if (!userData) {
      const logMetadata = Logger.generateApiMetadata('internal', 'login', {
        uniqueId,
        startAt,
      });
      errorMessage = 'Invalid JWT token extracted from cookie';
      Logger.error(errorMessage, logMetadata);

      dispatch(loginFailed('La connexion a échoué'));
      return;
    }

    Logger.debug(`Logged in as ${username}`);
    dispatch(loginSuccessful(userData.username, userData.token));

    if (!useRememberMe) {
      Logger.debug(`Deleting token cookie as no remember me asked`);
      deleteToken();
    }

    // Now we are logged, fetch again the page
    // $FlowFixMe
    dispatch(fetchAgain());
  };
}
