import React from 'react';
import Modal from '../Modal/Modal';
import Heading from '../Heading';

type Props = {
  transcription: string | null | undefined;
  closeTranscriptionModal: () => void;
  isTranscriptionModalOpen: boolean;
  label?: string;
};

export function TranscriptionModal({ closeTranscriptionModal, isTranscriptionModalOpen, transcription, label }: Props) {
  return (
    <Modal closeModal={closeTranscriptionModal} isModalOpen={isTranscriptionModalOpen}>
      <div className="block canalplus-block-dynamic-article">
        <div className="article">
          <div className="article__content">
            <Heading level={1} className="title article__fragment article__title">
              {label}
            </Heading>
          </div>
          {transcription &&
            <div className=" article__fragment text description" dangerouslySetInnerHTML={{ __html: transcription }} />
          }
        </div>
      </div>
    </Modal>
  )
}
