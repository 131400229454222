/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import Login from '../../presentational/Login';
import { State } from '../../../../typings/state/state';
import { login } from '../../../actions/authentication';

/**
 * LoginContainer Component
 */
const mapStateToProps = (state: State) => ({
  error: state.authentication.error,
});

const mapDispatchToProps = (dispatch: Function) => ({
  login: (url: string, username: string, password: string, useRememberMe: boolean) => {
    dispatch(login(url, username, password, useRememberMe));
  },
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
