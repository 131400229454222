/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import axios from 'axios';
import ConfigHelper from 'configHelper';

export const HttpClient = axios.create({
  baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
  timeout: ConfigHelper.getClientConfig('api.structure.timeout'),
  headers: {
    'X-MINISITE-DOMAIN': ConfigHelper.getClientConfig('identity'),
    'X-UNIQUE-ID': '-',
    'X-API-VERSION': ConfigHelper.getClientConfig('api.structure.version'),
  },
});

export const InternalApiClient = axios.create({
  timeout: ConfigHelper.getClientConfig('api.internal.timeout'),
  headers: {
    'X-UNIQUE-ID': '-',
  },
});

/**
 * Return a cancel token
 *
 * @returns {executor}
 */
export function getCancelToken() {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  return source.token;
}

export const { isCancel } = axios;
