import classnames from 'classnames';
import React from 'react';
import LazyLoad from 'react-lazy-load';

import { canUseDOM } from 'src/shared/helpers/windowHelper';
import { getBrandLogoUrl } from 'src/shared/service/brand-logo.service';

type Props = {
  children?: React.ReactNode;
  offset?: number;
  classStyle?: string;
  pureStyle?: object;
  role?: string;
  draggable?: string;
  fitContent?: boolean;
  fitWidth?: boolean;
  fitHeight?: boolean;
  isModal?: boolean;
  brand?: string;
  alt?: string;
};

const LazyImage = ({
  children,
  offset = 200,
  classStyle,
  brand = '',
  alt,
  fitContent = false,
  fitWidth = false,
  fitHeight = false,
  isModal = false,
}: Props) => {
  const canLazyLoad = canUseDOM() && !isModal;

  const imageComponent = (
    <img
      src={getBrandLogoUrl(brand)}
      className={classnames(classStyle, {
        fitWidth,
        fitHeight,
        block: fitContent,
      })}
      alt={alt}
    />
  );

  if (!canLazyLoad) {
    return <> {children || imageComponent}</>;
  }

  return (
    <LazyLoad offset={offset} debounce={false}>
      {children || imageComponent}
    </LazyLoad>
  );
};

export default LazyImage;
