import React from 'react';
import classNames from 'classnames';
import { getHeaderMedia } from '../../../helpers/imageHelper';
import Picture from '../Picture';
import { ContentGalacticHeaderElement } from 'src/typings/components/content';
import TemplateGalacticHeaderConst from 'src/typings/constants/templateGalacticHeaderConst';
import CustomLink from '../../container/CustomLink';

type Props = {
  appTitle: string;
  template: TemplateGalacticHeaderConst;
  menu: {
    elements: ContentGalacticHeaderElement[];
  };
  galacticMenuOpen: boolean;
  toggleGalacticHeader: (e: React.SyntheticEvent) => void;
};

class GalacticMenu extends React.PureComponent<Props> {
  render() {
    const { appTitle, template, menu, galacticMenuOpen, toggleGalacticHeader } = this.props;
    return (
      <div className={classNames('GalacticMenu')}>
        {menu.elements.length && (
          <>
            <button type="button" aria-expanded={galacticMenuOpen} onClick={toggleGalacticHeader}>
              <span className="GalacticMenu__label">MÉDIAS</span>
              <span className="GalacticSeparator" />
              {template === TemplateGalacticHeaderConst.DEFAULT && <span className="GalacticMenu__siteName">{appTitle}</span>}
              {template === TemplateGalacticHeaderConst.PICTURE_DROP_MENU && (
                <Picture
                  className="GalacticMenu__picture"
                  images={menu.elements[0].media ? [getHeaderMedia(menu.elements[0].media).src] : []}
                />
              )}
            </button>
            <div
              className={classNames('GalacticMenu__panel', {
                'GalacticMenu__panel--open': galacticMenuOpen,
                'GalacticMenu__panel--close': !galacticMenuOpen,
              })}
            >
              <ul>
                {menu.elements.map((element, index) => (
                  <li key={index}>
                    <CustomLink className="GalacticMenu__element" to={element.links.href} key={element.links.href}>
                      <Picture
                        images={element.media ? [getHeaderMedia(element.media).src] : []}
                        alt={element.media?.alt ? element.media?.alt : element.media?.description || ''}
                      />
                    </CustomLink>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default GalacticMenu;
