import React from 'react';
import LazyLoad from 'react-lazyload';
import ConfigHelper from 'configHelper';

type Props = {
  offset: number;
  children: React.ReactNode;
  height: number;
  disableLazyLoad?: boolean;
};

class LazyLoader extends React.PureComponent<Props> {
  render() {
    const { offset, children, height, disableLazyLoad } = this.props;

    const useLazyLoader = !disableLazyLoad && ConfigHelper.getClientConfig('feature.useLazyLoader');
    if (useLazyLoader) {
      return (
        <LazyLoad offset={offset} height={height}>
          {children}
        </LazyLoad>
      );
    }
    return <div>{children}</div>;
  }
}

export default LazyLoader;
