/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import uuid from 'uuid';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers';
import { State } from '../../typings/state/state';
import { WindowApp } from 'src/typings/global';

/**
 * Configure the store
 *
 * @param initialState Initial state
 * @param nonce        Unique ID of the request
 * @returns {*}
 */

function configureStore({ initialState, nonce }: { initialState?: State; nonce?: string }) {
  const uniqueId = nonce || uuid();

  // Redux Dev Tools store enhancer.
  // @see https://github.com/zalmoxisus/redux-devtools-extension
  // We only want this enhancer enabled for development and when in a browser
  // with the extension installed.
  const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
    typeof window === 'object' &&
    (window as unknown as WindowApp).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as unknown as WindowApp).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify here name, actionsBlacklist, actionsCreators and other options
        })
      : compose;

  const enhancers = composeEnhancers(
    // Middleware store enhancer.
    applyMiddleware(
      // Initialising redux-thunk with extra arguments will pass the below
      // arguments to all the redux-thunk actions.
      // @see https://github.com/gaearon/redux-thunk
      thunk.withExtraArgument({ uniqueId }),
    ),
    // other store enhancers if any
  );

  const store = initialState ? createStore(reducer, initialState, enhancers) : createStore(reducer, enhancers);

  if (process.env.NODE_ENV === 'development' && (module as any).hot) {
    // Enable Webpack hot module replacement for reducers. This is so that we
    // don't lose all of our current application state during hot reloading.
    (module as any).hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore;
