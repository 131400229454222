import React from 'react';
import classNames from 'classnames';
import { FragmentDate } from 'src/typings/components/fragment';
import { getLocaleDateString } from 'src/shared/helpers/dateHelper';

type Props = {
  content: FragmentDate;
  className?: string;
};

/**
 * DateFragment component
 */
const DateFragment = (props: Props) => {
  const { className, content } = props;
  return (
    <p className={classNames(className, 'article__date')}>
      {content.date ? getLocaleDateString(content.date, undefined, { year: 'numeric', month: 'long', day: 'numeric' }) : ''}
    </p>
  );
};

export default DateFragment;
