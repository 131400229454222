export enum FragmentType {
  FRAGMENT_TYPE_AUTHOR = 'canalplus.creativemedia.fragment.author',
  FRAGMENT_TYPE_DATE = 'canalplus.creativemedia.fragment.publication_date',
  FRAGMENT_TYPE_DOWNLOAD = 'canalplus.creativemedia.fragment.download',
  FRAGMENT_TYPE_IMAGE = 'canalplus.creativemedia.fragment.image',
  FRAGMENT_TYPE_IFRAME = 'canalplus.creativemedia.fragment.iframe',
  FRAGMENT_TYPE_INTRO = 'canalplus.creativemedia.fragment.introduction',
  FRAGMENT_TYPE_MAIN_TITLE = 'canalplus.creativemedia.fragment.main_title',
  FRAGMENT_TYPE_MAIN_IMAGE = 'canalplus.creativemedia.fragment.main_image',
  FRAGMENT_TYPE_QUOTE = 'canalplus.creativemedia.fragment.quote',
  FRAGMENT_TYPE_RAW = 'canalplus.creativemedia.fragment.raw',
  FRAGMENT_TYPE_RELATED_ARTICLES = 'canalplus.creativemedia.fragment.related_articles',
  FRAGMENT_TYPE_SOCIAL_NETWORKS = 'canalplus.creativemedia.fragment.sharing',
  FRAGMENT_TYPE_TAG = 'canalplus.creativemedia.fragment.tag',
  FRAGMENT_TYPE_TEXT = 'canalplus.creativemedia.fragment.text',
  FRAGMENT_TYPE_EMPTY_LINE = 'canalplus.creativemedia.fragment.empty_line',
  FRAGMENT_TYPE_TITLE = 'canalplus.creativemedia.fragment.title',
  FRAGMENT_TYPE_VIDEO = 'canalplus.creativemedia.fragment.video',
  FRAGMENT_TYPE_COMMENT = 'canalplus.creativemedia.fragment.comment',
  FRAGMENT_TYPE_MEDIA_LIST = 'canalplus.creativemedia.fragment.media_list',
  FRAGMENT_TYPE_ADVERTISING = 'canalplus.creativemedia.fragment.advertising',
}
