import { ArticleAuthor } from 'src/typings/components/articleBase';
import { CategoryContent, Category } from 'src/typings/components/category';
import { ContactElement } from 'src/typings/components/contactElement';
import { Flux, FluxFeed } from 'src/typings/components/content';
import { Link } from 'src/typings/components/link';
import { MainMedia } from 'src/typings/components/mainMedia';
import { Media, MediaItem } from 'src/typings/components/media';
import { MediaItemContent } from 'src/typings/components/mediaItem';
import { MenuItem } from 'src/typings/components/navigation';
import { PageMeta, MetaAttribute, PageScript, PageDataSite } from 'src/typings/components/page';
import { Sharing } from 'src/typings/components/sharing';
import { SocialNetworksBar, SocialNetworksMenu } from 'src/typings/constants/socialNetworks';
import { Tag } from 'src/typings/components/tag';
import { TrackerSettings, OmnitureTrackerSettings } from 'src/typings/components/tracking';
import { Video } from 'src/typings/components/video';

export const formatApiLink = (data: any): Link => ({
  rel: data.rel,
  href: data.href,
  translations: data.translations,
});

export const formatApiMetaAttribute = (data: any): MetaAttribute => ({
  attribute: data.attribute,
  value: data.value,
});

export const formatApiPageMeta = (data: any): PageMeta => {
  if (data.property) {
    return {
      property: data.property,
      content: data.content,
    };
  } else if (data.name) {
    return {
      name: data.name,
      content: data.content,
    };
  }

  const attributes: { [key: string]: MetaAttribute } = {};
  Object.keys(data.attributes).forEach((key) => {
    attributes[key] = formatApiMetaAttribute(data.attributes[key]);
  });
  return {
    attributes,
    content: data.content,
  };
};

export const formatApiPageScript = (data: any): PageScript => {
  if (data.raw) {
    return {
      type: data.type,
      raw: data.raw,
    };
  }

  return {
    url: data.url,
    attribute: data.attribute ? data.attribute : undefined,
  };
};

export const formatApiOmnitureTrackerSettings = (data: any): OmnitureTrackerSettings => ({
  reportsuite: data.reportsuite,
  server: data.server,
  server_secure: data.server_secure, // eslint-disable-line
});

export const formatApiTrackerSettings = (data: any): TrackerSettings => ({
  type: data.type,
  settings: formatApiOmnitureTrackerSettings(data.settings),
});

export const formatApiPageDataSite = (data: any): PageDataSite => ({
  locale: data.locale,
  title: data.title,
  logo: data.logo,
  tracker: data.tracker ? formatApiTrackerSettings(data.tracker) : undefined,
  languages: data.languages ? data.languages.map((lang: string) => lang.toUpperCase()) : undefined,
  translatable: data.translatable,
});

export const formatApiMedia = (data: any): Media => ({
  links: data.links ? data.links.map(formatApiLink) : [],
  filename: data.filename,
  contentType: data.contentType,
  size: data.size,
  description: data.description,
  copyright: data.copyright ? data.copyright : undefined,
  alt: data.alt,
});

export const formatApiMainMedia = (data: any): MainMedia => ({
  default: formatApiMedia(data.default),
  compact: data.compact ? formatApiMedia(data.compact) : undefined,
});

export const formatApiLogo = (data: any): Media => formatApiMedia(data);
export const formatApiSharing = (data: any): Sharing => ({
  twitter: data.twitter,
  facebook: data.facebook,
  tumblr: data.tumblr,
  google: data.google,
  mail: data.mail,
});
export const formatApiCategoryContent = (data: any): CategoryContent => ({
  name: data.name,
  slug: data.slug,
  links: data.links.map(formatApiLink),
});
export const formatApiCategory = (data: any): Category => ({
  category: formatApiCategoryContent(data.category),
  title: data.title,
  media: formatApiMedia(data.media),
  description: data.description,
});
export const formatApiArticleAuthor = (data: any): ArticleAuthor => ({
  name: data.name,
  username: data.username,
  email: data.email,
});
export const formatApiVideo = (data: any): Video => ({
  source: data.source,
  id: data.id,
  title: data.title,
  transcription: data.transcription,
  transcriptionTitle: data.transcriptionTitle,
});
export const formatApiTag = (data: any): Tag => ({
  name: data.name,
  links: data.links.map(formatApiLink),
});
export const formatApiMenuItem = (data: any): MenuItem => ({
  title: data.title,
  links: data.links.map(formatApiLink),
  id: data.id,
  pageType: data.page_type ? data.page_type : undefined,
  children: data.children ? data.children.map(formatApiMenuItem) : undefined,
  media: data.media ? formatApiMedia(data.media) : undefined,
});
export const formatApiContactElement = (data: any): ContactElement => ({
  media: data.media ? formatApiMedia(data.media) : undefined,
  type: data.type,
  title: data.title,
  text: data.text,
  buttonLabel: data.buttonLabel ? data.buttonLabel : undefined,
  links: data.links ? data.links.map(formatApiLink) : [],
  mailchimp: data.mailchimp ? data.mailchimp : undefined,
});

export const formatApiSocialNetworksMenu = (data: any): SocialNetworksMenu => {
  const ret = { label: data.label, items: {} };

  Object.keys(data.items).forEach((p: string): void => {
    ret.items[p] = { links: data.items[p].links.map(formatApiLink) };
  });

  return ret;
};

export const formatApiSocialNetworksBar = (data: any): SocialNetworksBar[] =>
  Object.entries(data).map((item: any) => ({ label: item[0], items: item[1].links }));

export const formatApiFluxFeed = (data: any): FluxFeed => ({
  type: data.type,
  apiId: data.apiId,
});

export const formatApiFlux = (data: any): Flux => data.map(formatApiFluxFeed);

export const formatApiMediaItem = (data: any): MediaItem => ({
  title: data.title,
  abstract: data.abstract,
  link: formatApiMedia(data.link),
});

export const formatApiMediaItemContent = (data: any): MediaItemContent => ({
  id: data.id,
  content: {
    title: data.title,
    template: data.template,
    medias: data.medias.map(formatApiMediaItem),
  },
});
