/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017-2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { FunctionComponent, useRef } from 'react';
import classNames from 'classnames';
import ConfigHelper from 'configHelper';
import { getMEALogoMedia, getMEABgMedia, getMainMedia } from '../../../helpers/imageHelper';
import { getLinkFromData } from '../../../helpers/uriHelper';
import Button from '../Button';
import { ContentMEA } from 'src/typings/components/content';
import TruncateText from '../TruncateText';
import TruncateTitle from '../TruncateTitle';
import { getLocaleDateString } from 'src/shared/helpers/dateHelper';
import CustomLink from '../../container/CustomLink';

type Props = {
  content: ContentMEA;
  isCompact: boolean;
  className?: string;
  onFocus?: (e: React.SyntheticEvent) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
};

/**
 * MEA Component (Mise En Avant)
 */
const MEA: FunctionComponent<Props> = ({ content, isCompact, className, onBlur, onFocus }) => {
  const renderContent = () => {
    const { article, logo, readMoreLabel, h1, displayTitle, readMoreType } = content;
    const showIcon = readMoreType === 'icon';
    const logoURL = logo ? getMEALogoMedia(logo).src : null;
    return (
      <div className={classNames('mea__wrapper')}>
        <div className="mea__wrapper__content">
          {logoURL && (
            <h1 className="mea__site-logo">
              <img alt={h1} src={logoURL} />
            </h1>
          )}
          {article && !!article.title && displayTitle && (
            <TruncateTitle level={logoURL ? 2 : 3} line={3} title={article.title} className={classNames('mea__titleWithoutLogo')} />
          )}
          {article && !!article.abstract && displayTitle && (
            <TruncateText text={article.abstract} line={6} className={classNames('mea__subtitle')} />
          )}
          {article && !!article.author && displayTitle && (
            <div className="mea__footer">
              <p className={classNames('mea__category')}>{article.category.name}</p>
              <p className={classNames('mea__author')}>{`${getLocaleDateString(new Date(article.publishedAt))}`}</p>
            </div>
          )}
          {article && !!readMoreType && (showIcon || readMoreLabel) ? (
            <Button
              icon={showIcon ? ConfigHelper.getClientConfig('feature.icon.mea') : ''}
              label={showIcon ? '' : readMoreLabel || ''}
              className={classNames('button--mea', {
                'button--icon': showIcon,
                'button--text': !showIcon,
              })}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  const { article, media } = content;
  const bgImageURL = media ? getMEABgMedia(media).src : null;

  const articleImg = article ? getMainMedia(article.mainMedia, isCompact).src : null;

  const bgImg = bgImageURL || articleImg;

  const searchInput = useRef(null);

  return (
    <div className={classNames('mea', className)}>
      {article ? (
        <CustomLink
          ariaLabel={article.title}
          ref={searchInput}
          tabIndex={-1}
          to={getLinkFromData(article)}
          className="mea__link"
        >
          <div className="mea__container">
            <div className="mea__bgd" style={bgImg ? { backgroundImage: `url(${bgImg})` } : {}} />
            {renderContent()}
          </div>
        </CustomLink>
      ) : (
        <div className="mea__container">
          <div className="mea__bgd" style={bgImageURL ? { backgroundImage: `url(${bgImageURL})` } : {}} />
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default MEA;
