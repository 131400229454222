/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import { getLinkHref } from '../../../helpers/uriHelper';
import { ContentLogin } from 'src/typings/components/content';

type Props = {
  content: ContentLogin;
  login: (loginUrl: string, username: string, password: string, useRememberMe: boolean) => void;
  error?: string;
};

type State = {
  username: string;
  password: string;
  useRememberMe: boolean;
};

type InputField = keyof State;

/**
 * Login Component
 */
class Login extends React.Component<Props, State> {
  state = {
    username: '',
    password: '',
    useRememberMe: false,
  };

  onLoginButtonClick = (event: React.SyntheticEvent) => {
    const { username, password, useRememberMe } = this.state;
    const { login, content } = this.props;
    const loginUrl = getLinkHref(content.links[0]);

    event.preventDefault();

    login(loginUrl, username, password, useRememberMe);
  };

  onInputChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name as InputField;
    this.setState({
      [name]: value,
    } as Pick<State, keyof State>);
  };

  render() {
    const { content, error } = this.props;
    const { loginLabel, passwordLabel, loginPlaceholder, allowRememberMe } = content;
    const { username, password, useRememberMe } = this.state;

    return (
      <form className="login">
        {error && <p className="login__error">{error}</p>}

        <label htmlFor="username">{loginLabel}</label>
        <input name="username" type="text" placeholder={loginPlaceholder} value={username} onChange={this.onInputChange} />

        <label htmlFor="password">{passwordLabel}</label>
        <input name="password" type="password" value={password} onChange={this.onInputChange} />

        {allowRememberMe && (
          <div>
            <label htmlFor="useRememberMe">
              <input name="useRememberMe" type="checkbox" defaultChecked={useRememberMe} onChange={this.onInputChange} />
              Se souvenir de moi
            </label>
          </div>
        )}

        <button className="button button--rounded" onClick={this.onLoginButtonClick}>
          Connexion
        </button>
      </form>
    );
  }
}

export default Login;
