import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import breakpoints from 'src/shared/constants/breakpoints';
import VGI from '../../presentational/VGI/VGI';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';
import { extractVideoUrlInfo } from 'src/shared/helpers/videoHelper';
import { ContentVGI } from 'src/typings/components/content';
import { State } from 'src/typings/state/state';
import { ArticleListProps } from '../../presentational/ArticleList/ArticleList';
import { renderContent } from 'src/shared/helpers/modalHelper';

const mapStateToProps = ({ page, authentication }: State) => ({
  token: authentication.token,
  lang: page.lang,
  isCompact: page?.window?.width <= parseInt(breakpoints.tablet, 10),
});

const mapDispatchToProps = (dispath, ownProps: Partial<ArticleListProps> & { renderBlock?: () => Promise<ReactNode[] | null> }) => {
  const { token, renderBlock } = ownProps;

  return { renderArticleContent: async (path: string | null, lang: string | undefined) => renderContent(path, token, lang, renderBlock) };
};

const VGIContainer = ({
  content,
  renderArticleContent,
  lang,
  isCompact,
}: {
  lang: string | undefined;
  content: ContentVGI;
  isCompact: boolean;
  renderArticleContent: (path: string | null, lang: string | undefined) => Promise<ReactNode[] | null>;
}) => {
  const { gif, articles, videoUrl, media, link } = content;
  const { id, source } = extractVideoUrlInfo(videoUrl);

  const video = {
    transcription: content.transcriptionText,
    transcriptionTitle: content.transcriptionTitle,
    id,
    source,
  };

  const image = { ...media, redirection: link[0] };
  const gifContent = {
    url: getLinkFromData(gif),
    transcriptionText: content.gif.transcriptionText,
    transcriptionTitle: content.gif.transcriptionTitle,
  }

  return (
    <VGI
      gifContent={gifContent}
      articles={articles}
      video={video}
      image={image}
      renderArticleContent={ (path) => renderArticleContent(path, lang) }
      isCompact={isCompact}
      alt={gif.alt}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VGIContainer);
