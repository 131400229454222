/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { Component } from 'react';
import { OMNITURE } from './../../../constants/trackers';
import { trackPage } from './../../../helpers/trackingHelper';
import { ContentOmnitureHeader } from 'src/typings/components/content';

type Props = {
  content: ContentOmnitureHeader;
};

/**
 * Omniture Header component
 *
 * This component tracks page with omniture
 */
class OmnitureHeader extends Component<Props> {
  reference?: HTMLDivElement | null;

  track(props: Props) {
    if (!this.reference) {
      return;
    }

    const { content } = props;

    trackPage(content, OMNITURE);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.content !== this.props.content) {
      // Only send tracking if tracking settings changed
      this.track(this.props);
    }
  }

  componentDidMount() {
    this.track(this.props);
  }

  render() {
    return (
      <div
        ref={(element) => {
          this.reference = element;
        }}
      />
    );
  }
}

export default OmnitureHeader;
