import { connect } from 'react-redux';

import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { State } from '../../../../typings/state/state';
import Page from '../../presentational/Page';
import { scrollTo, closeNavHeader } from '../../../actions/page';
import { getPageURI } from '../../../helpers/uriHelper';
/**
 * page container component
 * @class
 */

const mapStateToProps = ({ page }: State) => ({
  pageData: page,
  pageURI: getPageURI(page.path),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  scrollTo: (x: number, y?: number) => {
    dispatch(scrollTo(x, y));
  },
  closeNavHeader: () => {
    dispatch(closeNavHeader());
  },
});

const PageContainer = connect(mapStateToProps, mapDispatchToProps)(Page);

export default PageContainer;
