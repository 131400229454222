/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Logger from 'loggerHelper';
import { SearchResult } from '../../typings/components/searchResult';
import { RootAction } from '../../typings/rootActions';
import { ThunkAction } from 'redux-thunk';
import { PageBlockType } from '../../typings/constants/pageBlockTypes';
import { State } from '../../typings/state/state';
import { PageData } from '../../typings/components/page';
import StructureApi from '../api/StructureApi';
import { formatApiSearchResult } from '../api/parsing/PageParsing';

// used by search actions to eventually update tracking block
export function updateBlock(data: PageData, blockId: number): RootAction {
  return { type: 'UPDATE_BLOCK', payload: { data, blockId } };
}

function searching(keyword: string): RootAction {
  return { type: 'SEARCH_REQUEST', payload: keyword };
}

function searchReturned(result?: SearchResult): RootAction {
  return { type: 'SEARCH_RESPONSE', payload: result };
}

export function searchNextReturned(blockId: number, result?: SearchResult): RootAction {
  return { type: 'SEARCH_NEXT_RESPONSE', payload: { result, blockId } };
}

export function clearSearch(): RootAction {
  return { type: 'SEARCH_CLEAR' };
}

export function toggleDisplay(isOpen: boolean): RootAction {
  // only in front side
  if (typeof document !== 'undefined') {
    // Add search-open class to html element
    const htmlElement: any = document.querySelector('html');

    if (isOpen) {
      htmlElement.classList.add('search-open');
    } else {
      htmlElement.classList.remove('search-open');
    }
  }

  return { type: 'SEARCH_TOGGLE_DISPLAY', payload: { isOpen } };
}

export function changeKeyword(keyword: string): RootAction {
  return { type: 'SEARCH_CHANGE_KEYWORD', payload: { keyword } };
}

/**
 * Search for the given keywords
 *
 * @param {string} keyword
 *
 * @returns {ThunkAction}
 */
export function search(keyword = ''): ThunkAction<Promise<void>, State, { uniqueId?: string }, RootAction> {
  return async (dispatch, _getState, { uniqueId }) => {
    Logger.debug(`Searching for "${keyword}"`);

    dispatch(clearSearch());
    dispatch(searching(keyword));

    if (keyword === '') {
      dispatch(searchReturned());
      return;
    }

    let errorMessage;
    let response;

    const startAt = new Date().getTime();
    const { lang } = _getState().page;

    try {
      response = await StructureApi.search(keyword, { uniqueId, lang });
    } catch (error) {
      if (StructureApi.isCancelError(error)) {
        const logMetadata = Logger.generateApiMetadata('structure', 'search', {
          uniqueId,
          startAt,
        });
        errorMessage = `Search was canceled: ${error.message}`;
        Logger.debug(errorMessage, logMetadata);

        return;
      }

      const logMetadata = Logger.generateApiMetadata('structure', 'search', {
        uniqueId,
        startAt,
      });
      errorMessage = `Failed to get a readable response from api: ${error.message}`;
      Logger.error(errorMessage, logMetadata);

      // Trigger an error
      dispatch(searchReturned());
      return;
    }

    const resultSearch: SearchResult = formatApiSearchResult(response.data);
    try {
      // If the search response contains a tracking block, we should update the current
      // tracking block to trigger new tracking events

      const trackingBlock = resultSearch.blocks.find((block) => block.type === PageBlockType.BLOCK_TYPE_OMNITURE_HEADER);

      if (trackingBlock) {
        Logger.debug('Found tracking blocks to update in search response');
        dispatch(updateBlock(resultSearch, trackingBlock.id));
      }
    } catch (error) {
      const logMetadata = Logger.generateMetadata({ uniqueId });
      errorMessage = `Failed to update tracker data: ${error.message}`;
      Logger.error(errorMessage, logMetadata);
    }

    Logger.debug('Search success');
    dispatch(searchReturned(resultSearch));
  };
}
