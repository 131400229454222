import React from 'react';
import classNames from 'classnames';
import Picture from '../Picture';
import { getResponsiveImages } from '../../../helpers/imageHelper';
import { FragmentType } from '../../../../typings/constants/fragmentTypes';
import { getLinkFromData } from '../../../../shared/helpers/uriHelper';
import breakpoints from '../../../../shared/constants/breakpoints';
import { ContentPicture } from '../../../../typings/components/content';

const fragmentBreakpoints = [breakpoints.phone, breakpoints.tablet, breakpoints.tabletpro, breakpoints.desktop, breakpoints.hd];

const PictureFragment = ({ content, type, className }: { content: ContentPicture; type: string; className?: string }) => {
  const { media } = content;
  if (!media) {
    return null;
  }

  const alt = media.description || media.alt;
  const images = getResponsiveImages(getLinkFromData(media));
  const addtionalClassName = type === FragmentType.FRAGMENT_TYPE_MAIN_IMAGE ? 'main-picture' : '';
  return <Picture images={images} breakpoints={fragmentBreakpoints} alt={alt} className={classNames(className, addtionalClassName)} />;
};

export default PictureFragment;
