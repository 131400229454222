import React from 'react';

import ImageList from '../../presentational/ImageList';
import { MediaType } from 'src/typings/components/media';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';

const ImageListContainer = ({ content, type }: { content: { media: MediaType } & { images: MediaType[] }; type: string }) => {
  if (type === PageBlockType.BLOCK_TYPE_IMAGE) {
    return <ImageList images={[content.media]} />;
  }

  return <ImageList images={content.images} />;
};

export default ImageListContainer;
