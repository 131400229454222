import React from 'react';
import ConfigHelper from 'configHelper';

type CookiesConfig = {
  apiKey: string;
  noticeId: string;
};

export default function CookieConsentDidomi() {
  const { apiKey, noticeId }: CookiesConfig = ConfigHelper.getClientConfig('api.cookies');
  return (
    <script
      type="text/javascript"
      data-api-key={apiKey}
      data-notice-id={noticeId}
      src="https://static.canal-plus.net/boutique/didomi-v2.js"
    ></script>
  );
}
