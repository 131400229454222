export enum VideoSource {
  YOUTUBE = 'youtube',
  DAILYMOTION = 'dailymotion',
  PFV = 'pfv',
}

export type BannerVideoSource = VideoSource | undefined;

// eslint-disable-next-line prettier/prettier
export type BannerVideo = { source: BannerVideoSource; id: string | undefined; transcription: string; transcriptionTitle: string};

export interface PlayerBase {
  autoplay?: boolean;
  onError?: () => void;
  onEnd?: () => void;
}

export interface Player extends PlayerBase {
  id: string | undefined;
  isVisible: boolean;
  title?: string;
}
