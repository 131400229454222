/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Dispatch } from 'redux';
import Logger from 'loggerHelper';
import runTasksForLocation from '../../../shared/routeTasks/runTasksForLocation';
import { WindowApp } from 'src/typings/global';

function executeTasks(location: Location, dispatch: Dispatch) {
  const tasksToExecute =
    window && (window as unknown as WindowApp).APP_STATE
      ? // We have an APP_STATE available, which will contain the state from the
        // server populated by any 'prefetchData' tasks, therefore we only need to
        // call the 'deferredData' tasks.
        ['deferredData']
      : // No data is available so we will execute both the 'prefetchData' and
        // 'deferredData' tasks.
        ['prefetchData', 'deferredData'];

  if (window) {
    // We now remove the APP_STATE as it has been safely merged into the redux
    // store at this stage, and by deleting it we can make sure that we will
    // execute both the 'prefetchData' and 'deferredData' tasks for every route
    // change that happens on the client side.
    delete (window as unknown as WindowApp).APP_STATE;
  }

  // The location has changed so we will attempt to run any route tasks
  // that are matched for the new location.
  const executingTasks = runTasksForLocation(location, tasksToExecute, {
    dispatch,
  });

  if (executingTasks) {
    // Tasks are being executed...
    executingTasks.then(({ routes }) => {
      Logger.debug('Finished route tasks', routes);
    });
  }
}

export default executeTasks;
