/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import { getHeaderMedia, getHeaderMediaBurger } from '../../../helpers/imageHelper';
import Picture from '../Picture';
import { ContentLogo } from 'src/typings/components/content';
import TemplateHeaderConst from 'src/typings/constants/templateHeaderConst';
import CustomLink from '../../container/CustomLink';

type Props = {
  content: ContentLogo;
  templateHeader: TemplateHeaderConst;
  clearSearch: () => void;
  hideSearchResult: () => void;
};

class Logo extends React.PureComponent<Props> {
  static defaultProps = {
    templateHeader: TemplateHeaderConst.DEFAULT,
  };

  onClick = () => {
    this.props.clearSearch();
    this.props.hideSearchResult();
  };

  render() {
    const { content, templateHeader } = this.props;
    const alt = content.media.alt ? content.media.alt : content.media.description || '';
    const image = templateHeader === TemplateHeaderConst.BURGER ? getHeaderMediaBurger(content.media) : getHeaderMedia(content.media);

    return (
      <div className="logo">
        <CustomLink to="/" onClick={this.onClick}>
          <Picture images={[image.src]} alt={alt} />
        </CustomLink>
      </div>
    );
  }
}

export default Logo;
