import React from 'react';

import CreativeMediaArticle from '../CreativeMediaArticle/CreativeMediaArticle';
import Title from '../Title';

export const CategoryListAdvanced = ({ content }) => {
  const categoryList = content?.categories.map((category, index) => {
    const categoryAdvanced = { ...category, links: category.category.links };

    return (
      <div key={index}>
        <CreativeMediaArticle article={categoryAdvanced} ratio={169} isCompact alt={category.media.alt} />
      </div>
    );
  });

  return (
    <div className="category">
      {content.title && <Title title={content.title} className="article-list__title" level={2} />}
      <div className="category--list">{categoryList}</div>
    </div>
  );
};
