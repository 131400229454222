import { ReactNode } from 'react';

import StructureApi from '../api/StructureApi';
import { formatApiPageData } from '../api/parsing/PageParsing';

export const renderContent = async (
  path: string | null,
  token: string | undefined,
  lang: string | undefined,
  renderBlock?: () => Promise<ReactNode[] | null>,
) => {
  if (!path) {
    return null;
  }

  const { data } = await StructureApi.fetchPage(path, { token, lang });
  const formattedData = formatApiPageData(data);

  return renderBlock ? formattedData.blocks.filter((block) => block.container === 'content').map(renderBlock) : null;
};
