/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { IArticleList } from '../../typings/state/articleListState';
import { RootAction } from '../../typings/rootActions';

const defaultState: IArticleList = {
  heightImg: 250,
};

export default function (state: IArticleList = defaultState, action: RootAction): IArticleList {
  switch (action.type) {
    case 'SET_HEIGHT_IMG': {
      return {
        ...state,
        heightImg: action.payload,
      };
    }
    default:
      return state;
  }
}
