/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import get from 'lodash/get';
import { WindowApp } from 'src/typings/global';

const getPublicConfig = (): any => (window as unknown as WindowApp).APP_CONFIG;

/**
 * Config object
 *
 * Provides a consistent access to application config
 */
export default {
  /**
   * Return the whole public config
   *
   * @returns {object}
   */
  getPublicConfig(): any {
    return getPublicConfig();
  },

  /**
   * Retrieve client config value for the given key
   *
   * @param {string} key
   * @returns {any}
   */
  getClientConfig(key: string): any {
    if (process.env.MOCK && key.includes('api.structure.base_url')) {
      const { hostname, port } = get(this.getPublicConfig(), 'mocks.api', {});
      return hostname && port ? `http://${hostname}:${port}/` : null;
    }
    return get(this.getPublicConfig(), key, null);
  },
};
