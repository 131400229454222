import React from 'react';
import classNames from 'classnames';
import Title from '../Title';
import Icon from '../Icon';
import { ContentDownload } from 'src/typings/components/content';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';

/**
 * Raw component for handling responsive images
 * @constructor
 */

type Props = {
  content: ContentDownload;
};

type State = {
  listFull: boolean;
  listDisplayAt: number;
};

class Download extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { listFull: false, listDisplayAt: 6 };
  }

  showMore = () => {
    this.setState({ listFull: true });
  };

  render() {
    const { listFull, listDisplayAt } = this.state;
    const { content } = this.props;

    return (
      <div className={classNames({ listFull }, 'Download')}>
        <Title level={3} title={content.title} />
        <div className="Download__list">
          {content.documents.map((item) => (
            <div className="Download__item" key={item.description}>
              <a className="link" href={getLinkFromData(item)} target="_blank" rel="noopener%20noreferrer">
                {item.description}
                <Icon id="download" />
              </a>
            </div>
          ))}
        </div>
        {!listFull && content.documents.length >= listDisplayAt && (
          <button className="button button--rounded" onClick={this.showMore}>
            Voir plus
          </button>
        )}
      </div>
    );
  }
}

export default Download;
