import React from 'react';
import classNames from 'classnames';
import { mapOrder } from '../../../helpers/mapOrder';
import { HEADER_SCROLL_VALUE, HEADER_FULL_SCROLL_VALUE } from '../../../constants/scroll';
import { canUseDOM } from '../../../helpers/windowHelper';
import Button from '../Button';
import ScrollService from '../../../service/ScrollService';
import { Block } from 'src/typings/components/block';
import { PageBlockType } from 'src/typings/constants/pageBlockTypes';
import { HeaderProps, HeaderState } from 'src/typings/components/header';

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.refHeader = null;
    this.hasScrollEvent = false;
    this.state = {
      scrollY: 0,
      mobileNavIsOpened: false,
      styleMenuScroll: {},
    };

    this.scrollService = new ScrollService();
  }

  // onToggleMobileNav: () => void;
  hasScrollEvent: boolean;
  refHeader: any;
  scrollService: ScrollService;

  componentDidMount() {
    this.scrollService.attach();
    this.scrollService.addEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
  }

  componentWillUnmount() {
    this.scrollService.removeEventListener(ScrollService.SCROLL_CHANGE, this.onScrollChange);
    this.scrollService.dispose();
  }

  onScrollChange = (scrollTop: number) => {
    this.setState({ scrollY: scrollTop });
  };

  componentDidUpdate(prevProps: HeaderProps) {
    if (prevProps.mobileNavIsOpened !== this.props.mobileNavIsOpened) {
      this.setState({ mobileNavIsOpened: this.props.mobileNavIsOpened });
    }
  }

  onToggleMobileNav = () => {
    if (this.state.mobileNavIsOpened && this.props.closeNavHeader) {
      this.props.closeNavHeader();
    } else if (this.props.openNavHeader) {
      this.props.openNavHeader();

      const heightHeader = this.refHeader ? this.refHeader.getBoundingClientRect().height : 0;
      const heightMenuScroll = this.props.window.height - heightHeader;
      this.setState({
        styleMenuScroll: { maxHeight: `${heightMenuScroll}px` },
      });
    }
  };

  render() {
    if (!this.props.elements) {
      return null;
    }
    const { renderBlock } = this.props;
    const isHomeTop = (canUseDOM() && this.state.scrollY === 0 && window.location.pathname === '/') || !canUseDOM();
    const isTop = (canUseDOM() && this.state.scrollY === 0) || !canUseDOM();
    const rightBlockType = [PageBlockType.BLOCK_TYPE_SOCIAL_NETWORKS, PageBlockType.BLOCK_TYPE_SEARCH];
    const elementsGroup: { [key: string]: Array<Block> } = this.props.elements.reduce(
      (group: { [key: string]: Array<Block> }, el: Block) => {
        const groupName = rightBlockType.includes(el.type) ? 'rightBlockElements' : 'normalElements';
        return {
          ...group,
          [groupName]: [...group[groupName], el],
        };
      },
      {
        normalElements: [],
        rightBlockElements: [],
      },
    );

    const normalElements: Array<Block> = elementsGroup.normalElements.filter(
      (block) => block.type !== PageBlockType.BLOCK_TYPE_LANG, // block lang is displayed in GalacticHeader
    );
    const rightBlockElements: { [k: string]: any } = mapOrder(elementsGroup.rightBlockElements, rightBlockType, 'type');

    return (
      <header
        ref={(el) => {
          this.refHeader = el;
        }}
        className={classNames('header', {
          'header--full': (this.state.scrollY > HEADER_FULL_SCROLL_VALUE && this.props.checkForScroll) || this.props.isFull,
          'header--fixed': this.props.isGalactic ? this.state.scrollY > HEADER_SCROLL_VALUE : true,
          'header--opened': this.state.mobileNavIsOpened,
          'header--galactic': this.props.isGalactic,
          'header--homeTop': isHomeTop,
          'header--isTop': isTop,
        })}
      >
        <Button
          className="header__burger-button"
          icon={this.state.mobileNavIsOpened ? 'cross' : 'burger'}
          action={this.onToggleMobileNav}
        />
        <div className="header__menuScroll" style={this.state.styleMenuScroll}>
          {normalElements.length && normalElements.map(renderBlock)}
          <div className="headerRightContainer">{rightBlockElements.length && rightBlockElements.map(renderBlock)}</div>
        </div>
      </header>
    );
  }
}

export default Header;
