/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Component } from 'react';
import List from '../List';
import { getFooterLogoMedia } from '../../../helpers/imageHelper';
import { ContentFooter } from 'src/typings/components/content';
import { getLinkFromData } from 'src/shared/helpers/uriHelper';
import CustomLink from '../../container/CustomLink';

type Props = {
  content: ContentFooter;
};

/**
 * Footer component
 */
class Footer extends Component<Props> {
  reference: any;

  render() {
    const { content } = this.props;
    const { links, menu, logo } = content;
    const link = links ? getLinkFromData(content) : '';
    const logoUrl = getFooterLogoMedia(logo).src;

    return (
      <div
        className="footer__block"
        ref={(element) => {
          this.reference = element;
        }}
      >
        {link !== '' ? (
          <CustomLink className="footer__logo" to={link}>
            <div>
              {content.logo.copyright && <span>{content.logo.copyright}</span>}
              <img alt="Canal Plus" src={logoUrl} />
            </div>
          </CustomLink>
        ) : (
          <div className="footer__logo">
            {content.logo.copyright && <span>{content.logo.copyright}</span>}
            <img alt={logo.alt} src={logoUrl} />
          </div>
        )}
        <List className="footer__nav">
          {menu &&
            menu.map((item) => (
              <CustomLink to={getLinkFromData(item)} key={`footerMenuItem-${item.title}`}>
                {item.title}
              </CustomLink>
            ))}
        </List>
      </div>
    );
  }
}

export default Footer;
