/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { updatePage, formatPageApi } from './../helpers/pageStateHelper';
import { RootAction } from '../../typings/rootActions';
import { IPageState, PageDataState } from '../../typings/state/pageState';
import { PageData } from '../../typings/components/page';

const defaultState: IPageState = {
  path: '',
  data: {
    statusCode: 200,
    cache: 0,
    title: '',
    links: [],
    template: '',
    meta: [],
    scripts: [],
    headers: {},
    site: {
      locale: '',
      title: '',
      logo: '',
      translatable: false,
      languages: undefined,
    },
    blocks: {},
  },
  articleModal: {
    ariaLabelledBy: 'heading',
  },
  footerHeight: 0,
  window: { width: 0, height: 0 },
  mobileNavIsOpened: false,
  hasMEA: false,
  hasMeaCarousel: false,
  hasGalactic: false,
  isMEAFirst: false,
  isMeaCarouselFirst: false,
  langsAvailable: [],
};

export default function (state: IPageState = defaultState, action: RootAction): IPageState {
  let page;
  let dataFormatted;
  switch (action.type) {
    case 'FETCHED_PAGE':
      dataFormatted = formatPageApi(action.payload);
      return {
        ...state,
        ...dataFormatted,
      };
    case 'FETCHED_REDIRECTION':
      page = get(action.payload, 'data', {});
      return {
        ...state,
        redirect: {
          permanent: !isEmpty(page) && (page as PageData).statusCode === 301,
          location: get(page, 'headers.Location', ''),
        },
      };
    case 'FETCHED_NEXT_PAGE':
      return {
        ...state,
        data: updatePage('merge')(state.data, action.payload.page.data, action.payload.blockId) as PageDataState,
      };
    case 'UPDATE_BLOCK':
      return {
        ...state,
        data: updatePage('replace')(state.data, action.payload.data, action.payload.blockId) as PageDataState,
      };
    case 'SET_DEVICE':
      return {
        ...state,
        device: action.payload,
      };
    case 'RESIZE_CHANGE':
      return {
        ...state,
        window: action.payload,
      };
    case 'OPEN_NAV_HEADER':
      return {
        ...state,
        mobileNavIsOpened: true,
      };
    case 'CLOSE_NAV_HEADER':
      return {
        ...state,
        mobileNavIsOpened: false,
      };
    case 'FOOTER_HEIGHT_CHANGE':
      return {
        ...state,
        footerHeight: action.payload,
      };
    case 'SET_LANG':
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}
