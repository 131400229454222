import React from 'react';
import Truncate from 'react-text-truncate';
import classNames from 'classnames';

type Props = {
  text: string;
  className?: string;
  line?: number;
};

class TruncateText extends React.PureComponent<Props> {
  static defaultProps = {
    line: 2,
  };

  render() {
    const { text, className, line } = this.props;

    return <Truncate containerClassName={classNames('text', className)} line={line} truncateText="…" text={text} />;
  }
}

export default TruncateText;
