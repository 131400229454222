/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';
import MeaVideoList from '../../presentational/MeaVideoList';
import { setPlaylistIndex } from '../../../actions/meaPlaylist';
/**
 * Connected container for MeaVideoList component
 */
const mapStateToProps = (state: State) => ({
  meaPlaylist: state.meaPlaylist,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentIndex: (index: number) => {
    dispatch(setPlaylistIndex(index));
  },
});

const MeaVideoContainer = connect(mapStateToProps, mapDispatchToProps)(MeaVideoList);

export default MeaVideoContainer;
