import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Button from '../Button';
import WORDINGS from '../../../constants/wordings';

type Props = {
  keyword: string;
  changeKeyword: (value: string) => void;
  search: (value: string) => void;
};

type State = {
  value: string;
};

/**
 * SearchItem component
 */
class SearchItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onSearch = debounce(this.onSearch, 300);
  }

  onSearchValueChange = (evt) => {
    const { value } = evt.target as HTMLInputElement;
    this.props.changeKeyword(value);
  };

  onKeyPress = (evt: React.KeyboardEvent) => {
    const { value } = evt.target as HTMLInputElement;

    if (evt.charCode === 13 && value) {
      evt.preventDefault();
      this.onSearch(value);
    }
  };

  onSearch = (keyword: string) => {
    if (keyword) {
      this.props.search(keyword);
    }
  };

  render() {

    return (
      <form className="headerBurger__search" name="headerBurger__search" action="#" role="search">
        <label htmlFor="headerBurger-search-input">Recherche</label>
        <input
          id="headerBurger-search-input"
          name="headerBurger-search-input"
          type="text"
          className="headerBurger__search-input"
          placeholder={WORDINGS.SEARCH_PLACEHOLDER}
          onChange={this.onSearchValueChange}
          onKeyPress={(e)=> {
            this.onKeyPress(e); 
          }}
          value={this.props.keyword}
        />
        <Button
          className="headerBurger__search-toggle-button"
          ariaLabel="Rechercher"
          icon="glass"
          action={() => {
            this.onSearch(this.props.keyword);
          }}
        />
      </form>
    );
  }
}

export default SearchItem;
