import React from 'react';
import classNames from 'classnames';
import { removeScriptHtml } from 'src/shared/helpers/htmlHelper';

/**
 * Text component
 * @param {string} content Content
 * @constructor
 */
const Quote = ({ content, className }: { content: { text: string }; className?: string }) => (
  <div className={classNames(className, 'quote')}>
    <p dangerouslySetInnerHTML={{ __html: `« ${removeScriptHtml(content.text)} »` }}></p>
  </div>
);

export default Quote;
