import React from 'react';
import uuid from 'uuid';

import { VideoArticle } from '../../../../typings/components/videoArticle';
import { VideoSource, PlayerBase } from '../../../../typings/constants/players';
import Text from '../Text';
import classNames from 'classnames';
import YoutubePlayer from '../YoutubePlayer';
import DailyMotionPlayer from '../DailyMotionPlayer';
import CanalPlayer from '../CanalPlayer';
import TruncateTitle from '../TruncateTitle';
import WORDINGS from 'src/shared/constants/wordings';
import { Transcription } from '../Transcription';
import { TranscriptionModal } from '../TranscriptionModal';
import { Video } from 'src/typings/components/video';

interface Props extends PlayerBase {
  players: Array<VideoSource | undefined>;
  currentArticle: VideoArticle;
  isLastSource?: boolean;
  hasError?: boolean;
  isMultiple?: boolean;
  shouldPlayNext?: boolean;
  onNextArticle?: () => void;
  setShouldPlayNext?: () => void;
  className?: string;
}

type videoSwitchState = {
  focused: boolean;
  isTranscriptionModalOpen: boolean;
};

const getVideoSource = (article: VideoArticle): VideoSource | undefined => article.video && article.video.source;
const getVideoId = (article: VideoArticle): string | undefined => article.video && article.video.id;
const getVideoTitle = (video): string => video.title;

/**
 * general videoPlayer Component accept an array of article(s) contains video
 */
class VideoSwitch extends React.PureComponent<Props, videoSwitchState> {

  constructor(props: Props) {
    super(props);
    this.state = { focused: false, isTranscriptionModalOpen: false };
  }

  openTranscriptionModal = () => {
    this.setState({ isTranscriptionModalOpen: true });
  }

  closeTranscriptionModal = () => {
    this.setState({ isTranscriptionModalOpen: false });
  }

  render() {
    const {
      players,
      currentArticle,
      autoplay,
      onEnd,
      onError,
      hasError,
      onNextArticle,
      className,
      isLastSource,
      isMultiple,
      shouldPlayNext,
      setShouldPlayNext,
    } = this.props;

    const currentSource: VideoSource | undefined = getVideoSource(currentArticle);
    const youtubeVisible = currentSource === VideoSource.YOUTUBE;
    const youtubeId = youtubeVisible ? getVideoId(currentArticle) : undefined;
    const dailymotionVisible = currentSource === VideoSource.DAILYMOTION;
    const dailymotionId = dailymotionVisible ? getVideoId(currentArticle) : undefined;
    const videoTitle = getVideoTitle(currentArticle.video as Video);

    // As the onePlayer doesn't support instanciation without an id, it cannot be used in video list
    // we eliminate already all PFV source in reducer for video list
    // so the only case is a onePlayer video in single video block / MEA video
    const onePlayerVisible = currentSource === VideoSource.PFV;
    const isSingleCanalePlayer = players.includes(VideoSource.PFV) && onePlayerVisible;
    const showAutoPlay = (hasError && !isLastSource) || (!shouldPlayNext && isMultiple && !isLastSource);
    const uniqueId = uuid();

    const onVideoFocus = (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        this.setState({ focused: true });
      }
    };

    const onTranscriptionFocus = () => {
      this.setState({ focused: true })
    }

    const onTranscriptionBlur = () => {
      this.setState({ focused: false });
    }

    const isVgiPlayerFocused = this.state.focused && currentArticle.video?.transcriptionTitle && className === 'vgi__player';

    return (
      <>
        <div className={classNames(className, 'video')}>
          {videoTitle && <h2>{videoTitle}</h2>}
          <div className="video__container"
            tabIndex={0}
            onKeyDown={onVideoFocus}
          >
            <div className={`video__player ${isSingleCanalePlayer ? 'canal' : ''}`}>
              {players.includes(VideoSource.YOUTUBE) && (
                <YoutubePlayer
                  id={youtubeId}
                  title="Vidéo YouTube"
                  isVisible={youtubeVisible}
                  autoplay={autoplay}
                  onEnd={onEnd}
                  onError={onError}
                />
              )}
              {players.includes(VideoSource.DAILYMOTION) && (
                <DailyMotionPlayer
                  id={dailymotionId}
                  title="Vidéo Dailymotion"
                  isVisible={dailymotionVisible}
                  autoplay={autoplay}
                  onEnd={onEnd}
                  onError={onError}
                />
              )}
              {isSingleCanalePlayer && (
                <CanalPlayer id={getVideoId(currentArticle)} isVisible autoplay={autoplay} onError={onError} onEnd={onEnd} />
              )}
            </div>
            {isMultiple && (
              <label htmlFor={`video-switch-${uniqueId}`} className="video__switch">
                <span className="video__switch--label">{WORDINGS.AUTO_PLAY}</span>
                <input
                  id={`video-switch-${uniqueId}`}
                  name="video-switch"
                  className="video__switch--input"
                  type="checkbox"
                  onChange={setShouldPlayNext}
                  defaultChecked={shouldPlayNext}
                />
                <span className="video__switch--toggle" />
              </label>
            )}
            {currentArticle.title && <TruncateTitle title={currentArticle.title} />}
            {currentArticle.abstract && <Text text={currentArticle.abstract} />}
            {onNextArticle ? (
              <div className={`video__next ${showAutoPlay ? 'video__next--show' : ''}`}>
                <button className="button button--rounded button--pagination" onClick={onNextArticle}>
                  {WORDINGS.NEXT_VIDEO}
                </button>
              </div>
            ) : null}
          </div>

          <button className={`${isVgiPlayerFocused && 'text__transcription text__transcription--video'}`}
            style={{
              opacity: isVgiPlayerFocused ? '1' : '0',
              height: isVgiPlayerFocused ? 'auto' : '0',
            }}
            onKeyPress={this.openTranscriptionModal}
            onClick={this.openTranscriptionModal}
            onFocus={onTranscriptionFocus}
            onBlur={onTranscriptionBlur}
          >
             <div tabIndex={-1} aria-expanded="false" className="text__transcription--videoButton"><i
              className="icon ai-arrow-right" style={{ transform: "scale(1) rotate(0deg)" }}></i><span>{currentArticle.video?.transcriptionTitle}</span></div>
          </button>

          {currentArticle.video?.transcriptionTitle && className !== 'vgi__player' &&
            <Transcription
              label={currentArticle.video.transcriptionTitle}
              transcription={currentArticle.video.transcriptionTitle} />
          }

          {this.state.isTranscriptionModalOpen && currentArticle.video?.transcriptionTitle && className === 'vgi__player' &&
            <TranscriptionModal
              label={currentArticle.video?.transcriptionTitle}
              transcription={currentArticle.video?.transcription}
              closeTranscriptionModal={this.closeTranscriptionModal}
              isTranscriptionModalOpen={this.state.isTranscriptionModalOpen}
            />
          }
        </div>
      </>
    );
  }
}

export default VideoSwitch;
