import React from 'react';
import { ContentBannerHeader } from './../../../../typings/components/content';
import { getLinkHref } from 'src/shared/helpers/uriHelper';
import CustomLink from '../../container/CustomLink';
import { getImageWithSize } from 'src/shared/helpers/imageHelper';

/**
 * BannerHeader component
 * @constructor
 */
type Props = {
  content: ContentBannerHeader;
};

class BannerHeader extends React.PureComponent<Props> {
  render() {
    const { title, label, media, link } = this.props.content;

    return (
      <div className="bannerHeader">
        <div className="bannerHeader__container">
          <p className="bannerHeader__title">{title}</p>
          {(label || media) && (
            <div className="bannerHeader__side">
              {label && <p className="bannerHeader__side-label">{label}</p>}
              {media && link && link.length > 0 && (
                <CustomLink to={getLinkHref(link[0])}>
                  <img alt={label} src={getImageWithSize({ height: '25' }, media.links[0].href)} />
                </CustomLink>
              )}
              {media && !link && <img alt={label} src={media.links[0].href} />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BannerHeader;
