import React from 'react';
import classNames from 'classnames';
import ConfigHelper from 'configHelper';
import GalacticLogo from '../GalacticLogo';
import GalacticTools from '../GalacticTools';
import GalacticMenuContainer from '../../container/GalacticMenu';
import { ContentGalacticHeader } from 'src/typings/components/content';
import TemplateGalacticHeaderConst from 'src/typings/constants/templateGalacticHeaderConst';
import LangSelector from '../../container/LangSelector';

const galacticHeaderTemplate: TemplateGalacticHeaderConst = ConfigHelper.getClientConfig('feature.galacticHeader.template');

type Props = {
  content: ContentGalacticHeader;
  langsAvailable: string[];
};

class GalacticHeader extends React.PureComponent<Props> {
  render() {
    const { content, langsAvailable } = this.props;
    const { brand, menu, partner, tool } = content;

    return (
      <div
        className={classNames('GalacticHeader', {
          [galacticHeaderTemplate]: galacticHeaderTemplate,
        })}
        role="banner"
      >
        {brand.media && <GalacticLogo brand={brand} partner={partner} />}
        <div className="GalacticHeader__containerRight">
          <GalacticTools tool={tool} />
          {langsAvailable.length > 0 && <LangSelector />}
          {menu.elements[0] && menu.elements[0].media && <GalacticMenuContainer menu={menu} />}
        </div>
      </div>
    );
  }
}

export default GalacticHeader;
