import {
  FragmentArticleList,
  FragmentAuthor,
  FragmentDate,
  FragmentIntro,
  FragmentQuote,
  FragmentSocialNetwork,
  FragmentTag,
} from 'src/typings/components/fragment';
import { formatApiTag, formatApiVideo } from './ElementParsing';
import { Video } from 'src/typings/components/video';
import { formatApiArticleBase } from './ContentParsing';
import { ContentRaw } from 'src/typings/components/content';

export const formatFragmentAuthor = (data: any): FragmentAuthor => ({
  name: data.name,
});
export const formatFragmentDate = (data: any): FragmentDate => ({
  date: data.date,
});
export const formatFragmentIntro = (data: any): FragmentIntro => ({
  text: data.text,
});
export const formatFragmentQuote = (data: any): FragmentQuote => ({
  text: data.text,
});
export const formatFragmentNetworks = (data: any): FragmentSocialNetwork => {
  const ret: FragmentSocialNetwork = {
    shareList: {},
  };

  Object.keys(data).forEach((p: string): void => {
    if (data[p]) {
      ret.shareList[p] = {
        isActive: data[p].isActive,
        tracking: data[p].tracking,
      };
    }
  });

  return ret;
};
export const formatFragmentTag = (data: any): FragmentTag => ({
  tags: data.tags.map(formatApiTag),
});

export const formatFragmentVideo = (data: any): Video => formatApiVideo(data);

export const formatFragmentAdvertising = (data: any): ContentRaw => ({
  text: `${data.iframe}\n${data.script}\n${data.style}`,
});

export const formatFragmentArticleList = (data: any): FragmentArticleList => ({
  articles: data.articles.map(formatApiArticleBase),
  title: data.title,
});
