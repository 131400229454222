/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { Component } from 'react';
import CustomLink from '../../container/CustomLink';

type Props = {
  blockId: number;
  onClickHandler: (to: string, blockId: number, isSearch?: boolean) => void;
  to: string;
  className?: string;
  isSearch?: boolean;
  children?: React.ReactNode;
};

class NextLink extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  onClick = (event: React.SyntheticEvent) => {
    const { onClickHandler, blockId, to, isSearch } = this.props;

    event.preventDefault();

    onClickHandler(to, blockId, isSearch);
  };

  render() {
    const { className, to, children } = this.props;

    return (
      <CustomLink className={className} to={to} onClick={this.onClick}>
        {children}
      </CustomLink>
    );
  }
}

export default NextLink;
