import React from 'react';
import classNames from 'classnames';
import { ContentEmptyLine } from 'src/typings/components/content';
import { removeScriptHtml } from 'src/shared/helpers/htmlHelper';


const EmptyLine = ({ content, className }: { content: ContentEmptyLine; className?: string }) => {
  return (
    <div
      aria-hidden='true'
      className={classNames(className, 'empty-line')}
      dangerouslySetInnerHTML={{ __html: removeScriptHtml(content.text) }}
    />
  )
};

export default EmptyLine;
