/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';

const TwitterEmbed = ({ apiId }: { apiId: string }) => (
  <div className="twitter">
    <a className="twitter-timeline" href={`https://twitter.com/${apiId}`}>
      {`Tweets by ${apiId}`}
    </a>
  </div>
);

export default TwitterEmbed;
