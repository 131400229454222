import {
  ContentOmnitureHeader,
  ContentArticleList,
  ContentMediaList,
  ContentFooter,
  ContentGalacticHeaderElement,
  ContentGalacticHeader,
  ContentMEA,
  ContentArticleBanner,
  ContentCategoryList,
  ContentDownload,
  ContentContact,
  ContentStaticBlock,
  ContentComment,
  ContentCookieConsent,
  ContentIframe,
  ContentJSONLD,
  ContentLink,
  ContentLogo,
  ContentMEAVideo,
  ContentNavigation,
  ContentSocialFeed,
  ContentRaw,
  ContentText,
  ContentTitle,
  ContentLogin,
  ContentMeaCarousel,
  ContentArticleBannerColumn,
  ContentDownloadDocument,
  ContentPicture,
  ContentSearch,
  ContentLang,
  ContentBannerHeader,
  ContentArticleDynamic,
  ContentArticleDynamicList,
  ContentFooterV5,
  ContentVideoBanner,
  ContentVGI,
  ContentOffice,
  ContentEmptyLine,
} from 'src/typings/components/content';
import {
  formatApiLink,
  formatApiMedia,
  formatApiLogo,
  formatApiMenuItem,
  formatApiCategory,
  formatApiContactElement,
  formatApiFlux,
  formatApiArticleAuthor,
  formatApiCategoryContent,
  formatApiTag,
  formatApiMainMedia,
  formatApiSharing,
  formatApiVideo,
  formatApiSocialNetworksBar,
} from './ElementParsing';
import { ArticleBase } from 'src/typings/components/articleBase';
import { ArticleDynamic } from 'src/typings/components/articleDyanmic';
import { Fragment } from 'src/typings/components/fragment';
import { SocialNetworks } from 'src/typings/constants/socialNetworks';
import { ListImage } from 'src/typings/components/media';

export const formatApiArticleDyanmic = (data: any, formatFragment?: (data: any) => Fragment): ArticleDynamic => {
  const ret: ArticleDynamic = {
    id: data.id,
    title: data.title,
    readingTime: data.readingTime,
    subtitle: data.subtitle,
    abstract: data.abstract,
    label: data.label,
    omniture: data.omniture,
    author: formatApiArticleAuthor(data.author),
    tags: data.tags.map(formatApiTag),
    category: formatApiCategoryContent(data.category),
    links: data.links.map(formatApiLink),
    mainMedia: data.mainMedia.default ? formatApiMainMedia(data.mainMedia) : formatApiMedia(data.mainMedia),
    media: data.media ? formatApiMedia(data.media) : undefined,
    fragments: data.fragments && formatFragment ? data.fragments.map(formatFragment) : [],
    status: data.status,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    publishedAt: data.publishedAt,
    sharing: data.sharing ? formatApiSharing(data.sharing) : undefined,
    video: data.video ? formatApiVideo(data.video) : undefined,
    autoplay: data.autoplay,
  };
  return ret;
};

export const formatContentArticleDynamic = (data: any, formatFragment?: (data: any) => Fragment): ContentArticleDynamic => {
  if (data.article) {
    return {
      article: formatApiArticleDyanmic(data.article, formatFragment),
    };
  }
  return [];
};

export const formatApiArticleBase = (data: any): ArticleBase => {
  const ret: ArticleBase = {
    id: data.id,
    title: data.title,
    readingTime: data.readingTime,
    subtitle: data.subtitle,
    abstract: data.abstract,
    label: data.label,
    omniture: data.omniture,
    author: formatApiArticleAuthor(data.author),
    tags: data.tags.map(formatApiTag),
    category: formatApiCategoryContent(data.category),
    links: data.links.map(formatApiLink),
    mainMedia: data.mainMedia.default ? formatApiMainMedia(data.mainMedia) : formatApiMedia(data.mainMedia),
    media: data.media ? formatApiMedia(data.media) : undefined,
    status: data.status,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    publishedAt: data.publishedAt,
    sharing: data.sharing ? formatApiSharing(data.sharing) : undefined,
    video: data.video ? formatApiVideo(data.video) : undefined,
    autoplay: data.autoplay,
  };
  return ret;
};

export const formatContentOmnitureHeader = (data: any): ContentOmnitureHeader => data as ContentOmnitureHeader;

export const formatContentArticleList = (data: any): ContentArticleList => {
  const ret: ContentArticleList = {
    abstractExposed: data.abstractExposed,
    isPaginable: data.isPaginable,
    articles: data.articles.map(formatApiArticleBase),
    links: data.links ? data.links.map(formatApiLink) : undefined,
  };

  if (data.title) {
    ret.title = data.title;
  }
  if (data.template) {
    ret.template = data.template;
  }
  if (data.readMoreLabel) {
    ret.readMoreLabel = data.readMoreLabel;
  }

  return ret;
};

export const formatContentArticleDynamicList = (data: any): ContentArticleDynamicList => {
  const ret: ContentArticleDynamicList = {
    abstractExposed: data.abstractExposed,
    isPaginable: data.isPaginable,
    articles: data.articles.map(formatApiArticleDyanmic),
    links: data.links ? data.links.map(formatApiLink) : undefined,
  };

  if (data.title) {
    ret.title = data.title;
  }
  if (data.template) {
    ret.template = data.template;
  }
  if (data.readMoreLabel) {
    ret.readMoreLabel = data.readMoreLabel;
  }

  return ret;
};

export const formatContentMediaList = (data: any): ContentMediaList => ({
  medias: data.medias.map(formatApiArticleBase),
  title: data.title ? data.title : undefined,
  template: data.template ? data.template : undefined,
});

export const formatContentFooter = (data: any): ContentFooter => ({
  menu: data.menu ? data.menu.map(formatApiMenuItem) : [],
  logo: formatApiMedia(data.logo),
  links: data.links ? data.links.map(formatApiLink) : undefined,
});

export const formatContentFooterV5 = (data: any): ContentFooterV5 => ({
  links: data.center,
});

export const formatGalacticHeaderElement = (data: any): ContentGalacticHeaderElement => ({
  media: data.media ? formatApiLogo(data.media) : undefined,
  links: formatApiLink(data.links),
});

export const formatContentGalacticHeader = (data: any): ContentGalacticHeader => ({
  tool: {
    type: data.tool.type,
    settings: data.tool.settings
      ? {
          playlist_id: data.tool.settings.playlist_id, // eslint-disable-line
          user_id: data.tool.settings.user_id, // eslint-disable-line
        }
      : undefined,
  },
  brand: formatGalacticHeaderElement(data.brand),
  partner: formatGalacticHeaderElement(data.partner),
  menu: {
    elements: data.menu.elements.map(formatGalacticHeaderElement),
  },
});

export const formatContentMEA = (data: any): ContentMEA => {
  const ret: ContentMEA = {
    article: data.article ? formatApiArticleBase(data.article) : undefined,
    media: data.media ? formatApiMedia(data.media) : undefined,
    logo: data.logo ? formatApiLogo(data.logo) : undefined,
    readMoreType: data.readMoreType,
    readMoreLabel: data.readMoreLabel,
    h1: data.h1,
    displayTitle: data.displayTitle,
  };

  return ret;
};

export const formatContentMeaCarousel = (data: any): ContentMeaCarousel => ({
  slideTime: data.slideTime,
  columns: data.columns.map(formatContentMEA),
});

export const formatContentArticleBannerColumn = (data: any): ContentArticleBannerColumn => ({
  article: formatApiArticleBase(data.article),
  media: data.media ? formatApiMedia(data.media) : undefined,
});

export const formatContentArticleBanner = (data: any): ContentArticleBanner => ({
  title: data.title,
  columns: data.columns.map(formatContentArticleBannerColumn),
});

export const formatContentCategoryList = (data: any): ContentCategoryList => ({
  title: data.title,
  categories: data.categories.map(formatApiCategory),
});

export const formatContentDownloadDocument = (data: any): ContentDownloadDocument => ({
  links: data.links.map(formatApiLink),
  filename: data.filename,
  contentType: data.contentType,
  size: data.size,
  description: data.description,
});
export const formatContentDownload = (data: any): ContentDownload => ({
  title: data.title,
  documents: data.documents.map(formatContentDownloadDocument),
});

export const formatContentContact = (data: any): ContentContact => data.map(formatApiContactElement);

export const formatContentStaticBlock = (data: any): ContentStaticBlock => ({
  title: data.title,
  content: data.content,
  media: formatApiMedia(data.media),
});

export const formatContentSocialNetworksMenu = (data: any): SocialNetworks => formatApiSocialNetworksBar(data);

export const formatContentListImage = (data: any): ListImage => ({
  images: data.images,
});

export const formatContentComment = (data: any): ContentComment => ({
  comments: {
    settings: {
      short_name: data.comments.settings.short_name ? data.comments.settings.short_name : undefined, // eslint-disable-line
      hostname: data.comments.settings.hostname ? data.comments.settings.hostname : undefined,
    },
  },
});

export const formatContentCookieConsent = (data: any): ContentCookieConsent => ({
  howto: data.howto,
  links: data.links.map(formatApiLink),
  disclaimer: data.disclaimer,
  title: data.title,
});

export const formatContentIframe = (data: any): ContentIframe => ({
  width: data.width,
  height: data.height,
  links: data.links ? data.links.map(formatApiLink) : undefined,
});

export const formatContentJSONLD = (data: any): ContentJSONLD => ({
  data: data.data
    ? {
        url: data.data.url ? data.data.url : undefined,
        mainEntity: data.data.mainEntity
          ? {
              url: data.data.mainEntity.url ? data.data.mainEntity.url : undefined,
              logo: data.data.mainEntity.logo ? data.data.mainEntity.logo : undefined,
            }
          : undefined,
        potentialAction: data.data.potentialAction
          ? {
              target: data.data.potentialAction.target
                ? {
                    urlTemplate: data.data.potentialAction.urlTemplate ? data.data.potentialAction.urlTemplate : undefined,
                  }
                : undefined,
            }
          : undefined,
      }
    : undefined,
});

export const formatContentLink = (data: any): ContentLink => ({
  title: data.title,
  link: data.link.map(formatApiLink),
});

export const formatContentLogo = (data: any): ContentLogo => ({
  media: formatApiMedia(data.media),
});

export const formatContentMEAVideo = (data: any): ContentMEAVideo => ({
  article: formatApiArticleBase(data.article),
  transcriptionTitle: data.transcriptionTitle,
  autoplay: data.autoplay,
  focusVideo: data.focusVideo,
});

export const formatContentNavigation = (data: any): ContentNavigation => ({
  menu: data.menu.map(formatApiMenuItem),
});

export const formatContentSocialFeed = (data: any): ContentSocialFeed => ({
  title: data.title,
  flux: formatApiFlux(data.flux),
});

export const formatContentRaw = (data: any): ContentRaw => ({
  text: data.text,
});

export const formatContentText = (data: any): ContentText => ({
  text: data.text,
  transcription: data.transcription,
  transcriptionTitle: data.transcriptionTitle,
});

export const formatContentEmptyLine = (data: any): ContentEmptyLine => ({
  text: data.text,
});

export const formatContentTitle = (data: any): ContentTitle => ({
  title: data.title ? data.title : undefined,
  text: data.text ? data.text : undefined,
});

export const formatContentLogin = (data: any): ContentLogin => ({
  loginLabel: data.loginLabel,
  passwordLabel: data.passwordLabel,
  allowRememberMe: data.allowRememberMe !== undefined ? data.allowRememberMe : undefined,
  links: data.links.map(formatApiLink),
  loginPlaceholder: data.loginPlaceholder !== undefined ? data.loginPlaceholder : undefined,
});

export const formatContentSearch = (data: any): ContentSearch => ({
  links: data.links ? data.links.map(formatApiLink) : [],
});

export const formatContentPicture = (data: any): ContentPicture => ({
  media: formatApiMedia(data.media),
});

export const formatContentLang = (data: any): ContentLang => ({
  locales: data.locales,
});

export const formatContentBannerHeader = (data: any): ContentBannerHeader => ({
  title: data.title,
  label: data.label,
  media: data.media ? formatApiMedia(data.media) : undefined,
  link: data.link ? data.link.map(formatApiLink) : undefined,
});

export const formatContentVideoBanner = (data: any): ContentVideoBanner => ({
  videoUrl: data.videoUrl,
  transcriptionText: data.transcriptionText,
  transcriptionTitle: data.transcriptionTitle,
});

export const formatContentVGI = (data: any): ContentVGI => ({
  articles: Object.values(data.articles).map(formatApiArticleBase),
  abstractExposed: data.abstractExposed,
  gif: data.gif,
  link: data.link,
  transcriptionText: data.transcriptionText,
  transcriptionTitle: data.transcriptionTitle,
  media: data.media,
  videoUrl: data.videoUrl,
  readMoreLabel: data.readMoreLabel,
});

export const formatContentOfficeList = (data: any): ContentOffice => ({
  title: data.main_title,
  offices: data.offices.map((office) => ({
    title: office.title,
    subtitle: office.subtitle,
    mainMedia: formatApiMedia(office.media),
  })),
});
