/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../typings/state/state';
import SearchItem from '../../presentational/HeaderBurger/SearchItem';
import { search, toggleDisplay, changeKeyword } from '../../../actions/search';
import { closeNavHeader } from 'src/shared/actions/page';

const mapStateToProps = (state: State) => ({
  keyword: state.search.keyword,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, { uniqueId?: string }, Action>) => ({
  search: (value: string) => {
    dispatch(search(value));
    dispatch(toggleDisplay(true));
    dispatch(closeNavHeader());
  },
  changeKeyword: (keyword: string) => {
    dispatch(changeKeyword(keyword));
  },
});

const SearchItemContainer = connect(mapStateToProps, mapDispatchToProps)(SearchItem);

export default SearchItemContainer;
