import React from 'react';

import { Player } from '../../../../typings/constants/players';

const DailyMotionPlayer = ({ id, isVisible, autoplay, title }: Player) => (
  <div className={`video__dailymotion ${isVisible && id ? '' : 'video__dailymotion--isHidden'}`}>
    <iframe
      id={`dailymotionPlayer-${id}`}
      title={title}
      src={`https://www.dailymotion.com/embed/video/${id}?autoplay=${autoplay ? '1' : '0'}`}
      allow={autoplay ? `autoplay` : ''}
      allowFullScreen
    />
  </div>
);

export default DailyMotionPlayer;
