/** ../../typings/components/category
 * This file is part of the src/typings/components/articlet.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import map from 'lodash/map';

import ConfigHelper from 'configHelper';
import { Link } from './../../typings/components/link';

/** ../../typings/components/link
 * Transform the given parameter objects into a query string
 *
 * @param {object} object The parameters to be transformed into query string
 * @returns {string}      The resulting query string
 */
const buildQueryString = (object?: Record<string, any>): string => {
  const queryString = map(object, (value, key: any) => `${key}=${value}`).join('&');

  return queryString.length > 0 ? `?${queryString}` : '';
};

/**
 * Return the path to request on the Structure  for the given client uri
 *
 * @param {string} uri Client page URI (ex: site.canal.fr/articles/my-article
 * @param {object} parameters Parameters to be added as query string
 *
 * @returns {string} Path to call on the Structure
 */
export const getPageURI = (uri: string, parameters?: Record<string, any>): string => {
  if (uri !== '/' && uri !== undefined) {
    const arr = uri.split('/');
    arr.shift();
    return `/${arr.join('/')}${buildQueryString(parameters)}`;
  }
  return `/${buildQueryString(parameters)}`;
};

/**
 * Return the search URI for the given words
 *
 * @param {string} words Words to search
 * @param {object} parameters Parameters to be added as query string
 *
 * @returns {string} Path to call on the Structure
 */
export const getSearchURI = (words: string, parameters?: Record<string, any>): string =>
  // Todo: front app should not have to know it is "/search/".  must give this info with hateoas links
  `/search/${words}${buildQueryString(parameters)}`;

/**
 * Returns the path of the given  url, striping the  host
 *
 * @param {string} url
 * @return {string}
 */
export const getPath = (url: string): string => {
  let path;
  let BaseUrl = ConfigHelper.getClientConfig('api.structure.base_url');
  if (url.includes(BaseUrl)) {
    path = url.replace(BaseUrl, '');
  } else {
    // For now the  is not consistent and may give self links in http while it is requested in https
    // Until fixed, the front must be smart enough to use the correct protocol on self links
    // So we're fallbacking here by using the base_url  protocol for these self links
    BaseUrl = BaseUrl.replace('https://', 'http://');
    path = url.replace(BaseUrl, '');
  }

  // Ensure leading slash
  return path.startsWith('/') ? path : `/${path}`;
};

/**
 * Transform an  link into a path
 *
 * @param {Link} link
 * @returns {string}
 */
export const getLinkHref = (link: Link): string => {
  if (!(link && link.href)) {
    return '#';
  }

  switch (link.rel) {
    case 'self':
    case 'login':
    case 'prev':
    case 'next': {
      // Rel is "self", "next or "prev", it is a link on the
      // Removing  base url from the link
      return getPath(link.href);
    }
    case 'email':
      // Rel is "email", it is an email
      return `mailto:${link.href}`;
    case 'external':
      if (link.href.indexOf('https://') === -1 && link.href.indexOf('http://') === -1) {
        return `http://${link.href}`;
      }
      return link.href;
    default:
      // Unmatched rel, link is invalid
      return '#';
  }
};

/**
 * Return the language to use for the given client uri
 *
 * @param {string} uri Client page URI (ex: site.canalplus.com/fr/articles/my-article
 * @param {Array} langList The list of language avalaible.
 *
 * @returns {string} The language find in url or the default value language
 */
export const getLangFromUrl = (uri: string, langList: string[] = ['FR', 'EN']): string => {
  let lang = 'FR';

  langList.forEach((language) => {
    if (uri.indexOf(`/${language.toLowerCase()}/`) !== -1 || uri.endsWith(`/${language.toLowerCase()}`)) {
      lang = language;
    }
  });

  return lang;
};

export const getLinkFromData = (data: { [key: string]: any; links?: Link[] }) =>
  data.links && data.links[0] ? getLinkHref(data.links[0]) : '';
