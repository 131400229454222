/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { connect } from 'react-redux';
import breakpoints from '../../../constants/breakpoints';
import { State } from '../../../../typings/state/state';
import MEA from '../../presentational/MEA';
/**
 * Connected container for MeaVideoList component
 */
const mapStateToProps = (state: State) => ({
  isCompact: state.page.window ? state.page.window.width <= parseInt(breakpoints.tablet, 10) : false,
});

const MeaContainer = connect(mapStateToProps)(MEA);

export default MeaContainer;
