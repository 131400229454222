/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to?: string;
  children: React.ReactNode;
  lang?: string;
  className?: string;
  target?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onKeyPress?: (e: React.SyntheticEvent) => void;
  ariaLabel?: string;
  tabIndex?: number;
};

class CustomLink extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    target: '_blank',
    children: null,
    onFocus: undefined,
    onBlur: undefined,
    onKeyPress: undefined,
    tabIndex: 0,
  };

  render() {
    const { to, children, className, target, onClick, onBlur, onFocus, ariaLabel, tabIndex } = this.props;

    if (to && !to.match(/^(mailto:|https?:)/gi)) {
      return (
          <Link onKeyPress={onClick} tabIndex={tabIndex} aria-label={ariaLabel} onFocus={onFocus} onBlur={onBlur} className={className} to={to} onClick={onClick}>
            {children}
          </Link>
      );
    }
    return (
      <a onKeyPress={onClick} tabIndex={tabIndex} aria-label={ariaLabel} onFocus={onFocus} className={className} href={to} target={target} rel={target === '_blank' ? 'noopener%20noreferrer' : ''} onClick={onClick}>
        {children}
      </a>
    );
  }
}

export default CustomLink;
