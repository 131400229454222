import React from 'react';
import ErrorBlock from '../ErrorBlock';
import Config from 'configHelper';

type Props = {
  title: string;
  errorText: string;
  children: any;
  error?: boolean;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError || this.props.error) {
      // You can render any custom fallback UI
      return <ErrorBlock hide={Config.getClientConfig('debug') !== true} title={this.props.title} description={this.props.errorText} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
