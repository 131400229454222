/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import classNames from 'classnames';
import { getLinkFromData } from '../../../helpers/uriHelper';
import { ContentDownload } from 'src/typings/components/content';

type Props = {
  className?: string;
  content: ContentDownload;
};

const DownloadFragment = ({ content, className }: Props) => (
  <div className={classNames(className, 'article__fragment Download__fragment')}>
    {content.documents.map((item) => (
      <div key={item.description}>
        <a href={getLinkFromData(item)} target="_blank" rel="noopener%20noreferrer">
          {content.title}
        </a>
      </div>
    ))}
  </div>
);

export default DownloadFragment;
