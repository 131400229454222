import { langCodes } from 'src/typings/constants/langs';

export const countriesLangMapping = {
  [langCodes.FR]: 'FR',
  [langCodes.EN]: 'GB',
};

export const getCustomLabelsFromAvailableLangs = (langsAvailable: string[]): { [key: string]: string } => {
  const customLabels = {};

  langsAvailable.forEach((lang: string) => {
    if (countriesLangMapping[lang]) {
      customLabels[countriesLangMapping[lang]] = lang;
    } else {
      customLabels[lang] = lang;
    }
  });

  return customLabels;
};

export const getLangFromCountryCode = (countryCode: string): string => {
  let lang = countryCode;

  Object.keys(countriesLangMapping).forEach((key: string) => {
    if (countriesLangMapping[key] === countryCode) {
      lang = key;
    }
  });

  return lang;
};

export const getCountryCodeFromLang = (lang: string): string => countriesLangMapping[lang] || lang;
