/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/**
 * Return a locale date string (ex: 3 mars 2017)
 *
 * @param {string | Date} date
 * @param {string} locale
 * @returns {string} return a locale date string (ex: 3 mars 2017)
 */
export const getLocaleDateString = (date: string | number | Date, locale?: string, options?: any): string => {
  let formattedDate: any = date;

  if (formattedDate && date) {
    const type = typeof date;
    const time = type === 'string' || type === 'number' ? new Date(date) : date;
    formattedDate = time.toLocaleString(
      locale,
      options || { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    );
  }

  return formattedDate;
};

export const formatDateDetour = (date: string | number | Date, locale?: string, options?: any): string =>
  getLocaleDateString(date, locale, options).replace(/\//g, '.');
