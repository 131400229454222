import React, { Children } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  childClassName?: string;
  children?: React.ReactNode;
  refList?: (el: HTMLElement | null) => void;
};

class List extends React.PureComponent<Props> {
  static defaultProps = {
    children: null,
    refList: null,
  };

  render() {
    const { className, childClassName, children, refList } = this.props;

    return (
      <ul ref={refList} className={classNames(className, 'list')}>
        {Children.map(children, (child, key) => (
          <li className={classNames('list-item', childClassName)} key={key}>
            {child}
          </li>
        ))}
      </ul>
    );
  }
}

export default List;
