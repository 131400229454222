/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import classNames from 'classnames';
import { MediaItemContent } from '../../../../typings/components/mediaItem';
import MediaPreview from '../../presentational/MediaPreview';
import List from '../List';
import Title from '../Title';
import { MediaListType } from '../../../../typings/constants/mediaListTypes';

type Props = {
  content: MediaItemContent;
  className: string;
};

const MediaList = ({ content, className }: Props) => {
  const { title, template, medias } = content.content;
  const mediaTemplate = template || MediaListType.TEMPLATE_3_COL;
  return (
    <div className={classNames(className, `media-list media-list--${mediaTemplate}`)}>
      <Title title={title} level={2} />
      <div className={`media-list__grid media-list__grid--${mediaTemplate}`}>
        <List>{medias && medias.map((media) => <MediaPreview key={`media-${media.title}`} media={media} />)}</List>
      </div>
    </div>
  );
};

export default MediaList;
