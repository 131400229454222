import _ from 'lodash';
import React from 'react';

import LazyImage from '../LazyImage';
import { SocialNetworksBar } from 'src/typings/constants/socialNetworks';
import { getBrandLogoUrl } from 'src/shared/service/brand-logo.service';

type Props = {
  socialsLinks: SocialNetworksBar[];
};

const SocialLinksBar: React.FunctionComponent<Props> = ({ socialsLinks }) => {
  const getSocialsLinks = () => {
    if (socialsLinks) {
      const getUrl = (brand) => {
        const link = socialsLinks.find((link) => link.label === brand);

        if (link) {
          return link.items[0].href;
        }

        return null;
      };

      return {
        twitter: getUrl('twitter'),
        dailymotion: getUrl('dailymotion'),
        linkedin: getUrl('linkedin'),
        logo: {
          facebook: getBrandLogoUrl('facebook'),
          twitter: getBrandLogoUrl('twitter'),
          dailymotion: getBrandLogoUrl('dailymotion'),
          linkedin: getBrandLogoUrl('linkedin'),
        },
      };
    }

    return null;
  };

  const links = getSocialsLinks();

  return (
    <div className="SocialContainer">
      <ul className="SocialLinks">
        {links &&
          _.map(links, (_, linkKey) => {
            if (!links[linkKey] || links.logo[linkKey]) {
              return (
                <li key={linkKey} className={`social ${linkKey}`}>
                  <a href={links[linkKey]} rel="noreferrer" target="_blank">
                    <LazyImage brand={linkKey} alt={linkKey} fitWidth />
                  </a>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </div>
  );
};

export default SocialLinksBar;
