import React, { Dispatch, FunctionComponent, ReactNode, SetStateAction } from 'react';

import CreativeMediaArticle from '../CreativeMediaArticle/CreativeMediaArticle';
import { ArticleBase } from 'src/typings/components/articleBase';

type Props = {
  articles: ArticleBase[];
  setIsModalOpen?: (boolean) => void;
  setModalContent?: Dispatch<SetStateAction<ReactNode[] | null>>;
  renderArticleContent?: (path: string | null) => Promise<ReactNode[] | null>;
  isCompact: boolean;
};

const ArticleList3Columns: FunctionComponent<Props> = ({
  articles = [],
  setIsModalOpen,
  setModalContent,
  renderArticleContent,
  isCompact,
}) => (
  <>
    {articles &&
      articles.map((content, index) => (
        <div key={index} className="item">
          <CreativeMediaArticle
            alt={content.mainMedia.default?.alt}
            article={content}
            ratio={169}
            setModalContent={setModalContent}
            setIsModalOpen={setIsModalOpen}
            renderArticleContent={renderArticleContent}
            isCompact={isCompact}
          />
        </div>
      ))}
  </>
  );
  
export default ArticleList3Columns;
