/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { RootAction } from '../../typings/rootActions';
import { IMenuState } from '../../typings/state/menuState';

const defaultState: IMenuState = { galacticMenuOpen: false };

export default function (state: IMenuState = defaultState, action: RootAction): IMenuState {
  switch (action.type) {
    case 'TOGGLE_GALACTIC_HEADER':
      return {
        ...state,
        galacticMenuOpen: !state.galacticMenuOpen,
      };
    case 'CLOSE_GALACTIC_HEADER':
      return {
        ...state,
        galacticMenuOpen: false,
      };
    default:
      return state;
  }
}
