/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import classNames from 'classnames';

/**
 * ComponentError component
 */
const ErrorBlock = ({ title, description, hide }: { title: string; description: string; hide: boolean }) => (
  <div
    className={classNames({
      'block-error': !hide,
      'block-error block-error--is-hidden': hide,
    })}
  >
    <p className="block-error_title">{title}</p>
    <p className="block-error_description">{description}</p>
  </div>
);

export default ErrorBlock;
