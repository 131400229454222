import React, { useState } from 'react';
import Icon from '../Icon';

type Props = {
  transcription: string;
  label?: string;
  className?: string;  
};

export function Transcription({ transcription, className = '', label }: Props) {
  const [displayTranscription, setDisplayTranscription] = useState(false);

  return (
    <div className={`${className} text__transcription`}>
      <button
        type="button"
        aria-expanded={displayTranscription}
        className="text__transcription--button"
        onClick={() => setDisplayTranscription(!displayTranscription)}
      >
        <Icon id="arrow-right" rotate={displayTranscription ? 90 : 0} />
        <span>{label}</span>
      </button>
      {displayTranscription && <div className="description" dangerouslySetInnerHTML={{ __html: transcription }} />}
    </div>
  );
}
