export type Size = {
  width?: string;
  height?: string;
};

export enum ImageSizes {
  CONTACT_ELEMENT_IMG = 'contact-element',
  ARTICLE_IMG = 'article-img',
  FOOTER_LOGO_IMG = 'footer-logo',
  HEADER_LOGO_IMG = 'header-logo',
  HEADER_LOGO_IMG_BURGER = 'header-logo-burger',
  MEA_BACKGROUND_IMG = 'mea-bg',
  MEA_LOGO_IMG = 'mea-logo',
  BRAND_IMG = 'brand-logo',
  JSON_LOGO_IMG = 'json-logo',
}

export const ImageSizeMap: { [key in ImageSizes]: Size } = {
  [ImageSizes.CONTACT_ELEMENT_IMG]: { width: '120' },
  [ImageSizes.ARTICLE_IMG]: { height: '450' },
  [ImageSizes.FOOTER_LOGO_IMG]: { height: '40' },
  [ImageSizes.HEADER_LOGO_IMG]: { height: '50' },
  [ImageSizes.HEADER_LOGO_IMG_BURGER]: { height: '80' },
  [ImageSizes.BRAND_IMG]: { height: '100' },
  [ImageSizes.MEA_BACKGROUND_IMG]: { height: '496' },
  [ImageSizes.MEA_LOGO_IMG]: { height: '400' },
  [ImageSizes.JSON_LOGO_IMG]: { width: '500' },
};
