import ArticleList from '../../shared/components/container/ArticleList';
import Author from '../../shared/components/presentational/Author';
import DateFragment from '../../shared/components/presentational/DateFragment';
import DownloadFragment from '../../shared/components/presentational/DownloadFragment';
import PictureFragment from '../../shared/components/presentational/PictureFragment';
import IFrame from '../../shared/components/presentational/IFrame';
import Intro from '../../shared/components/presentational/Intro';
import TitleBlock from '../../shared/components/presentational/TitleBlock';
import Quote from '../../shared/components/presentational/Quote';
import RawBlock from '../../shared/components/presentational/Raw';
import SocialBar from '../../shared/components/container/SocialBar';
import TextBlockContainer from 'src/shared/components/container/Text';
import EmptyLineContainer from 'src/shared/components/presentational/EmptyLine';
import Tags from '../../shared/components/presentational/Tags';
import Subtitle from '../../shared/components/presentational/Subtitle';
import VideoFragment from '../../shared/components/presentational/VideoFragment';
import CommentDisqus from '../../shared/components/presentational/CommentDisqus';
import MediaList from '../../shared/components/presentational/MediaList';
import { FragmentType } from './fragmentTypes';

export const fragmentComp: { [key in FragmentType]: React.ReactType } = {
  [FragmentType.FRAGMENT_TYPE_AUTHOR]: Author,
  [FragmentType.FRAGMENT_TYPE_DATE]: DateFragment,
  [FragmentType.FRAGMENT_TYPE_DOWNLOAD]: DownloadFragment,
  [FragmentType.FRAGMENT_TYPE_IMAGE]: PictureFragment,
  [FragmentType.FRAGMENT_TYPE_MAIN_IMAGE]: PictureFragment,
  [FragmentType.FRAGMENT_TYPE_IFRAME]: IFrame,
  [FragmentType.FRAGMENT_TYPE_INTRO]: Intro,
  [FragmentType.FRAGMENT_TYPE_MAIN_TITLE]: TitleBlock,
  [FragmentType.FRAGMENT_TYPE_QUOTE]: Quote,
  [FragmentType.FRAGMENT_TYPE_RAW]: RawBlock,
  [FragmentType.FRAGMENT_TYPE_RELATED_ARTICLES]: ArticleList,
  [FragmentType.FRAGMENT_TYPE_SOCIAL_NETWORKS]: SocialBar,
  [FragmentType.FRAGMENT_TYPE_TAG]: Tags,
  [FragmentType.FRAGMENT_TYPE_TEXT]: TextBlockContainer,
  [FragmentType.FRAGMENT_TYPE_EMPTY_LINE]: EmptyLineContainer,
  [FragmentType.FRAGMENT_TYPE_TITLE]: Subtitle,
  [FragmentType.FRAGMENT_TYPE_VIDEO]: VideoFragment,
  [FragmentType.FRAGMENT_TYPE_COMMENT]: CommentDisqus,
  [FragmentType.FRAGMENT_TYPE_MEDIA_LIST]: MediaList,
  [FragmentType.FRAGMENT_TYPE_ADVERTISING]: RawBlock,
};
