/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { connect } from 'react-redux';
import JsonLd from '../../presentational/JsonLd';
import { getLinkFromData } from '../../../helpers/uriHelper';
import { State } from '../../../../typings/state/state';

/**
 * JsonLd container component
 * @class
 */
const mapStateToProps = (state: State) => ({
  pageURI: getLinkFromData(state.page.data),
});

const JsonLdContainer = connect(mapStateToProps)(JsonLd);

export default JsonLdContainer;
