/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Config from 'configHelper';
import { RootAction } from '../../typings/rootActions';

export function setError(message: string, statusCode = 500): RootAction {
  return {
    type: 'SET_ERROR',
    message: Config.getClientConfig('debug') === true ? message : undefined,
    statusCode,
  };
}
