/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import Title from '../Title';
import Text from '../Text';
import { MediaItem } from '../../../../typings/components/media';
import { getArticleMedia } from '../../../helpers/imageHelper';

/**
 * MediaPreview component
 *
 * @param MediaItem
 * @constructor
 */
const MediaPreview = ({ media }: { media: MediaItem }) => (
  <div className="media-preview">
    <div className="media-preview__image-wrapper" style={{ backgroundImage: `url(${getArticleMedia(media.link).src})` }} />
    <Title level={3} title={media.title} />
    {media.abstract && media.abstract !== '' && <Text text={media.abstract} />}
  </div>
);

export default MediaPreview;
