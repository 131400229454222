/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* global s_gi, s_account */ // eslint-disable-line
/* eslint camelcase: 0 */

import { ClickTrackingSettings } from '../../typings/components/tracking';
import { OMNITURE } from './../constants/trackers';
import { WindowApp } from 'src/typings/global';
import { ContentOmnitureHeader } from 'src/typings/components/content';

// the setting obj is the same format as OmnitureHeader content, or variables inside page.site.tracker
function formatPageSetting(obj: { [k: string]: string }) {
  return Object.keys(obj).reduce((acc, key) => (key && obj[key] ? { ...acc, [key]: obj[key] } : acc), {});
}

/**
 * Track a click with the given setting and tracker
 *
 * @param {ClickTrackingSettings} settings Settings for click tracking
 * @param {string}                tracker  Tracker name
 */
export const trackClick = (event: any, settings: ClickTrackingSettings, tracker?: string) => {
  if (tracker !== OMNITURE) {
    // We only deal with omniture for now
    return;
  }

  const { name, variables } = settings;
  const sendEvent = (window as unknown as WindowApp).sendEvent;
  if (sendEvent) {
    sendEvent({
      event,
      type: 'exitLink',
      name,
      data: formatPageSetting(variables),
    });
  }
};

/**
 * Track the page with the given setting and tracker
 *
 * @param {ContentOmnitureHeader} settings Settings for page tracking
 * @param {string}               tracker  Tracker name
 */
export const trackPage = (settings: ContentOmnitureHeader, tracker?: string) => {
  if (tracker !== OMNITURE) {
    // We only deal with omniture for now
    return;
  }
  const sendPageView = (window as unknown as WindowApp).sendPageView;
  if (sendPageView) {
    sendPageView({
      name: settings.pageName,
      data: formatPageSetting(settings),
    });
  }
};
