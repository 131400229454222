import React from 'react';
import { ContentGalacticHeaderTool } from 'src/typings/components/content';

type Props = {
  tool: ContentGalacticHeaderTool;
};

class GalacticTools extends React.PureComponent<Props> {
  render() {
    const { tool } = this.props;

    return (
      <div className="GalacticTools">
        {tool.type === 'spotify' && (
          <iframe
            title="Spotify playlist"
            src={`https://open.spotify.com/embed?uri=spotify:user:${tool.settings ? tool.settings.user_id : ''}:playlist:${
              tool.settings ? tool.settings.playlist_id : ''
            }`}
            width="400"
            height="80"
            frameBorder="0"
          />
        )}
      </div>
    );
  }
}

export default GalacticTools;
