import { connect } from 'react-redux';
import ArticleBanner from '../../presentational/ArticleBanner';
import breakpoints from '../../../constants/breakpoints';
import { State } from '../../../../typings/state/state';

const mapStateToProps = ({ page }: State) => ({
  isCompact: page.window ? page.window.width <= parseInt(breakpoints.tablet, 10) : false,
});

const ArticleBannerContainer = connect(mapStateToProps)(ArticleBanner);

export default ArticleBannerContainer;
