/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2017 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import find from 'lodash/find';
import endsWith from 'lodash/endsWith';
import { canUseDOM } from './windowHelper';
import { WindowApp } from 'src/typings/global';

/**
 * scriptExist
 *
 * this function take a script url and check if it already exist
 *
 * @param  {string} src            script uri
 * @return {script}               return the corresponding script DOM node or undefined
 */
export const scriptExist = (src: string) => {
  const scriptsList = document.querySelectorAll('script');
  // Convert NodeList object to Array
  const scripts = Object.values(scriptsList);
  return find(scripts, (script) => endsWith(script.src, src));
};

export const injectScript = (src: string) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  document.body.appendChild(script);
  return script;
};

/**
 * loadScript
 *
 * this function takes a script url and appends it
 * to the document.body if don't already exist. Resolves if it loads correctly or
 * resolve with url if it already exist, rejects otherwise
 *
 * @param  {string}  src           script uri
 * @return {Promise}               resolves if loaded, rejects otherwise or if not an url
 */
export const loadScript = (src: string, checker: () => boolean = () => false) =>
  new Promise((resolve, reject) => {
    if (!src) {
      reject(new Error('Script source is missing'));
      return;
    }

    if (!canUseDOM()) {
      // server side without window object
      resolve('Cannot load script on Server Side Rendering');
      return;
    }

    // at this point we can safely use DOM api as we return early if is server side
    // checker is a passed in function to verify if a certain global object exist
    if (checker()) {
      resolve('global object ready');
      return;
    }

    // else check if we already has script, if not inject it
    // then resolve it or reject on error
    const script = scriptExist(src) || injectScript(src);
    script.onload = (): void => resolve('global object ready after inject script');
    script.onerror = (): void => reject(new Error(`error while loading script: ${src}`));
  });

/**
 *
 * @param url
 */
const loadOrReloadScriptUrl = (url: string) => {
  const elem = scriptExist(url);
  if (elem && elem.parentNode) {
    elem.parentNode.removeChild(elem);
  }
  // We are looking for https://data.canalplus.com/production/v2/trcking.min.js
  const isTrackingScript = url.indexOf('trcking') === -1;

  if (isTrackingScript) {
    loadScript(`${url}?cache=${new Date().getTime()}`).then(() => {
      // hack for init instagram block
      const windowApp: WindowApp = window as unknown as WindowApp;
      if (url.indexOf('instagram') !== -1 && windowApp.instgrm) {
        windowApp.instgrm.Embeds.process();
      }
    });
  }
};

/**
 *
 * @param codeStr
 */
const execScriptCode = (codeStr) => {
  // for debug, display code executed in dom
  // check if exist
  const scriptsList = document.querySelectorAll('script');
  const scripts = Object.values(scriptsList);
  const elemsExist = find(scripts, (script: any) => script.text === codeStr);

  if (!elemsExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = codeStr;
    document.body.appendChild(script);
  } else if (window) {
    try {
      const windowApp: WindowApp = window as unknown as WindowApp;
      windowApp.eval(codeStr);
    } catch (e) {} // eslint-disable-line
  }
};

/**
 *
 * @param htmlCode
 */
export const executeScriptFromHtml = (htmlCode, onlyLoadScript = false) => {
  const elemHtml = document.createElement('div');
  elemHtml.innerHTML = htmlCode;
  const listScript = elemHtml.getElementsByTagName('script');

  for (let i = 0; i < listScript.length; i += 1) {
    if (listScript[i].src) {
      loadOrReloadScriptUrl(listScript[i].src);
    } else if (!onlyLoadScript && listScript[i].text) {
      execScriptCode(listScript[i].text);
    }
  }
};
