/*
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DIX (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { combineReducers, Reducer, Action } from 'redux';

import page from './page';
import error from './error';
import menu from './menu';
import search from './search';
import articleList from './articleList';
import authentication from './authentication';
import meaPlaylist from './meaPlaylist';

import { State } from './../../typings/state/state';

const reducer: Reducer<State, Action> = combineReducers({
  page,
  error,
  menu,
  search,
  articleList,
  authentication,
  meaPlaylist,
});

export default reducer;
