import React, { forwardRef} from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

/**
 * Button component
 * @param {string} label Button label
 * @param {function} action Action to dispatch when button is clicked
 * @param {string} type Button type (additional CSS modifier)
 * @constructor
 */
type Props = {
  label?: string;
  icon?: string;
  action?: (e: React.SyntheticEvent) => void;
  className?: string;
  ariaExpanded?: boolean;
  ariaControls?: string;
  ariaLabel?: string;
  isCloseButton?: boolean;
};

const Button = forwardRef(({
  label,
  icon,
  action,
  className = '',
  ariaExpanded,
  ariaControls,
  ariaLabel,
}: Props, ref: any) => {

  const buttonAction = (e) => {
    if (action) action(e);
  }

  return (
    <button
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      type="button"
      ref={ref}
      onClick={buttonAction}
      className={classNames(className, 'button')}
    >
      {label && label !== '' && <span>{label}</span>}
      {icon && <Icon id={icon} />}
    </button>
  )
});

export default Button;
