import React from 'react';
import Truncate from 'react-text-truncate';
import classNames from 'classnames';
import CustomLink from '../../container/CustomLink';
import Heading from '../Heading';

type Props = {
  title: string;
  line: number;
  level: number;
  className?: string;
  href?: string;
  onClick?: () => void;
};

class TruncateTitle extends React.PureComponent<Props> {
  static defaultProps = {
    level: 3,
    href: '',
    line: 2,
  };

  render() {
    const { title, className, line, level, href, onClick } = this.props;

    return (
      <Heading level={level} className={classNames('title', className)}>
        {href ? (
          <CustomLink to={href} onClick={onClick} target="_blank">
            <Truncate element="span" line={line} truncateText="…" text={title} />
          </CustomLink>
        ) : (
          <Truncate element="span" line={line} truncateText="…" text={title} />
        )}
      </Heading>
    );
  }
}

export default TruncateTitle;
