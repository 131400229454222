/**
 * This file is part of the "Creative Media" project.
 *
 * (c) 2018 - DED (CanalPlus Group)
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Logger from 'loggerHelper';
import { InternalApiClient, getCancelToken, isCancel } from './HttpClient';

/**
 * InternalApi is an API provided by the node server
 */
const InternalApi = {
  /**
   * Login on the node server
   *
   * @param {string} url
   * @param {string} username
   * @param {string} password
   * @param {object} options
   * @returns {Promise.<*>}
   */
  async login(url: string, username: string, password: string, options: any = {}) {
    const { uniqueId } = options;

    Logger.debug('InternalApi::login()', Logger.generateMetadata({ uniqueId }));

    const cancelToken = getCancelToken();

    Logger.info(
      `Fetch POST ${url}`,
      Logger.generateApiMetadata('internal', 'login', {
        uniqueId,
        endpoint: url,
      }),
    );

    const data = {
      url,
      username,
      password,
    };

    // $FlowFixMe
    return InternalApiClient.post('/internal/login', data, {
      cancelToken,
      headers: {
        'X-UNIQUE-ID': uniqueId,
      },
    });
  },

  /**
   * Check if given error is a canceled request error
   *
   * @param {object} error
   * @returns {boolean}
   */
  isCancelError(error: Record<string, any>): boolean {
    return isCancel(error);
  },
};

export default InternalApi;
